import React, { Component } from 'react';
import { connect } from 'react-redux';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Box, Heading, Text, Button } from "native-base";
import Spinner from "../../Component/Spinner";
import { setProducts } from "../store/actions";
import ProductTeaser from "../Component/ProductTeaser";
import { GET_PRODUCTS } from "../products-gql";
import ApolloClient from '../../../store/apolloClient';
import PropTypes from 'prop-types';
import CpIconButton from "../../Component/CpIconButton";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import AnimateBox from "../../Component/AnimatedBox";

const ApolloClientInstance = new ApolloClient();
const client = ApolloClientInstance.getClient();

class ProductsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExtended: props.isExtended
    }
  }

  debugMessage = [];

  validDuration = 3600;
  now() { return Math.floor(Date.now() / 1000); }

  loadProductsByAPI() {
    client.query({ query: GET_PRODUCTS, variables: { path: "/produkte" } })
      .then(result => {
        var tempprod = [...result.data.page.articles.items].sort((a, b) => {
          return b.priority - a.priority
        });
        const productsData = (typeof result.data.page.articles.items !== 'undefined' && result.data.page.articles.items)
          ? tempprod : false;
        if (productsData) {
          const now = this.now();
          this.props.storeProducts({ products: productsData, lastUpdate: now }, true);
        }
      })
      .catch((e) => {
        console.log('ERROR Products GQL-Query: ', e)
      })
  }

  loadProductsFromAsyncStore() {
    AsyncStorage.getItem('productsData')
      .then(storedProductsData => {
        let update = true;
        if (storedProductsData) {
          const parsedProductsData = JSON.parse(storedProductsData);
          if (typeof parsedProductsData === 'object') {
            this.props.storeProducts(parsedProductsData, false);

            // Refresh products data if required.
            const timeLimit = this.now() - this.validDuration;
            const lastUpdate = (typeof parsedProductsData.lastUpdate === 'number') ?
              parsedProductsData.lastUpdate : 0;
            update = (lastUpdate < timeLimit);
          }
        }
        if (update) {
          this.loadProductsByAPI()
        }

      }).catch(e => {
        this.loadProductsByAPI()
        console.log('ERROR Products AsyncStorage: ', e)
      });
  }

  componentDidMount() {
    if (this.props.lastUpdate === 0) {
      this.loadProductsFromAsyncStore();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isExtended !== this.props.isExtended) {
      this.setState({ isExtended: this.props.isExtended })
    }
  }

  /**
   * Filters product list by tags.
   *
   * @param product
   * @returns {boolean}
   *   Is item part of list (true) or not (false).
   */
  tagsFilter(product) {
    if (!Array.isArray(this.props.tags)) return true; // All products rendered.

    const tags = (Array.isArray(product.tags)) ? product.tags : [];
    const tagIds = tags.map(tagObj => parseInt(tagObj.id, 10));

    for (const id of this.props.tags) {
      const iId = (typeof id === 'string') ? parseInt(id, 10) : id;
      if (tagIds.indexOf(iId) < 0) {
        if (this.props.debug) {
          this.debugMessage.push(product.name
            + " (id: " + product.id + ") filtered: Has no tag: " + id)
        }
        return false
      }
    }
    return true
  }


  /**
   * Filters product list by recommend string.
   *
   * @param product
   * @returns {boolean}
   *   Is item part of list (true) or not (false).
   */
  recommendFilter(product) {
    if (!this.props.recommend) return true; // All products rendered.
    let regex = new RegExp(this.props.recommend);
    return (product.recommend) ? !!product.recommend.match(regex) : false;
  }

  /**
   * Filters products by ids .
   *
   * @returns {boolean}
   *
   */
  idFilter(product) {
    if (!Array.isArray(this.props.ids)) return true; // All products rendered.
    return (this.props.ids.indexOf(parseInt(product.id, 10)) >= 0)
  }

  render() {
    this.debugMessage = [];
    let products = (this.props.products.length) ?
      this.props.products
        .filter(product => this.tagsFilter(product))
        .filter(product => this.idFilter(product))
        .filter(product => this.recommendFilter(product))
        .map((product, i) =>
          <ProductTeaser key={product.uuid}
            border={!!i}
            mode={this.props.mode}
            product={product}
            navigation={this.props.navigation}
            clicked={() => this.props.navigation.navigate('Product Details', { product: product })} />)
      : (<Spinner />);

    let title = '';
    // Build the title (with replacement of the number).
    if (Array.isArray(products) && !products.length && !this.props.showTitleOnEmpty) {
      title = false
    } else {
      title = (typeof this.props.title !== 'undefined') ? this.props.title : false;
    }

    // Control empty behavior.
    if (Array.isArray(products) && !products.length) {
      if (this.props.hideOnEmpty && !this.props.debug) {
        return [];
      }
      products = (typeof this.props.emptyMessage !== 'undefined') ? this.props.emptyMessage : [];
    }

    if (title) {
      const num = (Array.isArray(products)) ? products.length : 0;
      title = title.replace('%num', num.toString(10));
    }

    // Prepare debug messages.
    let debugInfoComp = [];
    if (this.props.debug) {
      debugInfoComp.push(<Button key="btn" px={2} size="sm" onPress={() => this.loadProductsByAPI()}>Refresh Products from API</Button>);
      if (this.debugMessage.length) {
        const debugMessageComponents = this.debugMessage
          .map((message, index) => (<Text key={index} >{message}</Text>));

        debugInfoComp.push(<Box key="msgs" my={3} p={3}
          bgColor={'white'}
          borderColor={'red.500'}
          borderWidth={1}>{debugMessageComponents}</Box>)
      }
    }

    // Output as extendable list or pure.
    if (this.props.asExtendable) {
      return (<Box my={0.5}>
        <CpIconButton icon={{ as: MaterialCommunityIcons, name: (this.state.isExtended) ? "minus" : "plus" }}
          iconAlign={'right'}
          label={title}
          onPressed={() => {
            if (typeof this.props.onChangeIntend === 'function') {
              this.props.onChangeIntend(!this.state.isExtended)
            } else {
              this.setState({ isExtended: !this.state.isExtended })
            }
          }} />
        <AnimateBox open={this.state.isExtended}>
          {products}
          {debugInfoComp}
        </AnimateBox>
      </Box>)
    } else {
      return (
        <>
          {(title) ? (<Heading size="md">{title}</Heading>) : []}
          {products}
          {debugInfoComp}
        </>
      )
    }

  }
}


ProductsList.propTypes = {
  ids: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  tags: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  debug: PropTypes.bool,
  mode: PropTypes.string,
  asExtendable: PropTypes.bool,
  isExtended: PropTypes.bool,
  hideOnEmpty: PropTypes.bool,
  onChangeIntend: PropTypes.func,
  title: PropTypes.string,
  showTitleOnEmpty: PropTypes.bool,
  recommend: PropTypes.string,
  emptyMessage: PropTypes.element,
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired
  }),
};

ProductsList.defaultProps = {
  ids: null,
  mode: 'default',
  tags: null,
  debug: false,
  asExtendable: false,
  isExtended: false,
  hideOnEmpty: false,
  recommend: '',
  showTitleOnEmpty: true,
}

const mapStateToProps = (state) => {
  return {
    products: state.products.products,
    lastUpdate: state.products.lastUpdate,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    storeProducts: (data, syncLocal) => dispatch(setProducts(data, syncLocal))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProductsList);
