

export const validateLength = (string = '', min = 1) => {
  return Boolean(String(string).length >= min);
}

export const validateName = (name) => {
  const regx = /^[\p{L}\- ]{3,128}$/u;
  return regx.test(String(name));
}

export const validateEmail = (email) => {
  const regx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regx.test(String(email).toLowerCase());
}

export const validatePassword = (password) => {
  // at least 1 lowercase, 1 uppercase, one number and one special char from range !§$%&/()=?@*+;,:._-#\.
  const regx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!"§$%&/()=?@*+;,:._\-#\\]{8,}$/;
  return regx.test(String(password));
}
