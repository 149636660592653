import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";
import t from "../../translation/translation"

import StartScreen from "./Screens/StartScreen";
import LoginScreen from "./Screens/LoginScreen";
import RegisterScreen from "./Screens/RegisterScreen"
import PasswordScreen from "./Screens/PasswordScreen"

const StackNavi = createStackNavigator()
function AuthNavigator() {

  const screenOptions = {
    headerShown: false,
    cardStyleInterpolator: ({ current }) => ({
      cardStyle: {
        opacity: current.progress
      },
    })
  }

  return (
    <StackNavi.Navigator screenOptions={screenOptions}>
      <StackNavi.Screen options={{title: t("Start"), headerStyle:{}}} name="Start" component={StartScreen} />
      <StackNavi.Screen options={{title: t("Login"), headerStyle:{}}} name="Login" component={LoginScreen} />
      <StackNavi.Screen options={{title: t("Register"), headerStyle:{}}} name="Register" component={RegisterScreen} />
      <StackNavi.Screen options={{title: t("Password"), headerStyle:{}}} name="Password" component={PasswordScreen} />
    </StackNavi.Navigator>
  )
}

export default AuthNavigator;
