import React, { Component } from 'react';
import { connect } from 'react-redux';
import { VStack, Heading, Box, HStack, Button, Text, Divider, Pressable } from "native-base";
import Screen from "../../Component/Screen";
import t from "../../../translation/translation";
import ProductList from "../Component/ProductList"
import BottomSheet from "../../Component/BottomSheet";
import ActionIcon from "../Component/ActionIcon";
import InScreenFilterItem from "../Component/InScreenFilterItem"
import FilterGroup from "../Component/FilterGroup";

class ProductsOverview extends Component {

  constructor(props) {
    super(props);
    this.filterRef = React.createRef();
    this.state = {
      catOpen: this.props.catOpen,
      filterGroup: [],
      filterInfected: []
    }
  }

  render() {
    const catOverview = [];
    const isFiltered = (this.state.filterGroup.length >= 1);
    const hasMultipleGroups = (!isFiltered || this.state.filterGroup.length >= 2);
    const properties = {}
    const cat = this.props.categories
    for (const i in this.props.categories) {
      console.log(cat[i].key);
      if (hasMultipleGroups) {
        properties.onChangeIntend = (open) => { this.setState({ catOpen: (open) ? cat[i].key : 0 }) }
      }
      if (!isFiltered || this.state.filterGroup.indexOf(cat[i].key) >= 0) {
        // combine cat tag with filter tags.
        let tags = [...this.state.filterInfected].map(f => parseInt(f, 10));
        tags = [...tags, parseInt(cat[i].key, 10)];
        catOverview.push(<ProductList key={cat[i].key}
          mode="default"
          tags={tags}
          asExtendable={true}
          hideOnEmpty={true}
          isExtended={(!hasMultipleGroups && isFiltered) || this.state.catOpen === cat[i].key}
          title={cat[i].val + ' (%num)'}
          navigation={this.props.navigation}
          {...properties} />)
      }
    }



    // BUILD FILTER BOTTOM SHEET
    const btmSheetHeader = (
      <>
        <HStack justifyContent={'space-between'} alignItems={'center'} px={6}>
          <Pressable onPress={() => {
            this.setState({
              filterGroup: [],
              filterInfected: []
            })
            this.filterRef.current.closeBottomSheet();
          }}><Text fontWeight={400} fontSize={15.99} color="primary.600">{t('cancel')}</Text></Pressable>
          <Heading textAlign="center"
            lineHeight={24.01}
            px={6}
            letterSpacing={0.9}
            fontSize={17.999}
            textTransform={"uppercase"}>{t('Filter')}</Heading>
          <Pressable onPress={() => this.filterRef.current.closeBottomSheet()}>
            <Text fontWeight={700} fontSize={15.99} color="primary.600">{t('apply')}</Text>
          </Pressable>
        </HStack>
        <Divider my={3} h={0.59} bgColor={'#B2B2B2'} />
      </>
    )

    const infectedFilter = [
      { "key": '30', "val": t('Wound infected') },
      { "key": '31', "val": t('Wound not infected') }
    ]

    const btmSheet = (
      <BottomSheet ref={this.filterRef} heading={btmSheetHeader} height={1000}>
        <Box mx={6} my={3}>
          <FilterGroup title={t('Infection')}
            items={infectedFilter}
            current={this.state.filterInfected}
            onChange={filter => this.setState({ filterInfected: filter })} />
          <FilterGroup title={t('Product Category')}
            items={[...this.props.categories]}
            current={this.state.filterGroup}
            onChange={filter => this.setState({ filterGroup: filter })} />
        </Box>
      </BottomSheet>);

    // BUILD IN-SCREEN FILTER DISPLAY.
    const inScreenFilterItems = [];
    Object.keys(infectedFilter).forEach((item) => {
      if (this.state.filterInfected.indexOf(item) >= 0) {
        inScreenFilterItems.push(
          <InScreenFilterItem key={item}
            title={infectedFilter[item]}
            onDisable={() => {
              const newFilter = [...this.state.filterInfected]
                .filter(match => match !== item)
              this.setState({ filterInfected: newFilter });
            }} />)
      }
    });
    Object.keys(this.props.categories).forEach((item) => {
      if (this.state.filterGroup.indexOf(item) >= 0) {
        inScreenFilterItems.push(
          <InScreenFilterItem key={item}
            title={this.props.categories[item]}
            onDisable={() => {
              const newFilter = [...this.state.filterGroup]
                .filter(match => match !== item)
              this.setState({ filterGroup: newFilter });
            }} />)
      }
    });

    const inScreenFilter = (inScreenFilterItems.length) ? (
      <HStack flexWrap="wrap" mx={-2} my={4}>{inScreenFilterItems}</HStack>
    ) : [];

    const length = (Array.isArray(this.props.products)) ?
      this.props.products.length.toString(10) + ' ' : '';

    return (
      <Screen scrollable={true}>
        <VStack mx={6} my={6}>
          <HStack justifyContent={'space-between'} alignItems={'center'}>
            <Heading size="xl" my={2}>{t('Products')}</Heading>
            <ActionIcon icon="filter" onPressed={() => this.filterRef.current.showBottomSheet()} />
          </HStack>
          {inScreenFilter}
          {catOverview}
          {btmSheet}
        </VStack>
      </Screen>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products.products,
    categories: state.products.categories,
  }
}

export default connect(mapStateToProps)(ProductsOverview);
