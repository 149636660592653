export default {
  // basics
  "Loading": "Lade Daten",
  "Hello": "Hallo",
  "Nice to meet you here": "Schön, Dich hier zu treffen.",

  // Main menu
  "Welcome": "Verbandauswahl",
  "Your Account": "Benutzerkonto",
  "Wizard": "Verbandauswahl",
  "Products": "Produkte",
  "Bookmarked": "Merkliste",
  "Labels": "Labels",

  // Login
  "User Account": "Benutzerkonto",
  "Start": "Start",
  "Login": "Anmelden",
  "Username": "Benutzername",
  "Please enter your credentials": "Bitte geben Sie Benutzernamen und Passwort ein.",
  "The email field cannot be empty": "Das E-Mail Feld darf nicht leer sein.",
  "The password field cannot be empty": "Das Passwort darf nicht leer sein.",
  "Login failed": "Login fehlgeschlagen",
  "Username or password wrong": "Benutzername oder Passwort ist nicht korrekt eingetragen.",
  "Register": "Registrieren",
  "Register new Account": "Neues Konto registrieren",
  "Sign up to continue": "Registrieren Sie ein neues Benutzerkonto um fortzufahren!",
  "Full name": "Vollständiger Name",
  "Email": "E-Mail",
  "Password": "Passwort",
  "Forgot password": "Passwort vergessen",
  "Confirm Password": "Passwort bestätigen",
  "Register error": "Registrierung fehlgeschlagen",
  "Seems e-mail is already registered": "Anscheinend wurde diese E-Mail-Adresse schon registriert. Nutze die Passwort-vergessen-Funktion um ein Neues Passwort zu erstellen.",
  "Reset Password": "Passwort zurücksetzen",
  "E-mail address not valid": "Die E-Mail-Adresse ist ungültig..",
  "Name must contain three char": "Der Nutzername muss mindestens 3 Buchstaben enthalten..",
  "Password mistyped": "Das Passwort sollte wenigstens 8 Zeichen (Klein-, Großbuchstaben und Zahlen) enthalten.",
  "Password does not match": "Passwort stimmt nicht überein.",
  "Reset password text": "Du erhältst in kürze eine E-Mail, mit Informationen um Dein Passwort zurückzusetzen.",
  "Please enter your e-mail address": "Bitte gib Deine E-Mail-Adresse ein.",
  "Submit": "Absenden",
  "Return to Login": "Zurück zum Login",
  "E-mail error": "Unbekannte E-Mail-Adresse",
  "Offline error": "Server error",
  "App can not connect with server": "Die App kann sich nicht mit dem Internet verbinden. Bitte prüfe Deinen Online-Status.",
  "Seems e-mail not registered yet": "Es scheint so, als wenn diese E-Mail-Adresse noch nicht registriert wurde. Bitte prüfe diese auf Tippfehler.",
  "Server error": "Server gibt eine Fehlermelldung zurück.\nBitte versuchen Sie es später noch einmal.",
  "Succeeded": "Ok, E-Mail versendet.",
  "Please check your e-mails": "Bitte prüfe Deinen E-Mail-Account. Du solltest eine E-Mail erhalten haben, um Dein Passwort zurückzusetzen.",
  "Close": "Schließen",
  "Logout": "Logout",
  "You are in offline mode": "Aktuell ist die App im Offline-Modus. Du kannst Dich weder ein- noch ausloggen.",
  // Account Update
  "Update Account": "Konto bearbeiten",
  "Account update failed": "Konto bearbeiten ist fehlgeschlagen",
  "Please contact our service team when error comes up repeatedly": "Bitte kontaktieren Sie unser Service-Team, wenn der Fehler wiederholt auftritt.",
  "Update your Address Data": "Ihre Adresse",
  "The %name field cannot be empty": "Bitte füllen Sie das Feld '%name' aus.",
  "required information": "erforderliche Angaben",
  "Name": "Name",
  "Company": "Klinik/Firmenname",
  "Job Title": "Berufsbezeichnung",
  "Phone Number": "Telefonnummer",
  "For any queries we need your telephone number": "Für eventuelle Rückfragen benötigen wir zwingend Ihre Telefonnummer, um einen reibungslosen Versand Ihres Musters gewährleisten zu können.",
  "Street": "Straße",
  "Nr": "Nr.",
  "Address Supplement": "Adresszusatz",
  "ZIP": "PLZ",
  "City": "Ort",
  "Country": "Land",
  "Germany": "Deutschland",
  "Product samples can only be sent to addresses in Germany": "Produktmuster können ausschließlich an Adressen in Deutschland geschickt werden.",
  "Update": "Speichern",
  "This information is required to process your sample order": "Diese Angaben sind für die Bearbeitung Ihrer Musterbestellung erforderlich. Alle darüber hinaus gehenden Angaben erfolgen auf freiwilliger Basis und können von Coloplast ebenfalls im Rahmen der Musterbestellung genutzt werden.",
  "Process Update": "Account Aktualisierung",

  // Data Privacy
  "Data Privacy": "Datenschutz",
  "Consent under data protection law": "Datenschutzrechtliche Einwilligung",
  "The dispatch of the product sample order is free of charge for you": "Die Versendung der Produktmusterbestellung ist für Sie kostenfrei. Um die Musterbestellung abzuschließen, lesen und akzeptieren Sie bitte die nachfolgende »Datenschutzrechtliche Einwilligung für Produktmuster«. Wenn Sie weiterhin mit uns in Kontakt bleiben wollen und exklusiv vorab Informationen über neue Coloplast Produkte und Services erhalten möchten, dann lesen und akzeptieren Sie bitte die »Datenschutzrechtliche Einwilligung zur werblichen Kontaktaufnahme« (optional).",
  "Declaration of consent under data protection law for the dispatch of product samples": "Datenschutzrechtliche Einwilligungserklärung für den Versand von Produktmustern",
  "Consent under data protection law to contact you for advertising": "Datenschutzrechtliche Einwilligung zur werblichen Kontaktaufnahme",
  "Further information about the processing and protection of your data at": "Weitere Informationen über die Verarbeitung und den Schutz meiner Daten erhalte ich über",

  "Bookmarks": "Merkliste",
  "Bookmark": "Merken",
  "Add to cart": "Zur Musterbestellung",

  // Result page on Update
  "Your account details have been successfully transmitted": "Ihre Account-Daten wurden erfolgreich übermittelt",
  "Do you want to refresh your knowledge or develop new skills": "Kennen Sie schon unsere Wissensplattform Coloplast Professional? Ob Wundwissen, Fortbildungen oder Tools: Schauen Sie vorbei und informiert Sie sich rund um das Thema Wundversorgung. Wir freuen uns auf Ihren Besuch! Weiter zu Coloplast Professional.",
  "More about Coloplast Professional": "Mehr zu Coloplast® Professional",
  "Learn more": "Mehr Wissen",
  "Everything for healthcare professionals working in wound care": "Alles für Fachkräfte im Gesundheitswesen, die im Bereich Wundversorgung arbeiten:",
  "E-learning modules": "E-Learning-Module",
  "Web seminars": "Web-Seminare",
  "Face-to-face seminars": "Präsenzseminare",
  "Offline material": "Offline-Material",
  "Events": "Veranstaltungen",
  "You will find everything you need at": "Sie finden alles, was Sie brauchen, auf ",


  // Welcome
  "Home": "Home",
  "Welcome lets get started": "Herzlich Willkommen\u00A0– \nLassen Sie uns starten.",
  "Directly to the bandage selection": "Direkt zur Verbandauswahl",
  "For wound cleaning": "Zur Wundreinigung",
  "Select if wound cleansing has already been carried out": "Bitte auswählen, wenn bereits eine Wundreinigung durchgeführt wurde.",
  "Select if wound cleansing has not yet been carried out": "Bitte auswählen, wenn noch keine Wundreinigung durchgeführt wurde oder Sie mehr erfahren wollen.",
  "Indispensable wound cleansing": "Unerlässlich: \nDie Wundreinigung",
  "Only a clean wound can be properly assessed and further treatment can be derived": "Nur eine gereinigte Wunde kann gut beurteilt und die weitere Behandlung abgeleitet werden.",
  "Effective wound cleaning": "Effektive Wundreinigung",
  "The basis of local wound therapy is wound cleaning": "Grundlage der Lokaltherapie einer Wunde ist die Wundreinigung. Sie unterstützt die Wundheilung durch Schaffung eines optimalen Wundmilieus. Hierzu werden Gewebereste, Beläge und Biofilm entfernt, die Keimlast reduziert und damit das Infektionsrisiko und die Heilungszeit gesenkt.",
  "Video on optimal wound cleaning": "Video zur optimalen Wundreinigung",
  "Allrinse wound irrigation solution": "Unsere Lösung von Coloplast: ",
  "Allrinse is an excellent helper for wound cleaning": "Ein ausgezeichneter Helfer bei der Wundreinigung ist Allrinse®: Mehr als die Lösung zur effektiven Wundreinigung. Eine alkoholfreie Lösung zur Reinigung, Dekontamination, Befeuchtung und Förderung der Heilung von akuten und chronischen Wunden.",
  "To the Allrinse product details": "Zu den Allrinse® Produktdetails",
  "link to Allrinse wound irrigation solution": "Allrinse® Wundspüllösung",
  "Disclaimerp1": "Coloplast bietet Ihnen ein großes Sortiment an modernen Wundversorgungsmaterialien. Die Informationen unseres »VerbandCheck« können Sie dabei unterstützen, den Verband auszuwählen, der am besten für die Versorgung Ihres Patienten geeignet ist.",
  "Disclaimerp2": "Bitte beachten Sie, dass vor Auswahl eines Wundversorgungsprodukts eine sorgfältige individuelle Anamnese unerlässlich ist, um die Ursache der Wunde und der Begleiterkrankungen zu diagnostizieren.",
  "Disclaimerp3": "Der »VerbandCheck« bietet Ihnen eine unverbindliche Produktempfehlung, die auf den allgemeinen Anwendungshinweisen unserer Produkte basiert. Im Einzelfall, ist vor der Anwendung unserer Produkte die Gebrauchsanweisung zu beachten.",


  // Bandage finder
  "Do you want to clean the wound?": "Wollen Sie eine Wundreinigung durchführen?",
  "Wound cleansing": "Wundreinigung",
  "Disclaimer": "Haftungshinweis",

  "No wound cleansing": "Keine Wundreinigung",
  "Why wound cleansing?": "Wundreinigung, wie geht das?",
  "Why wound cleansing text": "Wann wird eine Wundreinigung benötigt? Consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.\n\n" +
    "Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.\n\n" +
    "In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.",

  previous: "Zurück",
  next: "Weiter",

  "Infection": "Bewertung der Wunde",
  "Is wound infected?": "Bitte wählen Sie den vorherrschenden Wundzustand aus.",
  "Wound infected": "Infektionsgefährdete Wunde",
  "Wound not infected": "Wunde ohne Infektionsgefährdung",
  "Show examples": "Beispiele anzeigen",
  "What is an infected wound?": "Was ist eine infizierte Wunde?",
  "Example images": "Beispielbilder",
  // Bottom sheet
  "Beware of signs of wound infection": "Achtung bei Anzeichen einer Wundinfektion",
  "This could be": "Diese können sein:",
  "A wound is considered infected": "Eine Wunde gilt als infiziert, wenn Mikroorganismen vorhanden sind, die das lokale Gewebe schädigen und die Wundheilung verzögern.",
  "Foul smelling/excessively strong wound odor": "übel riechender/übermäßig starker Wundgeruch",
  "No or poor quality granulation tissue": "kein oder minderwertiges Granulationsgewebe",
  "Discoloration of the wound compared to before": "Verfärbung der Wunde im Vergleich zu vorher",
  "Delayed wound healing": "verzögerte Wundheilung",
  "Sensitive granulation tissue": "empfindliches Granulationsgewebe",
  "Severe or worsening local pain": "starke oder stärker werdende lokale Schmerzen",
  "Increased exudation": "vermehrte Exsudation",
  "Pocket formation": "Taschenbildung",
  "Our portal for specialists and doctors": "Unserer Portal für Fachkräfte und Ärzte hält mehr über Wundinfektionen, ihre Symptome und mögliche Behandlungsmethoden, sowie weiteres Wundwissen für Sie bereit!",
  "Read more": "Mehr lesen",
  "Click here for Coloplast Professional": "Hier geht es zu Coloplast® Professional",

  "Tissue": "Wundzustand",
  "What is the tissue status?": "Bitte wählen Sie den vorherrschenden Wundzustand aus.",
  necrotic: "Nekrotisch",
  fibrinous: "Belegt",
  granulating: "Granulierend",
  epitelizing: "Epithelisierend",

  "Exsudation": "Exsudatmenge",
  "How strong is the exudate discharge?": "Bitte wählen Sie die vorhandene Exsudatmenge der Wunde aus.",
  "little exudation": "Wenig",
  "moderate exudation": "Mittel",
  "profusely exudation": "Stark",

  "Surrounding skin": "Umgebungshaut",
  "Select condition of the surrounding skin": "Bitte wählen Sie den Zustand der Wundumgebung aus.",
  "Surrounding skin not intact": "Nicht intakte Umgebungshaut",
  "Surrounding skin intact": "Intakte Umgebungshaut",

  "Wound depth": "Wundtiefe",
  "How deep is the Wound?": "Bitte geben Sie die entsprechende Wundtiefe der Wunde an.",
  "lt2cm": "0 – 2 cm",
  "gt2cm": "> 2 cm",
  "Determine depth": "Wundtiefe bestimmen",
  "Determine depth text": "Um die Tiefe einer Wunde zu bestimmen sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.\n\nDonec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.\n\nIn enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim.",
  "If undermined please use a suitable wound filler": "Bei Unterminierung nutzen Sie bitte einen geeigneten Wundfüller.",

  "Recommended Products": "Produktempfehlungen",
  "Product Category": "Produktkategorie",
  "ProductsOv": "Produktübersicht",
  "Products overview": "Produktübersicht",
  "Product Details": "Details",
  "Filter": "Filter",
  "cancel": "Abbrechen",
  "apply": "Anwenden",
  bandageSelector: "Verbandauswahl",

  painful: "schmerzhaft",
  bacteriallyContaminated: "bakteriell belastet",
  lowInGerms: "keimarm",

  intactSkin: "intakte Haut",
  previouslyDamagedSkin: "vorgeschädigte Haut",

  deepWound: "tiefe Wunde",
  superficialWound: "oberflächliche Wunde",

  littleExudate: "Exsudat gering",
  mediumExudate: "Exsudat mittel",
  highExudate: "Exsudat stark",
  theCategoriesScreen: "Kategorie Übersicht",

  "Error": "Fehlermeldung",
  "You have ordered more samples %prev + %curr than the permitted maximum quantity %all": "Sie haben mehr Muster bestellt (%prev + %curr) als pro Nutzer zulässig sind (%all).",
  "You ordered one or more samples previously %prev": "Sie haben ein (oder mehrere) Produktmuster früher schon bestellt: \n%prev",

  // Treatment Goals
  "Treatment Goals": "Behandlungsziele",
  "Goal exudation management head": "Exudat-Management",
  "Goal exudation management body": "Optimal feuchtes Wundmilieu.",
  "Goal germ reduction head": "Keimreduktion",
  "Goal germ reduction body": "Keimbindende und reduzierende Therapie, Wundspüllösung.",
  "Goal epithelialization head": "Epithelisierung",
  "Goal epithelialization body": "Wundruhe / Gewebeschutz",
  "Goal tissue build-up head": "Gewebeaufbau",
  "Goal tissue build-up body": "Wundruhe",
  "Button Product recommendations": "Weiter zu Produktempfehlungen",


  "No recommendation header": "Sorry ...",
  "No recommendation body": "Diese Kombination von Befunden ist sehr selten, weswegen wir keine Empfehlung für Sie haben.",
  "We do not have any combination": "Für die von Ihnen angegebene Wundkombinationen haben wir leider keine passenden Produktvorschläge. Wir sind uns dennoch sicher, Ihnen eine mögliche Versorgung bieten können.",
  "Feel Free to contact us": "Nehmen Sie dazu aber gerne direkt Kontakt mit uns auf - wir finden eine Lösung.",
  // Bandage selection.
  "Recommendation": "Empfehlung",
  "Alternative": "Alternative",
  "%num Products": "%num Produkte",
  "Primary bandage": "Primärverband",
  "Secondary bandage": "Sekundärverband",
  "Alternate primary dressing": "Alternativer Primärverband",
  "Alternate secondary dressing": "Alternativer Sekundärverband",

  // Product Categories
  "Foam dressings": "Schaumverbände",
  "Wound fillers": "Wundfüller",
  "Hydrocolloid dressings": "Hydrokolloidverbände",
  "Super absorber": "Superabsorber",
  "Wound Contact Dressing": "Wundkontaktauflage",
  "Wound Irrigation Solutions": "Wundspüllösung",
  "Skin Protection": "Hydrogel",

  // Bokkmarks
  "Add more": "Mehr hinzufügen",
  "Remove all": "Alles leeren",
  "There is no product on your bookmark list yet": "Es befindet sich noch kein Produkt auf Ihrer Merkliste.",
  "Delete all bookmarks": "Alle Einträge löschen",
  "Are you sure you want to delete all bookmarks": "Sind Sie sicher, dass Sie alle Einträge aus der Merkliste löschen wollen?",
  "Delete": "Löschen",
  "Cancel": "Abbrechen",

  // Sample Order
  "SampleOrder": "Musterbestellung",
  "Sample order": "Musterbestellung",
  "Empty cart": "Warenkorb leeren",
  "Are you sure you want to delete all items in the cart": "Sind Sie sicher, dass Sie alle Produkte aus dem Korb entfernen wollen?",
  "There is no product in your cart yet": "Es befindet sich noch kein Produkt in Ihrer Musterbestellung.",
  "Go to checkout": "Weiter",
  "Your sample order has been sent successfully": "Ihre Musterbestellung wurde erfolgreich abgeschickt.",
  "New Selection": "Neue Auswahl",
  // Contact
  "Contact": "Kontakt",
  "Company Address": "Firmenadresse",
  "Address concrete": "Coloplast GmbH \nKuehnstr. 75 \n22045 Hamburg",
  "Legal IDs concrete": "Amtsgericht Hamburg HRB 65501 \nUSt.-Id.-Nr.: DE 247 070 750",
  "Managing Director": "Geschäftsführer",
  "Managing Director concrete": "Henning Reichardt",
  "PO box address": "Postfachadresse",
  "PO box address concrete": "Coloplast GmbH \nPostfach 70 03 40 \n22003 Hamburg",
  "Here you will find our": "Hier finden Sie unseren",
  "Disclaimer": "Haftungshinweis",
  "Privacy Policy": "Datenschutzerklärung",
  "Important Info": "Wichtige Informationen",
  "Write an e-mail": "E-Mail schreiben",
  "mailto-address": "mailto:verbandcheck@coloplast.com",
  "Call us by phone": "Rufen Sie uns an",
  "tel-num": "tel:+494066980777",
}
