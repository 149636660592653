import React, { Component } from 'react';
import { Text, Box, VStack, Heading, Link, Center } from "native-base";
import { FontAwesome, Foundation } from "@expo/vector-icons"
import { connect } from 'react-redux';
import t from '../../../translation/translation'
import { resetDecisions, setDecision } from "../../../store/actions/decisionTree";
import Screen from "../../Component/Screen";
import StepId from "../../Component/StepId";
import PageText from "../../Component/PageText";
import AnswerSelect from "../../Component/AnswerSelect";
import CpIconButton from "../../Component/CpIconButton";
import PrevNext from "../../Component/PrevNext";
import BottomSheet from "../../Component/BottomSheet";
import Image from "../../Component/Image";
import Ulist from "../../Component/Ulist";

class InfectedScreen extends Component {

  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.infoRef = React.createRef();

  }

  componentDidUpdate(prevProps, prevState, x) {
    // Reset the navigation to first item when leaving Wizard.
    if (!this.props.woundInfected) {
      this.props.navigation.reset({ index: 0, routes: [{ name: 'Infection' }] });
    }
  }

  render() {
    const continueWith = (result) => {
      this.props.setResult(result);
    }

    const footer = (
      <PrevNext
        prev={() => {
          this.props.resetResult()
          this.props.navigation.navigate('Welcome')
        }
        }
        prevDisabled={false}
        next={() => this.props.navigation.navigate('Tissue')}
        nextDisabled={!this.props.woundInfected}
      />);

    const options = [
      { '30': t('Wound infected') },
      { '31': t('Wound not infected') }
    ];

    /**
     * 30: Infected wound
     * 31: Not infected wound
     */
    return (
      <Screen scrollable={true} footer={footer}>
        <StepId stepCount={6} current={0} nav={this.props.navigation} />
        <VStack mx={6} flex={1} space={6} alignItems="center">

          <PageText
            fontSize={37}
            heading={t('Infection')}
            text={t('Is wound infected?')} />

          <AnswerSelect options={options}
            defaultValue={this.props.woundInfected + ''}
            onAnswered={(answer) => { continueWith(answer); }} />


          <Box width='100%' mt={8}>
            <CpIconButton icon={{ as: FontAwesome, name: "eye" }}
              label={t("Show examples")}
              onPressed={() => {
                this.componentRef.current.showBottomSheet()
              }} />
            <CpIconButton icon={{ as: Foundation, name: "info" }}
              label={t("What is an infected wound?")}
              onPressed={() => {
                this.infoRef.current.showBottomSheet()
              }} />
          </Box>
        </VStack>

        <BottomSheet ref={this.componentRef} heading={t('Example images')} height={1000}>
          <Box mx={6}>
            <Box my={3}>
              <Image id="wound_infected"
                alt={t('Wound infected')}
                style={{ borderRadius: 4, overflow: 'hidden' }} />
              <Text fontSize={16} my={1}>{t('Wound infected')}</Text>
            </Box>
            <Box my={3}>
              <Image id="wound_not_infected"
                alt={t('Wound not infected')}
                style={{ borderRadius: 4, overflow: 'hidden' }} />
              <Text fontSize={16} my={1}>{t('Wound not infected')}</Text>
            </Box>
          </Box>
        </BottomSheet>

        <BottomSheet ref={this.infoRef} heading={t('Wound infected')} height={1000}>
          <Box mx={6} my={3}>
            <Heading fontSize={{ base: 25, sm: 34, md: 38 }} color={'#2E3233'} mb={3}>{t("Beware of signs of wound infection")}</Heading>
            <Text fontSize={16} my={3} color={'#2E3233'} >{t("A wound is considered infected")}</Text>
            <Text fontSize={16} color={'#2E3233'} fontWeight={600}>{t("This could be")}</Text>
            <Ulist style={{ mb: 3, mt: 0 }} textStyle={{ fontSize: 16, color: '#2E3233' }}
              data={[
                t("Foul smelling/excessively strong wound odor"),
                t("No or poor quality granulation tissue"),
                t("Discoloration of the wound compared to before"),
                t("Delayed wound healing"),
                t("Sensitive granulation tissue"),
                t("Severe or worsening local pain"),
                t("Increased exudation"),
                t("Pocket formation"),
              ]} />
            <Text my={3} fontSize={16} fontWeight={600} color={'primary.600'}>{t("Our portal for specialists and doctors")}</Text>
            <Link href="https://www.coloplastprofessional.de/wundversorgung/wundwissen/wundinfektion/"
              isExternal
              _text={{
                fontSize: 16,
                color: "primary.700",
              }}>{t("Click here for Coloplast Professional")}</Link>
          </Box>
        </BottomSheet>
      </Screen>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    woundInfected: state.decisionTree.woundInfected
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setResult: (valObj) => {
      return dispatch(setDecision({ woundInfected: valObj }))
    },
    resetResult: () => {
      return dispatch(resetDecisions())
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(InfectedScreen)
