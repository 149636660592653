import React, { Component } from "react";
import { Heading, HStack, VStack } from "native-base";
import FilterItem from "./FilterItem";
import PropTypes from "prop-types";


export default class FilterGroup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filter: this.props.current,
    }
  }

  onAddFilter(item) {
    const newFilter = [...this.state.filter, item];
    this.setState({ filter: newFilter });
    this.props.onChange(newFilter);
  }

  onRemoveFilter(item) {
    const newFilter = [...this.state.filter].filter(match => item !== match);
    this.setState({ filter: newFilter });
    this.props.onChange(newFilter);
  }

  render() {
    const filterComp = this.props.items.map((item) => {
      return (<FilterItem key={item.key}
        title={item.val}
        enabled={(this.props.current.indexOf(item.key) >= 0)}
        onEnable={() => this.onAddFilter(item.key)}
        onDisable={() => this.onRemoveFilter(item.key)} />);
    });

    return (
      <VStack mb={6}>
        <Heading size="xl" mb={2}>{this.props.title}</Heading>
        <HStack flexWrap="wrap" mx={-2}>
          {filterComp}
        </HStack>
      </VStack>)
  }
}


FilterGroup.propTypes = {
  onChange: PropTypes.func,
  current: PropTypes.array,
  filter: PropTypes.object,
  title: PropTypes.string,
};

FilterGroup.defaultProps = {
  onChange: (filter) => { console.log(filter) },
  current: [],
  filter: {},
  title: ''
}
