import React, { Component } from 'react';
import { Image } from 'native-base';
import { ActivityIndicator, View } from 'react-native';

class MediaImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      original: this.props,
      height: 60
    }
  }

  getRatio() {
    return Math.round(this.state.original.width / this.state.original.height);
  }

  onLayout(layout) {
    this.setState({
      height: Math.round(layout.width/this.getRatio())
    })
  }

  render() {
    let style = {width: '100%', height: this.state.height};
    if (typeof this.props.style === 'object') {
      style = {...this.props.style, ...style}
    }
    const alt = (typeof this.state.original.alt === 'string') ? this.state.original.alt : this.state.original.name;
    return (
      <View style={style} onLayout={(e) => {this.onLayout(e.nativeEvent.layout)}}>
        <Image source={{uri: this.state.original.src}}
               PlaceholderContent={<ActivityIndicator />}
               key={'m' + this.state.original.id}
               alt={alt}
               size={'100%'}
        />
      </View>
    );
  }
}

export default MediaImage;
