import React, { Component } from 'react';
import Screen from "../../Component/Screen";
import t from "../../../translation/translation";
import {Button, Box, Heading, HStack, Text, Switch, VStack, Link} from 'native-base';

import { connect } from 'react-redux';
import BottomSheet from "../../Component/BottomSheet";
import Image from "../../Component/Image";
import CpIconButton from "../../Component/CpIconButton";
import {Foundation} from "@expo/vector-icons";
import Ulist from "../../Component/Ulist";
import ColoSpinner from "../../Component/Spinner";


class ProcessUpdateScreen extends Component {

  constructor(props) {
    super(props);
    this.proRef = React.createRef();
    this.state = {
      status: 'await'
    }
  }

  submitForm = () => {
    // This part must be moved to Disclaimer screen.
    this.props.accountUI.updateAccountData({
        // To get access login is required.
        name: this.props.user.name,
        email: this.props.user.email,
        password: this.props.user.password,
      }, {...this.props.user.data}
    ).then((result) => {
      this.setState({ status: result});
    });
  }

  componentDidMount() {
    this.submitForm()
  }

  render() {
    let content;
    switch (this.state.status) {
      case 'success':
        content = (<>
          <Heading size="2xl" my={16}>{t('Your account details have been successfully transmitted')}</Heading>
          <Box><Text fontSize="16px">{t('Do you want to refresh your knowledge or develop new skills')}</Text></Box>
          <Box width='100%' mb={6}>
            <CpIconButton icon={{as: Foundation, name:"info"}}
                          label={t("More about Coloplast Professional")}
                          onPressed={() => {this.proRef.current.showBottomSheet()}} />
          </Box>
        </>)
        break;
      case 'error':
      case 'mistyped':
      case 'offline':
        content = (<>
          <Heading size="2xl" my={16}>{t('Server error')}</Heading>
          <Box><Text fontSize="16px">{t('Please contact our service team when error comes up repeatedly')}</Text></Box>
          <Box width='100%' mb={6}>
            <CpIconButton icon={{as: Foundation, name:"info"}}
                          label={t("More about Coloplast Professional")}
                          onPressed={() => {this.proRef.current.showBottomSheet()}} />
          </Box>
        </>)
        break;
      default:
        content = (<ColoSpinner/>)
    }

    const footer = (
      <HStack space={4} mx={6}>
        <Button w={'48%'} size={'lg'} variant="subtl" colorScheme="secondary"
                onPress={() => this.props.navigation.navigate('Disclaimer')}>{t('previous')}</Button>
      </HStack>)

    return (
      <Screen footer={footer}>
        <VStack flex={1} mx={6} mt={5} space={4}>
          {content}

          <BottomSheet ref={this.proRef} heading={t('Learn more')} height={1000}>
            <Box mx={6} my={3} maxW={200} pl="2px">
              <Image id="cp_professional_logo" alt={'Coloplast Professional'} />
            </Box>

            <Box my={3}>
              <Image id="cp_professional"
                     alt={'Coloplast Professional'}
                     style={{}}  />
            </Box>

            <Box mx={6} my={3}>
              <Text mt={3} fontWeight={600} fontSize={16} color={'primary.600'}>{t("Everything for healthcare professionals working in wound care")}</Text>
              <Ulist style={{mb: 3, mt:1}} textStyle={{fontSize: 16, color: '#2E3233'}}
                     data={[
                       t("E-learning modules"),
                       t("Web seminars"),
                       t("Face-to-face seminars"),
                       t("Offline material"),
                       t("Events"),
                     ]} />

              <Text fontSize="16px" color={'#2E3233'}>{t("You will find everything you need at")}
                <Link href="https://www.coloplastprofessional.de/"
                      isExternal
                      _text={{
                        color: "primary.600",
                        fontSize: "16px",
                        fontFamily: "Coloplast",
                      }}>{'coloplastprofessional.de'}</Link>
              </Text>

            </Box>
          </BottomSheet>

        </VStack>
      </Screen>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    accountUI: state.login.accountUI
  }
}

export default connect(mapStateToProps)(ProcessUpdateScreen);
