import React, { Component } from 'react';
import {Avatar, Box, Button, Center, Heading, HStack, ScrollView, Text, VStack, useToast} from 'native-base';
import { connect } from 'react-redux';
import t from "../../../translation/translation";
import { logout } from "../store/actions";
import Screen from "../../Component/Screen";

class AccountScreen extends Component {
  render() {

    let avatarProps = (this.props.image)
      ? {source: {uri: this.props.image}} : {};
    avatarProps.size = 'lg';
    avatarProps.bgColor = 'primary.600';
    avatarProps._text = {
      color: '#FFF',
      fontSize: 16.001,
      fontWeight: 700
    };

    let uName = this.props.name;
    let initials = ''
    for(let word of uName.split(' ')) {
      initials = initials + word.toUpperCase().charAt(0);
    }

    const action = (this.props.offline)
      ? (<Box border={1} borderRadius={5} p={2} mt={4} borderColor={'yellow.500'}>
        <Text color={'yellow.500'}>{t('You are in offline mode')}</Text>
      </Box>) : [];

    const logout = (!this.props.offline) ?
      (<Button size={'lg'} variant={'solid'} onPress={() => {
        this.props.doLogout()}}>{t('Logout')}</Button>) : [];

    const footer = (
      <VStack space={2} mx={6}>
        <Button size={'lg'} variant="subtl" onPress={() => {
          this.props.navigation.navigate('Update');
        }}>{t('Update Account')}</Button>
        {logout}
      </VStack>);

    return (
      <Screen footer={footer}>
        <Box safeArea flex={1} p={2} w="90%" mx='auto'>
          <HStack>
            <Avatar {...avatarProps}>{initials}</Avatar>
            <VStack px={4} justifyContent={'center'}>
              <Heading size={'sm'} color="gray.700">{this.props.name}</Heading>
              <Text fontSize={16.001} mt={1} color="gray.500" fontWeight={400}>{this.props.email}</Text>
            </VStack>
          </HStack>
          <VStack space={4} my={4}>
            {action}
          </VStack>
        </Box>
      </Screen>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    name: state.login.user.name,
    image: state.login.user.image,
    email: state.login.user.email,
    offline: state.login.user.offline,
    valid: state.login.user.valid,
    lastValidation: state.login.user.lastValidation,
    accountUI: state.login.accountUI
  }
}

const mapDispatchToProps = dispatch => {
  return {
    doLogout: () => {
      dispatch(logout())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountScreen);

