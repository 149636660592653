import React, { Component } from "react";
import { Platform } from 'react-native';
import { StatusBar } from "expo-status-bar";
import { Link, Box, HStack, Pressable, Text, VStack } from "native-base";
import { createDrawerNavigator, DrawerContentScrollView, DrawerItem } from "@react-navigation/drawer";
import NavigatorSwitch from "../Login/NavigatorSwitch";
import ProductsNavigator from "../Products/ProductsNavigator";
import ProductsFavNav from "../Products/ProductsFavNav";
import ProductsCartNav from "../Products/ProductsCartNav";
import DecisionTreeNavigator from "../DecisionTree/DecisionTreeNavigator";
import Logo from "../Component/Logo";
import { HeaderBtnsLeft, HeaderBtnsRight } from "../Products/Component/HeaderBtns"
import WelcomeScreen from "../Screens/WelcomeScreen";
import ContactScreen from "../Screens/ContactScreen";
import t from "../../translation/translation";
import { resetDecisions } from "../../store/actions/decisionTree";
import { connect } from "react-redux";
import { appInitialized } from "../../store/actions/main";
// import You from '../Login/Components/You';

const Drawer = createDrawerNavigator();

const styles = {
  text: {
    color: 'white',
    fontWeight: 400,
    textTransform: 'uppercase',
    fontSize: 24,
    letterSpacing: 1.2
  },
  drawerStyle: {
    backgroundColor: '#00b0ca',
    width: (Platform.OS === 'web') ? 400 : '100%',
  },
  header: {
    headerTitleAlign: 'center',
    headerStyle: {
      backgroundColor: '#00b0ca',
      borderBottomWidth: 0,
      shadowColor: 'transparent',
      elevation: 0,
    },
    headerTintColor: '#fff',
    headerTitleStyle: {
      textTransform: 'uppercase',
      fontSize: 17.999,
      letterSpacing: 0.6,
      textAlign: 'center',
      fontFamily: 'Coloplast-Bold'
    }
  }
}

function CustomDrawerContent(props) {
  return (
    <DrawerContentScrollView {...props} >
      <VStack space={6} my={2} mx={4} justifyContent="space-between" minHeight="90%">
        <VStack space={4} marginTop="30%">
          <VStack space={3}>
            {props.state.routeNames.map((name, index) => {
              if (['Wizard'].indexOf(name) >= 0) {
                return []
              }
              const activeStyle = (index === props.state.index) ? { fontWeight: 800 } : {};
              return (
                <Pressable key={index} px={5} py={1} rounded="md" style={{ cursor: 'pointer' }}
                  bg={'transparent'}
                  onPress={(event) => {
                    props.navigation.navigate(name);
                  }}
                >
                  <HStack space={7} alignItems="center">
                    <Text  {...styles.text} {...activeStyle}>{t(name)}</Text>
                  </HStack>
                </Pressable>
              )
            })}
          </VStack>
        </VStack>
        <VStack my={3} mx={6}>
          <Pressable style={{ cursor: 'pointer' }}
            onPress={(e) => {
              props.navigation.navigate('Welcome');
            }}>
            <Logo width={154} fill='white' />
          </Pressable>
        </VStack>
      </VStack>
    </DrawerContentScrollView>
  );
}

class DrawerNav extends Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.props.appInit();
  }

  componentDidMount() {
    this.props.appInit();
  }

  render() {
    let drawerKey = 0;
    const headerOpts = {
      headerRight: () => (<HeaderBtnsRight />)
    }

    const overlay = (!this.props.splashFinished) ? (
      <Box position="absolute" zIndex={5} top={0}
        right={0} bottom={0} left={0} bg={'#78BFD3'} />) : [];

    return (
      <Box flex={1} style={{ position: "relative" }}>
        {overlay}
        <StatusBar style="light" translucent={true} />
        <Drawer.Navigator
          screenOptions={{
            drawerStyle: styles.drawerStyle, ...styles.header,
            headerLeft: () => (<HeaderBtnsLeft />)
          }}
          drawerContent={(props) => <CustomDrawerContent key={'drawer_key_' + drawerKey++} {...props} />}
        >
          <Drawer.Screen name="Welcome" component={WelcomeScreen} options={{ title: t("Home"), ...headerOpts }} />
          <Drawer.Screen name="Wizard" component={DecisionTreeNavigator} options={{ title: t("Wizard"), ...headerOpts }}
            listeners={{
              blur: (e) => {
                this.props.resetResult();
              }
            }} />
          <Drawer.Screen name="ProductsOv" component={ProductsNavigator} options={{ title: t("Products overview"), ...headerOpts }} />
          <Drawer.Screen name="Bookmarked" component={ProductsFavNav} options={{ title: t("Bookmarked"), ...headerOpts }} />
          {/* <Drawer.Screen name="SampleOrder" component={ProductsCartNav} options={{ title: t("Sample order"), ...headerOpts }} /> */}
          {/* <DrawerItem ><Link href="https://produkte.coloplast.de/produkte/wundversorgung?utm_source=verbandcheck&utm_medium=app&utm_campaign=musterbestellung">t("Sample order")</Link></DrawerItem> */}
          {/* <Drawer.Screen name="Your Account"  component={NavigatorSwitch} options={{title: t("Your Account"), ...headerOpts}} /> */}
          <Drawer.Screen name="Contact" component={ContactScreen} options={{ title: t("Contact") }} />
        </Drawer.Navigator>
      </Box>
    );
  }
}


const mapStateToProps = (state) => ({
  splashFinished: state.main.splashFinished,
})

const mapDispatchToProps = dispatch => {
  return {
    resetResult: () => {
      return dispatch(resetDecisions())
    },
    appInit: () => dispatch(appInitialized())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerNav);
