import React, { Component } from 'react';
import { Box } from 'native-base';
import StepIndicator from 'react-native-step-indicator';

class StepId extends Component {

  render() {
    const indicatorStyles = {
      stepIndicatorSize: 12,
      separatorStrokeWidth: 3,
      labelColor: 'transparent',
      labelSize: 1,
      labelAlign: 'center',
      stepStrokeWidth: 3,

      stepStrokeCurrentColor: '#00B0CA',
      stepIndicatorCurrentColor: '#00B0CA',
      currentStepIndicatorSize: 12,
      currentStepStrokeWidth: 5,
      currentStepIndicatorLabelFontSize: 1,
      currentStepLabelColor: '#00B0CA',
      stepIndicatorLabelCurrentColor: '#00B0CA',

      separatorUnFinishedColor: '#D4D5D5',
      stepIndicatorUnFinishedColor: '#D4D5D5',
      stepIndicatorLabelUnFinishedColor: 'transparent',
      separatorStrokeUnfinishedWidth: 2,
      stepStrokeUnFinishedColor: '#F3E9E0',

      separatorFinishedColor: '#00B0CA',
      stepIndicatorLabelFontSize: 1,
      stepIndicatorFinishedColor: '#00B0CA',
      stepIndicatorLabelFinishedColor: 'transparent',
      separatorStrokeFinishedWidth: 3,
      stepStrokeFinishedColor: '#00B0CA',
    };

    const state = this.props.nav.getState();
    const labels = state.routeNames;
    const currentPage = this.props.current;

    const onStepPress = (position) => {
      if (position < state.index) {
        this.props.nav.push(labels[position]);
      }
    };

    const renderLabel = ({ position, label, currentPosition}) => {
      return (<></>);
      /*return (<Text style={ position === currentPosition
          ? styles.stepLabelSelected : styles.stepLabel}>{label}</Text>);*/
    };

    return (
      <Box py={7}>
        <StepIndicator
          customStyles={indicatorStyles}
          currentPosition={currentPage}
          stepCount={6}
          labels={['A', 'B', 'C', 'D', 'E', 'F']}
          renderLabel={renderLabel}
          onPress={onStepPress}
        />
      </Box>
    )
  }
}

export default StepId;
