import React,  { Component } from "react";
import {Box, Center, HStack, Text} from "native-base";
import { Foundation } from "@expo/vector-icons";


class CpInfoBox extends Component {
  render() {
    const size = 47.9999;
    const icon = (<Center w={size} h={size} borderRadius={32} backgroundColor={'#fff'}>
      <Foundation name="info" color="#2E3233" size={25} textAlign="center" />
    </Center>);

    return (
      <HStack>
        {icon}
        <Box flex={1} justifyContent={'center'} px={3}>
          <Text fontSize={'md'} color='#2E3233'>{this.props.label}</Text>
        </Box>
      </HStack>)
  }
}

export default CpInfoBox;
