import * as React from 'react';
import {Component} from "react";
import {connect} from 'react-redux';
import Screen from "../../Component/Screen";
import {
  AlertDialog,
  Box,
  Heading,
  Text,
  VStack,
  FormControl,
  Input,
  Button,
  ScrollView
} from 'native-base';
import { validateEmail, validateLength } from "../service/validation";
import t from "../../../translation/translation";
import Alert from "../Components/Alert";
import PageText from "../../Component/PageText";
import {KeyboardAvoidingView, Platform} from "react-native";

class PasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.email,
      formError: {},
      alert: { showAlert: false }
    };
  }

  validateForm = () => {
    let errors = {}
    if (!validateEmail(this.state.email)) {
      errors.email = t('E-mail address not valid');
    }
    this.setState({formError: {...errors}});
    return (Object.keys(errors).length === 0);
  }

  submitResetPassword = async () => {
    if (this.validateForm()) {
      const submitted = await this.props.accountUI.resetPassword(this.state.email);
      switch (submitted) {
        case 'error':
          this.setState({ alert: {
              open: true,
              header: t('E-mail error'),
              body: t('Seems e-mail not registered yet'),
              onClosed: () => { this.setState({alert: { showAlert: false }}) }
            }
          });
          break;
        case 'offline':
          this.setState({ alert: {
              open: true,
              header:  t('Offline error'),
              body: t('Server error'),
              onClosed: () => { this.setState({alert: { showAlert: false }}) }
            }
          });
          break;
        case 'success':
          this.setState({ alert: {
              open: true,
              header: t('Succeeded'),
              body: t('Please check your e-mails'),
              onClosed: () => {
                this.setState({alert: { showAlert: false }});
                this.props.navigation.navigate('Login');
              }
            }
          });
          break;
        case 'mistyped':
          this.setState({ alert: {
              open: true,
              header: 'Program error',
              body: 'Unexpected response. Check coding of the account API for reset a user password.',
              onClosed: () => {
                this.setState({alert: { showAlert: false }});
              }
            }
          });
          break;
        default:

      }
    }
  }

  render = () => {
    const footer = (
      <VStack space={2} mx={6}>
        <Button size={'lg'} onPress={() => {this.submitResetPassword()}}>{t('Submit')}</Button>
      </VStack>);

    return (
      <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{flex: 1}}>
        <Screen footer={footer}>
          <VStack flex={1} mx={6} mt={8}>

            {/* Feedback from from submission (error, success). */}
            <Alert {...this.state.alert} />

            <PageText heading={t('Reset Password')}
                      size="xl"
                      text={t('Reset password text')}/>
            <FormControl mt={5} isRequired isInvalid={'email' in this.state.formError}>
              <FormControl.Label>{t('Email')}</FormControl.Label>
              <Input size="xl" type="text" value={this.state.email} onChangeText={email => {
                this.setState({email: email});
              }} />
              <FormControl.ErrorMessage>{this.state.formError.email}</FormControl.ErrorMessage>
            </FormControl>

            <VStack mt={5} space={2} alignItems="flex-end">
              <Button variant="ghost" size="lg"
                      onPress={() => {this.props.navigation.navigate('Login');}}>{t('Return to Login')}</Button>
            </VStack>
          </VStack>
        </Screen>
      </KeyboardAvoidingView>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    email: state.login.user.email,
    accountUI: state.login.accountUI
  }
}

export default connect(mapStateToProps)(PasswordScreen);
