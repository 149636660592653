import React from "react";
import {Accordion, Icon, Text} from "native-base";
import {Feather} from "@expo/vector-icons";

function CpAccordion(props) {
  const size = (props.size) ? props.size : 'sm';
  const iconProps = (props.size === 'xs') ?
    {size: size, name: 'plus', mr: 4.002, style: { fontSize: 16}}
    : {size: size, name: 'plus', style: {fontSize: 24}};

  const accProps = (props.size === 'xs') ?
    {my: -2, mb: -3, mx: -3, borderRadius: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 0} :
    {mb: 2, borderRadius: 0, borderLeftWidth: 0, borderRightWidth: 0};

  const items = props.items.map((item, index) => {
    const title = item.slice(0,1);
    const body = item.slice(1);

    return (
      <Accordion.Item key={index} index={index}>
        <Accordion.Summary>{title}<Icon
          as={Feather} {...iconProps} /></Accordion.Summary>
        <Accordion.Details>{body}</Accordion.Details>
      </Accordion.Item>)
  });

  return (
    <Accordion {...accProps}>
      {items}
    </Accordion>
  );
}

export default CpAccordion;
