import React, { Component } from 'react';
import { connect } from 'react-redux';
import t from "../../../translation/translation";
import Screen from "../../Component/Screen";
import {Button, Box, Heading, HStack, Text, VStack, Link} from 'native-base';
import BottomSheet from "../../Component/BottomSheet";
import Image from "../../Component/Image";
import CpIconButton from "../../Component/CpIconButton";
import {Foundation} from "@expo/vector-icons";
import Ulist from "../../Component/Ulist";
import ColoSpinner from "../../Component/Spinner";
import { emptyCart } from "../store/actions";


class ProcessOrderScreen extends Component {

  constructor(props) {
    super(props);
    this.proRef = React.createRef();
    this.state = {
      status: 'await',
      message: '',
      errorType: '',
    }
  }

  submitForm = async () => {
    this.props.accountUI.submitOrder(this.props.cart)
      .then((result) => {
        if (typeof result === 'object' && result.status) {
          if (result.status === 'error') {
            switch (result.type) {
              case 'max_items':
                this.setState({
                  status: 'error',
                  message: t('You have ordered more samples %prev + %curr than the permitted maximum quantity %all')
                    .replace('%all', result.data.allowed)
                    .replace('%curr', result.data.current)
                    .replace('%prev', result.data.previous)
                });
                break;
              case 'duplicates':
                this.setState({
                  status: 'error',
                  message: t('You ordered one or more samples previously %prev')
                    .replace('%prev', Object.values(result.data).join(', '))
                });
                break;
              default:
                this.setState({
                  status: 'mistyped'
                });
            }
          } else if(result.status === 'success') {
            this.props.emptyCart();
            this.setState({
              status: 'success',
            });
          }
        }
      });
  }

  componentDidMount() {
    this.submitForm()
  }

  render() {
    let content;
    switch (this.state.status) {
      case 'success':
        content = (<>
          <Heading size="2xl" my={16}>{t('Your sample order has been sent successfully')}</Heading>
          <Box><Text fontSize="16px">{t('Do you want to refresh your knowledge or develop new skills')}</Text></Box>
          <Box width='100%' mb={6}>
            <CpIconButton icon={{as: Foundation, name:"info"}}
                          label={t("More about Coloplast Professional")}
                          onPressed={() => {this.proRef.current.showBottomSheet()}} />
          </Box>
        </>)
        break;
      case 'error':
        content = (<>
          <Heading size="2xl" my={16}>{t('Error')}</Heading>
          <Box p={4} borderWidth={2} borderColor={'#f96600'} bg="#FFF" mb={6}><Text fontSize="16px">{this.state.message}</Text></Box>
          <Box width='100%' mb={6}>
            <CpIconButton icon={{as: Foundation, name:"info"}}
                          label={t("More about Coloplast Professional")}
                          onPressed={() => {this.proRef.current.showBottomSheet()}} />
          </Box>
        </>)
        break;
      case 'mistyped':
      case 'offline':
        content = (<>
          <Heading size="2xl" my={16}>{t('Server error')}</Heading>
          <Box><Text fontSize="16px">{t('Please contact our service team when error comes up repeatedly')}</Text></Box>
          <Box width='100%' mb={6}>
            <CpIconButton icon={{as: Foundation, name:"info"}}
                          label={t("More about Coloplast Professional")}
                          onPressed={() => {this.proRef.current.showBottomSheet()}} />
          </Box>
        </>)
        break;
      default:
        content = (<ColoSpinner/>)
    }

    const footer = (
      <HStack space={4} mx={6}>
        <Button w={'48%'} size={'lg'} variant="subtl" colorScheme="secondary"
                onPress={() => this.props.navigation.navigate('Disclaimer')}>{t('previous')}</Button>
      </HStack>)

    return (
      <Screen footer={footer}>
        <VStack flex={1} mx={6} mt={5} space={4}>
          {content}

          <BottomSheet ref={this.proRef} heading={t('Learn more')} height={1000}>
            <Box mx={6} my={3} maxW={200} pl="2px">
              <Image id="cp_professional_logo" alt={'Coloplast Professional'} />
            </Box>

            <Box my={3}>
              <Image id="cp_professional"
                     alt={'Coloplast Professional'}
                     style={{}}  />
            </Box>

            <Box mx={6} my={3}>
              <Text mt={3} fontWeight={600} fontSize={16} color={'primary.600'}>{t("Everything for healthcare professionals working in wound care")}</Text>
              <Ulist style={{mb: 3, mt:1}} textStyle={{fontSize: 16, color: '#2E3233'}}
                     data={[
                       t("E-learning modules"),
                       t("Web seminars"),
                       t("Face-to-face seminars"),
                       t("Offline material"),
                       t("Events"),
                     ]} />

              <Text fontSize="16px" color={'#2E3233'}>{t("You will find everything you need at")}
              <Link href="https://www.coloplastprofessional.de/"
                    isExternal
                    _text={{
                      color: "primary.600",
                      fontSize: "16px",
                      fontFamily: "Coloplast",
                    }}>{'coloplastprofessional.de'}</Link>
              </Text>

            </Box>
          </BottomSheet>

        </VStack>
      </Screen>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    cart: state.products.cart,
    accountUI: state.login.accountUI
  }
}
const mapDispatchToProps = dispatch => {
  return {
    emptyCart: () => dispatch(emptyCart())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessOrderScreen);
