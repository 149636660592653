import React, { Component } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Spinner from "../../Component/Spinner";
import Screen from "../../Component/Screen";
import t from "../../../translation/translation";
import { Button, Box } from 'native-base';

import { connect } from 'react-redux';
import { saveUser } from "../store/actions";


class StartupScreen extends Component {

  validateLogin = async (userData) => {
    // Validate login with server. (boolean or string 'offline')
    const user = await this.props.accountUI.connectWithAccount(userData);
    if (typeof user === 'object') {
      const accountStatus = {
        offline: false,
        valid: true,
        lastValidation: new Date().getTime()
      }
      this.props.setUserData({...userData, ...user, ...accountStatus});
    } else if (typeof user === 'string') {
      switch(user) {
        case 'offline':
          this.props.setUserData({
            ...userData,
            offline: true,
            valid: true
          }, false);
          break;
        case 'error':
          this.props.navigation.navigate("Login");
          this.props.setUserData({
            ...userData,
            offline: false,
            valid: false
          }, false);
          break;
        default:
      }
    }
  }

  navigateLogin() {
    this.props.navigation.navigate("Login")
  }

  componentDidMount() {
    AsyncStorage.getItem('userData')
      .then(storedUserData => {
        if (storedUserData) {
          const parsedUserData = JSON.parse(storedUserData);
          this.validateLogin(parsedUserData);
        } else {
          setTimeout(() => this.navigateLogin(), 1000);
        }
      })
      .catch(e => {
        console.log('StartScreen.componentDidMount: ', e)
      });
  }

  render() {
    return (
      <Screen>
        <Box flex={1} mx={6}>
          <Spinner />
          <Button onPress={() => {this.navigateLogin()}}>{t('Login')}</Button>
        </Box>
      </Screen>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    accountUI: state.login.accountUI
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUserData: (user, async) => {
      async = (typeof async !== 'undefined') ? async : true;
      return dispatch(saveUser(user, async))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StartupScreen);
