import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";

import ProductsCart from "./Screens/ProductsCart";
import ProductScreen from "./Screens/ProductScreen";
import UserDataSwitch from "./Component/UserDataSwitch";
import DisclaimerScreen from "../Login/Screens/DisclaimerScreen";
import ProcessOrderScreen from "./Screens/ProcessOrderScreen";
import { prodScreenOpts } from "./Component/navopts";

function ProductsCartNav() {
  const StackNavi = createStackNavigator();

  const screenOptions = {
    headerShown: false,
    cardStyleInterpolator: ({ current }) => ({
      cardStyle: {
        opacity: current.progress
      },
    })
  }

  return (
    <StackNavi.Navigator screenOptions={screenOptions}>
      <StackNavi.Screen name="Products overview" component={ProductsCart} />
      <StackNavi.Screen name="Product Details" component={ProductScreen} options={prodScreenOpts} />
      <StackNavi.Screen name="Update" component={UserDataSwitch} />
      <StackNavi.Screen name="Disclaimer" component={DisclaimerScreen} />
      <StackNavi.Screen name="Process" component={ProcessOrderScreen} />
    </StackNavi.Navigator>
  )
}

export default ProductsCartNav;
