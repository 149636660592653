import { Buffer } from "buffer";

class drupalJsonApi {

  domain = null;
  basicAuth = null;
  basePath = '/';

  token = '';
  loading = false;
  user = {};

  constructor(conf) {
    if (typeof conf.domain !== 'string') return false;
    this.domain = conf.domain;
    this.basePath = (typeof conf.basePath === 'string')
      ? conf.basePath : this.basePath;
    this.basicAuth = (typeof conf.basicAuth === 'string')
      ? conf.basicAuth : this.basicAuth;

    this.getToken();
  }

  /**
   * Receive a session token before to exchange data.
   */
  getToken = () => {
    try {
      fetch(this.domain + this.basePath + 'session/token')
        .then(response => response.text())
        .then(data => {
          this.token = data
        }).catch(e => {
          console.log('GET TOKEN FAILED (I): ', e)
        });
    } catch(e) {
      console.log('GET TOKEN FAILED (II): ', e);
    }
  }

  /**
   * Prepares user data to authenticate in the backend.
   *
   * @param user {Object}
   */
  setUser = (user) => {
    this.user = user;
    this.user.basicAuth = (
      typeof user.name !== "undefined" && user.name
      && typeof user.password !== "undefined" && user.password
    ) ? Buffer.from(user.name + ':' + user.password).toString('base64') : '';
  }

  /**
   * Send a register new user request.
   *
   * @param user {Object}
   *   Expects an object with at least these properties: name, email, password.
   *
   * @returns {string}
   *   Returns result code (success, error, offline, mistyped: for internal error)
   *   if request was successful.
   */
  registerAccount = async (user) => {
    this.setUser(user);
    this.loading = true;
    try {
      let headers = {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': this.token
      }

      if (this.basicAuth) {
        // Authenticate with user with the only permission to register other users.
        headers['Authorization'] = this.basicAuth
      }

      const dataBody = {
        "_links": {
          "type": {
            "href": this.domain + this.basePath + "rest/type/user/user"
          }
        },
        "name": [{"value": user.name}],
        "mail": [{"value": user.email}],
        "pass": [{"value": user.password}],
        "status": [{"value": 1}]
      }

      if (typeof user.expoToken === 'string') {
        const tokenFrags = user.expoToken.match(/^ExponentPushToken\[([a-zA-Z0-9]*)\]$/);
        if (typeof tokenFrags === 'object' && tokenFrags.length >= 2) {
          dataBody["push_notification_device_token_expo"] = [{"value": tokenFrags[1]}]
        }
      }

      // console.log('CHECK TOKEN: ', dataBody);

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(dataBody)
      };

      let dataResponse = {};
      let responseStatus = null;
      await fetch(this.domain + this.basePath + 'entity/user?_format=hal_json', requestOptions)
        .then(response => {
          responseStatus = response.status;
          return response.json()
        })
        .then(data => {
          dataResponse = data
        }).catch((error) => {
          console.log('REGISTER ACCOUNT FAILED (I): ', error);
          return 'error'
        });

      // Give response to account interface.
      if (typeof responseStatus === 'number') {
        return (responseStatus < 400) ? 'success' : 'error';
      } else return 'error';

    } catch(error) {
      console.log('REGISTER ACCOUNT FAILED (II): ', error);
      return 'error'
    } finally {
      this.loading = false;
    }
  }

  updateAccount = async (user) => {
    this.setUser(user);
    this.loading = true;
    try {
      let headers = {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': this.token
      }

      if (this.basicAuth) {
        // Authenticate with user with the only permission to register other users.
        headers['Authorization'] = this.basicAuth
      }

      const dataBody = {
        "_links": {
          "type": {
            "href": this.domain + this.basePath + "rest/type/user/user"
          }
        },
        "name": [{"value": user.name}],
        "mail": [{"value": user.email}],
        "pass": [{"value": user.password}],
        "status": [{"value": 1}]
      }

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(dataBody)
      };

    } catch(error) {
      console.log('UPDATE ACCOUNT FAILED (II): ', error);
      return 'error'
    } finally {
      this.loading = false;
    }
  }


  updateAccountData = async (user, data) => {
    this.setUser(user);
    this.loading = true;
    try {
      let headers = {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': this.token
      }

      if (user.basicAuth) {
        // Authenticate with user with the only permission to register other users.
        headers['Authorization'] = 'Basic ' + user.basicAuth
      }

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      };

      let dataResponse = {};
      let responseStatus = null;
      await fetch(this.domain + this.basePath + 'colo-custom/set-user-details?_format=hal_json', requestOptions)
        .then(response => {
          responseStatus = response.status;
          return response.json()
        })
        .then(data => {
          dataResponse = data
        }).catch((error) => {
          console.log('REGISTER ACCOUNT FAILED (I): ', error);
          return 'error'
        });

      // Give response to account interface.
      if (typeof responseStatus === 'number') {
        return (responseStatus < 400) ? dataResponse : 'error';
      } else return 'offline';

    } catch(error) {
      console.log('UPDATE ACCOUNT FAILED (II): ', error);
      return 'error'
    } finally {
      this.loading = false;
    }
  }
  /**
   * Returns if App can connect with external server.
   *
   * @param user {Object}
   *   Expects an object with at least these properties: name, password.
   *
   * @returns {String|Object}
   *   Return result code (error|offline) or (when login was successful) an object
   *   with user data (properties are at least: name, email).
   */
  connectWithAccount = async (user) => {
    this.setUser(user);
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
          'Authorization': 'Basic ' + user.basicAuth
        },
      };
      let responseStatus = null;
      let responseData = null;
      await fetch(this.domain + this.basePath + 'colo-custom/receive-user-data?_format=hal_json', requestOptions)
        .then(response => {
          responseStatus = response.status;
          return response.json()
        })
        .then(data => {
          // Response data are not relevant just check success.
          responseData = data
        }).catch((error) => {
          return 'error'
        });

      // Give response to account interface.
      if (typeof responseStatus === 'number') {
        return (responseStatus < 400) ? responseData : 'error';
      } else return 'offline';

    } catch(e) {
      console.log('CONNECT ACCOUNT FAILED (III): ', e);
    }
  }

  /**
   * Send request to send a reset password email to user.
   *
   * @param email {string}
   *   Expects an email address.
   *
   * @returns {string}
   *   Returns result code (success, error, offline) if request was successful.
   */
  resetPassword = async (email) => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/hal+json',
          'X-CSRF-Token': this.token
        }
      }
      const encodedMail = encodeURIComponent(email);

      const requestPath = 'colo-custom/send-passw-recovery-mail/'+ encodedMail +'?_format=hal_json';
      let responseStatus = null;
      let responseData = null;
      await fetch(this.domain + this.basePath + requestPath, requestOptions)
        .then(response => {
          responseStatus = response.status;
          return response.json()
        })
        .then(data => {
          // Response data are not relevant just check success.
          responseData = data
        }).catch((error) => {
          return 'error'
        });

      // Give response to account interface.
      if (typeof responseStatus === 'number') {
        if (responseStatus < 400 && typeof responseData.result !== 'undefined') {
           return (responseData.result) ? 'success' : 'error';
        } else return 'error';
      } else return 'offline';

    } catch (e) {
      return 'offline';
    }
  }

  submitOrder = (cart = {}) => {
    this.loading = true;
    try {
      let headers = {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': this.token
      }

      if (this.user.basicAuth) {
        headers['Authorization'] = 'Basic ' + this.user.basicAuth
      }

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(cart)
      };

      let responseStatus = null;
      return fetch(this.domain + this.basePath + 'colo-custom/receive-order?_format=hal_json', requestOptions)
        .then(response => {
          responseStatus = response.status;
          return response.json()
        })
        .then(data => {
          // Give response to account interface.
          if (responseStatus >= 400)   {
            return 'error';
          } else return data;
        })
        .catch((error) => {
          console.log('SUBMIT ORDER FAILED (I): ', error);
          return 'error'
        });


    } catch(error) {
      console.log('SUBMIT ORDER FAILED (II): ', error);
      return 'error'
    } finally {
      this.loading = false;
    }
  }


  putCartMultiple  = async (addObjects= [], removeObjects = []) => {
    try {
      let headers = {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': this.token
      }

      if (this.user.basicAuth) {
        headers['Authorization'] = 'Basic ' + this.user.basicAuth
      }

      const dataBody = {
        "field_cart": {
          "add": addObjects,
          "remove": removeObjects
        }
      }

      // console.log('CHECK TOKEN: ', dataBody);

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(dataBody)
      };

      let responseStatus = null;
      await fetch(this.domain + this.basePath + 'colo-custom/set-cart?_format=hal_json', requestOptions)
        .then(response => {
          responseStatus = response.status;
          return response.json()
        })
        .then(data => data)
        .catch((error) => {
          console.log('SET CART FAILED (I): ', error);
          return 'error'
        });

      // Give response to account interface.
      if (typeof responseStatus === 'number') {
        return (responseStatus < 400) ? 'success' : 'error';
      } else return 'error';

    } catch(error) {
      console.log('SET CART FAILED (II): ', error);
      return 'error'
    } finally {
      this.loading = false;
    }
  }

  /**
   * Add/Remove multiple bookmark entities from backend user account.
   *
   * @param addIds
   *   Array of ids to add.
   * @param removeIds
   *   Array of ids to remove.
   *
   * @returns {Promise<string|string>}
   */
  setBookmarks = async (addIds= [], removeIds = []) => {
    try {
      let headers = {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': this.token
      }

      if (this.user.basicAuth) {
        headers['Authorization'] = 'Basic ' + this.user.basicAuth
      }

      const dataBody = {
        "field_bookmarks": {
          "add": addIds,
          "remove": removeIds
        }
      }

      // console.log('CHECK TOKEN: ', dataBody);

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(dataBody)
      };

      let responseStatus = null;
      await fetch(this.domain + this.basePath + 'colo-custom/set-bookmarks?_format=hal_json', requestOptions)
        .then(response => {
          responseStatus = response.status;
          return response.json()
        })
        .then(data => data)
        .catch((error) => {
          console.log('SET BOOKMARK FAILED (I): ', error);
          return 'error'
        });

      // Give response to account interface.
      if (typeof responseStatus === 'number') {
        return (responseStatus < 400) ? 'success' : 'error';
      } else return 'error';

    } catch(error) {
      console.log('SET BOOKMARK FAILED (II): ', error);
      return 'error'
    } finally {
      this.loading = false;
    }
  }
}

export default drupalJsonApi;
