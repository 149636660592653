import React, { Component } from 'react';
import { Box } from "native-base";
import HtmlRenderer from "../../Component/HtmlRenderer";
import htmlRendererStyles from "../../../theme/htmlRendererStyles";

class ParagraphText extends Component {

  render() {

    return (
      <Box p={2} mb={2}>
        <HtmlRenderer html={this.props.text} styles={htmlRendererStyles} />
      </Box>)
  }
}

export default ParagraphText;
