import React, { Component } from 'react';
import { Alert, Linking } from 'react-native';
import { VStack, Text, Box, Link, Button } from 'native-base';
import Screen from "../Component/Screen";
import t from "../../translation/translation";
import BottomSheet from "../Component/BottomSheet";

class ContactScreen extends Component {

  constructor(props) {
    super(props);
    this.discRef = React.createRef();
  }

  render() {
    const styles = {
      h3: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: 'primary.600'
      },
      body: {
        fontSize: '16px',
        color: '#2E3233'
      },
      pointer: {
        fontSize: '16px',
        color: 'primary.600'
      },
      link: {
        fontSize: '16px',
        color: 'primary.700'
      }
    }
    const footer = (
      <VStack space={2} mx={6}>
        <Button size={'lg'} variant="solid"
          onPress={() => {
            Linking.canOpenURL(t('mailto-address'))
              .then(supported => {
                if (!supported) {
                  Alert.alert('Can´t open mail client with: '
                    + t('mailto-address').replace('mailto:', ''));
                } else {
                  return Linking.openURL(t('mailto-address'));
                }
              })
          }}>{t('Write an e-mail')}</Button>
        <Button size={'lg'} variant="solid" onPress={() => {
          Linking.canOpenURL(t('tel-num'))
            .then(supported => {
              if (!supported) {
                Alert.alert('Phone service not available with: '
                  + t('tel-num').replace('tel:', ''));
              } else {
                return Linking.openURL(t('tel-num'));
              }
            })
        }}>{t('Call us by phone')}</Button>
      </VStack>);

    return (
      <Screen scrollable={true} footer={footer}>
        <VStack mx={6} flex={1} space={6} pt={8} justifyContent={"flex-start"}>

          <Box>
            <Text {...styles.h3}>{t('Company Address')}</Text>
            <Text {...styles.body}>{t('Address concrete')}</Text>
          </Box>


          <Box>
            <Text {...styles.body}>{t('Legal IDs concrete')}</Text>
          </Box>

          <Box>
            <Text {...styles.h3}>{t('Managing Director')}</Text>
            <Text {...styles.body}>{t('Managing Director concrete')}</Text>
          </Box>

          <Box>
            <Text {...styles.h3}>{t('PO box address')}</Text>
            <Text {...styles.body}>{t('PO box address concrete')}</Text>
          </Box>

          <Box>
            <Text {...styles.h3}>{t('Important Info')}</Text>

            <Text {...styles.body}><Text {...styles.pointer}>{'> '}</Text>{"Unsere"} <Link
              _text={styles.link} href="https://www.coloplast.de/global/datenschutz">{t('Privacy Policy')}</Link></Text>

            <Text {...styles.body}><Text {...styles.pointer}>{'> '}</Text>{"Unseren"} <Link
              _text={styles.link} onPress={() => this.discRef.current.showBottomSheet()} >{t('Disclaimer')}</Link></Text>
          </Box>

        </VStack>

        <BottomSheet ref={this.discRef} heading={t('Disclaimer')} height={1000}>
          <Box mx={6} my={3}>

            <Text fontSize={16} color={'#2E3233'}>{t("Disclaimerp1")}</Text>
            <Text fontSize={16} mt={5} color={'#2E3233'}>{t("Disclaimerp2")}</Text>
            <Text fontSize={16} mt={5} color={'#2E3233'}>{t("Disclaimerp3")}</Text>

          </Box>
        </BottomSheet>

      </Screen >
    )
  }
}

export default ContactScreen
