import React, {useState} from "react";
import {Box, Pressable} from "native-base";
import PropTypes from "prop-types";

export default function FilterItem(props) {
  const [status, setStatus] = useState(props.enabled);

  const pressed = function() {
    if (!status) {
      props.onEnable()
    } else {
      props.onDisable()
    }
    setStatus(!status);
  }

  return (
    <Pressable onPress={() => pressed()}>
      <Box borderRadius="full" m={2} px={4} py={1}
           bg={(status) ? 'primary.600' : '#EEE'}
           color={(status) ? '#FFF' : '#2E3233'}
      >{props.title}</Box>
    </Pressable>
  )
}

FilterItem.propTypes = {
  onEnable: PropTypes.func,
  onDisable: PropTypes.func,
  title: PropTypes.string,
};

FilterItem.defaultProps = {
  onEnable: () => {},
  onDisable: () => {},
  title: "no title given"
}
