import AsyncStorage from '@react-native-async-storage/async-storage';
import accountInterface from "../service/accountInterface";
import drupalJsonApi from "../service/accountApi/drupalJsonApi";
import {SAVE_USER, SAVE_USER_DATA, LOGOUT } from "./actions";

function getAccountUi() {
  // Prepare account UI.
  const accountAPI = new drupalJsonApi({
    domain: 'https://colo-backend.zukunftsmarke.com',
    basicAuth: 'Basic YXBwX3YxOktDNGhGcGtaaVB6TnZFbg=='
  });
  return new accountInterface(accountAPI);
}

const initialState = {
  user: {
    name: '',
    image: '',
    email: '',
    password: '',
    valid: false,
    offline: false,
    lastValidation: 0,
    data: {
      job: '',
      company: '',
      phone: '',
      street: '',
      nr: '',
      additional: '',
      zip: '',
      city: '',
      country: '',
    }
  },
  accountUI: getAccountUi()
}

const loginReducer = (state = initialState, action) => {
  let updatedUser, updatedUserData;

  switch (action.type) {
    case SAVE_USER:
      updatedUser = {...state.user, ...action.user};
      if (action.async) {
        AsyncStorage.setItem('userData', JSON.stringify(updatedUser));
      }
      return {
        ...state,
        user: updatedUser
      }

    case SAVE_USER_DATA:
      updatedUserData = {...state.user.data, ...action.data}
      updatedUser = {...state.user, data: updatedUserData};
      if (action.async) {
        AsyncStorage.setItem('userData', JSON.stringify(updatedUser));
      }
      return {
        ...state,
        user: updatedUser
      }

    case LOGOUT:
      AsyncStorage.removeItem('userData');
      return {
        ...initialState,
        accountUI: getAccountUi()
      }

    default:
  }

  return state;
}

export default loginReducer;
