import React from 'react';
import {Box, Heading, Text} from "native-base";

export default function(props) {
  const output = [];
  const size = (props.size) ? props.size : '2xl';
  const fontSize = (props.fontSize) ? props.fontSize : 0;
  if (typeof props.heading === 'string' && fontSize == 0) {
    output.push(<Heading key={'h1'} size={size} mb={1}>{props.heading}</Heading>)
  }
  if (typeof props.heading === 'string' && fontSize != 0 ) {
    output.push(<Heading key={'h1'} size={size} fontSize={fontSize} mb={1}>{props.heading}</Heading>)
  }
  if (typeof props.text === 'string') {
    output.push(<Text key={'txt'} fontSize={15.999}>{props.text}</Text>)
  }
  const boxStyle = (typeof props.boxStyle === 'object') ? props.boxStyle : {};
  return (<Box {...boxStyle}>{output}</Box>);
}
