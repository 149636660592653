// Put main constants and actions here.

export const APP_INIT = 'APP_INIT';
export const appInitialized = () => {
  return { type: APP_INIT, values: {} }
};

export const SPLASH_FIN = 'SPLASH_FIN';
export const splashFinished = () => {
  return { type: SPLASH_FIN, values: {} }
};
