import React from "react";
import {Center, Heading, HStack, Spinner} from "native-base";
import t from "../../translation/translation";

const ColoSpinner = (props) => {
  return (
    <Center minHeight={300}>
      <HStack space={2} alignItems="center">
        <Spinner accessibilityLabel="Loading" />
        <Heading color="primary.600" fontSize="md">{t('Loading')}</Heading>
      </HStack>
    </Center>)
}

export default ColoSpinner
