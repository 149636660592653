import { extendTheme } from 'native-base'

const theme = extendTheme({
  fontConfig: {
    Coloplast: {
      100: {
        normal: 'Coloplast',
        italic: 'Coloplast-Italic',
      },
      200: {
        normal: 'Coloplast',
        italic: 'Coloplast-Italic',
      },
      300: {
        normal: 'Coloplast',
        italic: 'Coloplast-Italic',
      },
      400: {
        normal: 'Coloplast',
        italic: 'Coloplast-Italic',
      },
      500: {
        normal: 'Coloplast-Medium',
        italic: 'Coloplast-MediumItalic',
      },
      600: {
        normal: 'Coloplast-Medium',
        italic: 'Coloplast-MediumItalic',
      },
      700: {
        normal: 'Coloplast-Bold',
        italic: 'Coloplast-BoldItalic',
      },
      800: {
        normal: 'Coloplast-Bold',
        italic: 'Coloplast-BoldItalic',
      },
      900: {
        normal: 'Coloplast-Bold',
        italic: 'Coloplast-BoldItalic',
      },
    },
    Roboto: {
      100: {
        normal: 'Roboto',
        italic: 'Roboto-Italic',
      },
      200: {
        normal: 'Roboto',
        italic: 'Roboto-Italic',
      },
      300: {
        normal: 'Roboto',
        italic: 'Roboto-Italic',
      },
      400: {
        normal: 'Roboto',
        italic: 'Roboto-Italic',
      },
      500: {
        normal: 'Roboto-Medium',
        italic: 'Roboto-MediumItalic',
      },
      600: {
        normal: 'Roboto-Medium',
        italic: 'Roboto-MediumItalic',
      },
      700: {
        normal: 'Roboto-Bold',
        italic: 'Roboto-BoldItalic',
      },
      800: {
        normal: 'Roboto-Bold',
        italic: 'Roboto-BoldItalic',
      },
      900: {
        normal: 'Roboto-Bold',
        italic: 'Roboto-BoldItalic',
      },
    },
  },

  fonts: {
    heading: 'Coloplast',
    body: 'Coloplast',
    mono: 'Coloplast',
  },

  colors: {
    primary: {
      50: '#f2fbfc',
      100: '#d6f3f7',
      200: '#b8e9f0',
      300: '#95dfe9',
      400: '#6cd3e0',
      500: '#3ac3d6',
      600: '#00b0ca',
      700: '#0097ab',
      800: '#007787',
      900: '#004650',
    },
    secondary: {
      50: '#000000',
      100: '#FFFFFF',
      200: '#FFFFFF',
      300: '#FFFFFF',
      400: '#FFFFFF',
      500: '#FFFFFF',
      600: '#FFFFFF',
      700: '#FFFFFF',
      800: '#FFFFFF',
      900: '#000000',
    },
  },
  config: {},
  components: {
    AccordionIcon: {
      name: 'home',
      baseStyle: {
        color: '#2e3233',
        _hover: {
          color: '#2e3233',
        },
        _expanded: {
          color: '#2e3233',
        }
      },

    },
    AccordionSummary: {
      baseStyle: {
        fontFamily: 'Coloplast',
        bg: 'transparent',
        _text: {
          color: '#F00',
          fontWeight: 'bold',
        },
        _hover: {
          bg: 'transparent',
        },
        _expanded: {
          bg: 'transparent',
          fontWeight: 'bold',
          _text: {
            color: '#F00',
            fontWeight: 'bold',
          }
        }
      },
    },
    Heading: {
      baseStyle: {
        color: '#2E3233',
      },
      sizes: {
        "2xl": {
          fontSize: 40,
        },
        "xl": {
          fontSize: 30,
        }
      }
    },
    Text: {
      baseStyle: {
        color: '#6C6F70',
      },
    },
    FormControlLabel: {
      baseStyle: {
        mt: 1,
        mb: 0,
        ml: 2,
        _text: {
          fontSize: 13.001
        }
      }
    },
    Input: {
      baseStyle: {
        bg: '#FFFFFF',
        borderRadius: 8.001,
        padding: '12px',
        _focus: {
          borderColor: 'primary.700'
        }
      },
      sizes: {
        lg: {
          px: 15.001,
          py: 15.001,
          color: '#6C6F70',
          fontSize: 16.001,
        }
      }
    },
    Button: {
      baseStyle: {
        borderRadius: 'full', // 0, 'xs', 'sm' ...
        _text: {
          position: 'relative',
          top: .5,
        }
      },
      variants: {
        outline: ({ colorScheme }) => {
          return {
            bg: 'white',
            borderColor: `${colorScheme}.600`,
            _text: {
              color: `${colorScheme}.700`,
            },
            _pressed: {
              bg: `${colorScheme}.50`,
            },
          };
        },
        solid: ({ colorScheme }) => {
          return {
            _text: {
              textTransform: 'uppercase',
              fontWeight: 'bold',
              letterSpacing: 0.8,
              fontSize: 16,
            },
            _disabled: {
              opacity: 1,
              _text: {
                color: '#FFF'
              }
            }
          };
        },
        subtl: ({ colorScheme }) => {
          return {
            bg: '#FCFAF9',
            _text: {
              textTransform: 'uppercase',
              fontWeight: 'bold',
              letterSpacing: 0.8,
              fontSize: 16,
            },
          };
        },
        ghost: ({ colorScheme }) => {
          return {
            bg: '#FFFFFF01',
            _text: {
              color: `${colorScheme}.700`,
              fontWeight: 600,
            },
            _pressed: {
              bg: '#FFFFFF01',
            },
          };
        },
      },
    }
  }
});

export default theme;
