import React, { Component } from 'react';
import { VStack, Center, Heading, Text, Box, Link } from 'native-base';
import Screen from "../Component/Screen";
import { connect } from "react-redux";
import t from "../../translation/translation";
import PageText from "../Component/PageText";
import CpIconButton from "../Component/CpIconButton";
import CpIconButtonHighlighted from "../Component/CpIconButtonHighlighted";
import { FontAwesome5, Foundation } from "@expo/vector-icons";
import BottomSheet from "../Component/BottomSheet";

class WelcomeScreen extends Component {

  constructor(props) {
    super(props);
    this.infoRef = React.createRef();
    this.discRef = React.createRef();
  }

  render() {
    return (
      <Screen>
        <VStack mx={6} space={6} pt={12} flex={1} justifyContent={"flex-start"}>

          <Box width='100%' mb={3}>
            <PageText heading={t("Welcome lets get started")} fontSize={{ 'base': 30, 'sm': 36 }} text="" />

          </Box>

          <Box width='100%' mb={3}>
            <CpIconButtonHighlighted icon={{ as: FontAwesome5, name: "briefcase-medical" }}
              label={t("Directly to the bandage selection")}
              onPressed={() => { this.props.navigation.navigate('Wizard', { screen: 'Infection' }) }} />
            <Text fontSize={16} mt={3}>{t("Select if wound cleansing has already been carried out")}</Text>
          </Box>

          <Box width='100%'>
            <CpIconButton icon={{ as: Foundation, name: "info" }}
              label={t("Disclaimer")}
              onPressed={() => {
                this.discRef.current.showBottomSheet()
              }} />
          </Box>

          <Box width='100%' mb={6}>
            <CpIconButton icon={{ as: Foundation, name: "info" }}
              label={t("For wound cleaning")}
              onPressed={() => {
                this.infoRef.current.showBottomSheet()
              }} />
            <Text fontSize={16} mt={3}>{t("Select if wound cleansing has not yet been carried out")}</Text>
          </Box>

        </VStack>

        <BottomSheet ref={this.discRef} heading={t('Disclaimer')} height={1000}>
          <Box mx={6} my={3}>

            <Text fontSize={16} color={'#2E3233'}>{t("Disclaimerp1")}</Text>
            <Text fontSize={16} mt={5} color={'#2E3233'}>{t("Disclaimerp2")}</Text>
            <Text fontSize={16} mt={5} color={'#2E3233'}>{t("Disclaimerp3")}</Text>

          </Box>
        </BottomSheet>

        <BottomSheet ref={this.infoRef} heading={t('Wound cleansing')} height={1000}>
          <Box mx={6} my={3}>
            <Heading fontSize={{ base: 25, sm: 34, md: 38 }} color={'#2E3233'} mb={2}>{t("Indispensable wound cleansing")}</Heading>
            <Text mb={4} fontSize={18} lineHeight={26.001} color={'primary.600'}>{t("Only a clean wound can be properly assessed and further treatment can be derived")}</Text>

            <Text fontSize={15} color={'#2E3233'} fontWeight={600}>{t("Effective wound cleaning")}</Text>
            <Text fontSize={15} color={'#2E3233'}>{t("The basis of local wound therapy is wound cleaning")}</Text>
            <Link href="https://www.coloplastprofessional.de/wundversorgung/tools/anleitungen/"
              isExternal
              _text={{
                mt: 2,
                color: "primary.700",
                fontSize: 16
              }}>{t("Video on optimal wound cleaning")}</Link>

            <Text fontSize={16} color={'#2E3233'} fontWeight={600} mt={8}>{t("Allrinse wound irrigation solution")}
              <Link href="https://www.coloplast.de/wundversorgung/allrinse/"
                isExternal
                _text={{
                  color: "primary.700",
                  fontSize: 16
                }}>{t("link to Allrinse wound irrigation solution")}</Link>
            </Text>
            <Text fontSize={16} color={'#2E3233'}>{t("Allrinse is an excellent helper for wound cleaning")}</Text>

          </Box>
        </BottomSheet>
      </Screen>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.login.user
  }
}

export default connect(mapStateToProps)(WelcomeScreen)
