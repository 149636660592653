import React, { Component } from "react";
import LoginSwitch from "../../Login/LoginSwitch";
import AccountUpdate from "../../Login/Screens/AccountUpdate";


class UserDataSwitch extends Component {

  render() {
    return (
      <LoginSwitch>
        <AccountUpdate navigation={this.props.navigation} />
      </LoginSwitch>);
  }
}

export default UserDataSwitch;
