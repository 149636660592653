import React, { Component } from 'react';
import { VStack } from "native-base";
import { connect } from 'react-redux';
import Screen from '../../Component/Screen';
import t from '../../../translation/translation'
import { resetDecisions, setDecision } from "../../../store/actions/decisionTree";
import StepId from "../../Component/StepId";
import PageText from "../../Component/PageText";
import AnswerSelect from "../../Component/AnswerSelect";
import PrevNext from "../../Component/PrevNext";

class TissueTypeScreen extends Component {



  render() {
    const continueWith = (result) => {
      this.props.setResult(result);
    }

    const recommendArr = ["woundInfected", "tissueType"]
      .map(item => {
        if (this.props.decisionTree[item]) {
          return this.props.decisionTree[item]
        } else return 'nn'
      });
    const recommendStr = recommendArr.join('_');

    const footer = (
      <PrevNext
        prev={() => {
          this.props.resetInfection()
          this.props.navigation.navigate('Infection')
        }
        }
        next={() => {
          if (recommendStr == "30_21" || recommendStr == "31_21") {
            this.props.navigation.navigate('Surrounding skin')
          } else if (recommendStr == "30_18") {
            this.props.navigation.navigate('Products overview')
          }
          else {
            this.props.navigation.navigate('Exudation')
          }
        }
        }
        nextDisabled={!this.props.tissueType}
      />);

    const options = [
      { '20': t('fibrinous') },
      { '21': t('necrotic') },
      { '19': t('granulating') },
      { '18': t('epitelizing') },
    ];

    return (
      <Screen scrollable={true} footer={footer}>
        <StepId stepCount={6} current={1} nav={this.props.navigation} />
        <VStack mx={6} flex={1} space={6} alignItems="center">

          <PageText
            fontSize={37}
            heading={t('Tissue')}
            text={t('What is the tissue status?')} />

          <AnswerSelect options={options}
            defaultValue={this.props.tissueType + ''}
            onAnswered={(answer) => { continueWith(answer); }} />

        </VStack>
      </Screen>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tissueType: state.decisionTree.tissueType,
    decisionTree: state.decisionTree,

  }
}

const mapDispatchToProps = dispatch => {
  return {
    setResult: (valObj) => {
      return dispatch(setDecision({ tissueType: valObj }))
    },

    resetInfection: () => {
      return dispatch(setDecision({ woundInfected: null }))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TissueTypeScreen)
