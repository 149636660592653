export const SET_PRODUCTS = 'SET_PRODUCTS';
export const setProducts = (data, syncLocal = true) => {
    return { type: SET_PRODUCTS, data: data, syncLocal: syncLocal }
};

export const SET_BOOKMARK = 'SET_BOOKMARK';
export const setBookmark = (id, syncBackend = true) => {
    id = (typeof id !== 'number') ? parseInt(id, 10) : id;
    return { type: SET_BOOKMARK, id: id, syncBackend: syncBackend }
};

export const UNSET_BOOKMARK = 'UNSET_BOOKMARK';
export const unsetBookmark = (id, syncBackend = true) => {
    id = (typeof id !== 'number') ? parseInt(id, 10) : id;
    if (typeof id === 'number') {
        return { type: UNSET_BOOKMARK, id: id, syncBackend: syncBackend }
    } else {
        return { type: 'DO_NOTHING' }
    }
};

export const UNSET_ALL_BOOKMARKS = 'UNSET_ALL_BOOKMARKS';
export const unsetAllBookmarks = (syncBackend = true) => {
    return { type: UNSET_ALL_BOOKMARKS, syncBackend: syncBackend }
};

export const PUT_CART = 'PUT_CART';
export const putCart = (id, variant = 0, syncBackend = true) => {
    id = parseInt(id,10);
    variant = parseInt(variant, 10);
    if (id) {
        return { type: PUT_CART, id: id, variant: variant, syncBackend: syncBackend }
    } else {
        return { type: 'DO_NOTHING' }
    }
};

export const RM_CART = 'RM_CART';
export const rmCart = (id, syncBackend = true) => {
    id = parseInt(id, 10).toString(10);
    return { type: RM_CART, id: id, syncBackend: syncBackend }
};

export const EMPTY_CART = 'EMPTY_CART';
export const emptyCart = () => {
    return { type: EMPTY_CART }
};

export const SUBMIT_ORDER = 'SUBMIT_ORDER';
export const submitOrder = (id, cart = []) => {
    return { type: SUBMIT_ORDER, cart: cart }
};
