import React, { PureComponent } from 'react';
import { Animated, Easing, View } from 'react-native';
import PropTypes from "prop-types";

class AnimateBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      init: false,
      opacity: (this.props.open) ? 1 : 0,
      height: 1
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.init && prevState.open !== this.props.open) {
      this.showContent();
    }
  }

  adjustHeight(layout) {
    const height = Math.round(layout.height);
    this.setState({
      init: true,
      opacity: new Animated.Value((this.props.open) ? 1 : 0),
      height: new Animated.Value((this.props.open) ? height : 0),
      interpol: {
        inputRange: [0, 1],
        outputRange: [0, height]
      }
    });
  }

  showContent = () => {
    const { opacity, height } = this.state;

    Animated.timing(height, {
      toValue: (this.props.open) ? 1 : 0,
      duration: 400,
      easing: Easing.out(Easing.ease),
      useNativeDriver: false
    }).start(() => {
      Animated.timing(opacity, {
        toValue: (this.props.open) ? 1 : 0,
        duration: 300,
        easing: Easing.linear,
        useNativeDriver: false
      }).start();
    });
  };

  render() {
    const { opacity, height, interpol } = this.state;
    let animHeight = height;
    if (this.state.init) {
      animHeight = height.interpolate(interpol);
    }
    return (
      <Animated.View style={{ position: 'relative', opacity: opacity, height: animHeight, overflow: 'hidden' }}>
        <View style={{position: 'absolute', top: 0, left: 0, right: 0}}
              onLayout={(event) => { this.adjustHeight(event.nativeEvent.layout)}} >
          {this.props.children}
        </View>
      </Animated.View>);
  }
}

AnimateBox.propTypes = {
  open: PropTypes.bool
};

AnimateBox.defaultProps = {
  open: false
}

export default AnimateBox;
