import React, { Component } from 'react';
import {Pressable, Text} from "native-base";

class VariantTag extends Component {

  render() {
    let bgColor = '#f4f4f4';
    let fontColor = '#2e3233';

    switch(this.props.status) {
      case 'disabled':
        bgColor = '#fcfcfc';
        fontColor = '#c0c1c1';
        break;
      case 'active':
        bgColor = 'primary.600';
        fontColor = '#fff';
        break;
      default:
    }

    return (<Pressable px={4} py={1} mx={0.5} my={1}
                       bgColor={bgColor} borderRadius={'full'}
                       style={{cursor: 'pointer'}}
                       onPress={() => {
                         if (this.props.status !== 'disabled') {
                           this.props.onPress()
                         }
                       }}>
      <Text fontSize={14.001} color={fontColor}>{this.props.children}</Text>
    </Pressable>);
  }
}

export default VariantTag;
