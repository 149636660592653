import React from 'react';
import { AntDesign, Ionicons, MaterialCommunityIcons, Feather, FontAwesome } from '@expo/vector-icons';
import { Icon, Box } from 'native-base';

const CustomHeaderButton = (props) => {
  const icomp = (typeof props.icomp !== 'undefined')
    ? props.icomp : 'Ionicons';

  let iconComponent;
  switch (icomp) {
    case 'AntDesign':
      iconComponent = AntDesign;
      break;
    case 'Feather':
      iconComponent = Feather;
      break;
    case 'FontAwesome':
      iconComponent = FontAwesome;
      break;
    case 'MaterialCommunityIcons':
      iconComponent = MaterialCommunityIcons;
      break;
    default:
      iconComponent = Ionicons;
  }

  const iconSize = (props.iconSize)? props.iconSize : 24.001;

  const count = (props.count) ?
    ( <Box bgColor="#f96600"
           rounded="full"
           position={'absolute'}
           minW={14.001}
           px={2.75}
           h={14.001}
           top={-4}
           right={-4}
           zIndex={1}
           _text={{
             fontSize: 10,
             lineHeight: 15.001,
             color: '#FFF',
             textAlign: 'center',
           }}>{props.count}</Box>) : [];

  return (<Box position={'relative'} mx={2}>
      {count}
      <Icon as={iconComponent}
            size={iconSize}
            color={'#FFF'}
            position={'relative'}
            {...props}/>
  </Box>)
};

export default CustomHeaderButton;
