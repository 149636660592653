import { createNavigationContainerRef, DrawerActions } from '@react-navigation/native';

// See: https://reactnavigation.org/docs/navigating-without-navigation-prop/

export const navigationRef = createNavigationContainerRef()

export function navigate(name, params) {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params);
  }
}

export function toggleDrawer() {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(DrawerActions.toggleDrawer());
  }
}
