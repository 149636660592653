import React, { Component } from 'react';
import { Text, Box, Center, Button, VStack, Heading, HStack } from "native-base";
import { connect } from 'react-redux';
import t from '../../../translation/translation'
import { setDecision, resetDecisions } from "../../../store/actions/decisionTree";
import HelpModal from "../../Component/HelpModal";
import StepId from "../../Component/StepId";
import Screen from "../../Component/Screen";
import AnswerSelect from "../../Component/AnswerSelect";
import CpIconButton from "../../Component/CpIconButton";
import PageText from "../../Component/PageText";
import { FontAwesome } from "@expo/vector-icons";
import PrevNext from "../../Component/PrevNext";

class SurroundingSkin extends Component {


  render() {

    const continueWith = (result) => {
      result = parseInt(result, 10);
      this.props.setResult(result);
    }

    const recommendArr = ["woundInfected", "tissueType", "exudation", "surroundingSkin"]
      .map(item => {
        if (this.props.decisionTree[item]) {
          return this.props.decisionTree[item]
        } else return 'nn'
      });
    const recommendStr = recommendArr.join('_');
    const recommendArrB = ["woundInfected", "tissueType", "exudation"]
      .map(item => {
        if (this.props.decisionTree[item]) {
          return this.props.decisionTree[item]
        } else return 'nn'
      });
    const recommendStrB = recommendArrB.join('_');

    const options = [
      { '34': t('Surrounding skin not intact') },
      { '35': t('Surrounding skin intact') }
    ];

    const footer = (
      <PrevNext
        prev={() => {
          if (recommendStrB == '30_21_nn' || recommendStrB == '31_21_nn') {
            this.props.resetTissue();
            this.props.navigation.navigate('Tissue')
          } else {
            this.props.resetExudation();
            this.props.navigation.navigate('Exudation')
          }

        }
        }
        next={() => {
          if (recommendStr == "30_21_nn_34" || recommendStr == "30_21_nn_35" || recommendStr == "31_21_nn_34" || recommendStr == "31_21_nn_35" || recommendStr == "31_18_26_34" || recommendStr == "31_18_26_35" || recommendStr == "31_18_27_34" || recommendStr == "31_18_27_35") {
            this.props.navigation.navigate('Products overview')

          } else {
            this.props.navigation.navigate('Wound depth')
          }
        }

        }
        nextDisabled={!this.props.surroundingSkin}
      />);

    /**
     * Surrounding skin
     */
    return (
      <Screen scrollable={true} footer={footer}>
        <StepId stepCount={6} current={3} nav={this.props.navigation} />
        <VStack mx={6} flex={1} space={6} alignItems="center" >

          <PageText
            fontSize={37}
            heading={t('Surrounding skin')}
            text={t('Select condition of the surrounding skin')}
          />

          <AnswerSelect options={options}
            defaultValue={this.props.surroundingSkin + ''}
            onAnswered={(answer) => { continueWith(answer); }}
          />

        </VStack>
      </Screen>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    surroundingSkin: state.decisionTree.surroundingSkin,
    decisionTree: state.decisionTree,

  }
}

const mapDispatchToProps = dispatch => {
  return {
    setResult: (valObj) => {
      return dispatch(setDecision({ surroundingSkin: valObj }))
    },
    resetTissue: () => {
      return dispatch(setDecision({ tissueType: null }))
    },
    resetExudation: () => {
      return dispatch(setDecision({ exudation: null }))
    }

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SurroundingSkin);
