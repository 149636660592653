import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";

import ProductsFavList from "./Screens/ProductsFavList";
import ProductScreen from "./Screens/ProductScreen";
import {prodScreenOpts} from "./Component/navopts";

function ProductsFavNav() {
  const StackNavi = createStackNavigator();

  const screenOptions = {
    headerShown: false,
    cardStyleInterpolator: ({ current }) => ({
      cardStyle: {
        opacity: current.progress
      },
    })
  }

  return (
    <StackNavi.Navigator screenOptions={screenOptions}>
      <StackNavi.Screen name="Products overview" component={ProductsFavList} />
      <StackNavi.Screen name="Product Details" component={ProductScreen} options={prodScreenOpts} />
    </StackNavi.Navigator>
  )
}

export default ProductsFavNav;
