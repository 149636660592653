import React, { Component } from 'react';
import { TouchableOpacity } from 'react-native';
import {HStack, Box, Text, Icon, Center} from "native-base";

class CpIconButton extends Component {

  render() {
    const size = 47.9999;
    const iconSize = (this.props.icon.name === 'info') ? 7 : 5;
    const iconProps = { color: '#2E3233', size: iconSize, textAlign: 'center', ...this.props.icon }
    const icon = (<Center w={size} h={size} borderRadius={32} backgroundColor={'#fff'}><Icon {...iconProps} /></Center>);
    const iconAlign = (typeof this.props.iconAlign !== 'undefined' && this.props.iconAlign === 'right') ?
      'right' : 'left';
    return (
      <TouchableOpacity onPress={() => { this.props.onPressed() }}>
        <HStack w={'100%'}  h={size} borderRadius={32} my={'1'} backgroundColor={'rgba(255, 255, 255, .5)'}>
          {(iconAlign === 'left') ? icon : []}
          <Box flex={1} justifyContent={'center'} px={(iconAlign === 'right') ? '6' : '3'} pt={'1'}>
            <Text fontSize={'md'} color='#2E3233'>{this.props.label}</Text>
          </Box>
          {(iconAlign === 'right') ? icon : []}
        </HStack>
      </TouchableOpacity>
    );
  }
}

export default CpIconButton;

