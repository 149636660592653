import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";

import ProductsOverview from "./Screens/ProductsOverview";
import ProductScreen from "./Screens/ProductScreen";
import t from "../../translation/translation";
import { prodScreenOpts } from "./Component/navopts";

function ProductsNavigator() {
  const StackNavi = createStackNavigator();

  const screenOptions = {
    headerShown: false,
    cardStyleInterpolator: ({ current }) => ({
      cardStyle: {
        opacity: current.progress
      },
    })
  }

  return (
    <StackNavi.Navigator screenOptions={screenOptions}>
      <StackNavi.Screen name="Products overview" options={{title: t("Products overview")}} component={ProductsOverview} />
      <StackNavi.Screen name="Product Details" options={prodScreenOpts} component={ProductScreen} />
    </StackNavi.Navigator>
  )
}

export default ProductsNavigator;
