import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { View, Button, Text } from 'react-native';
import { setExpoToken } from "./store/reducer";
import SyncStatus from "./Components/SyncStatus";
import Constants from 'expo-constants';

import * as Notifications from 'expo-notifications';

// Required to handle Notifications while the App IS in the foreground.
Notifications.setNotificationHandler({
  handleNotification: async () => {
    return {
      shouldShowAlert: true
    }
  }
})

export default function Notification() {
  const pushToken = useSelector(state => state.notifications.token);
  const isDebug = useSelector(state => state.main.isDebug);
  const dispatch = useDispatch();
  const isDevice = Constants.isDevice;
  const isExpo = Constants.appOwnership === 'expo';

  // iOS requires a permission to display notifications.
  useEffect(() => {
    if (isDevice) {
      Notifications.getPermissionsAsync()
        .then(statusObj => {
          if (statusObj.status !== 'granted') {
            return Notifications.requestPermissionsAsync();
          }
          return statusObj;
        })
        .then(statusObj => {
          if (statusObj.status !== 'granted') {
            throw new Error('Permission not granted.');
          }
        }).then(() => {
          return Notifications.getExpoPushTokenAsync();
        }).then((response) => {
          const token = response.data;
          dispatch(setExpoToken(token));
        }).catch((err) => {
          console.log(err)
        });
    }
  }, []);

  useEffect(() => {
    if (isDevice) {
      // Defines behavior when  notifications received WHILE THE APP IS IN BACKGROUND.
      const backgroundSubscription = Notifications.addNotificationReceivedListener(
        response => console.log(response)
      )

      // Defines behavior when  notifications received WHILE THE APP IS IN FOREGROUND.
      const foregroundSubscription = Notifications.addNotificationReceivedListener(
        notification => console.log(notification)
      )

      return () => {
        // Means: The notification will be removed onClick
        backgroundSubscription.remove();
        foregroundSubscription.remove();
      }
    }
  }, []);

  const triggerNotificationHandler = () => {
    if (isDevice && isExpo && isDebug) {
      Notifications.scheduleNotificationAsync({
        content: {
          title: 'my first notification',
          body: ' This is the first notification we are sending.'
        },
        trigger: {
          seconds: 5
        }
      })

      const body = JSON.stringify({
        to: pushToken,
        title: 'Test notification by app',
        body: 'This notification was sent by expo to test token and connection.'
      });

      fetch('https://exp.host/--/api/v2/push/send', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Accept-Encoding': 'gzip, deflate',
          'Content-Type': 'application/json',
        },
        body: body
      }).catch(err => {
        console.log(err,body)
      })
    }
  }

  const button = (isDevice && typeof pushToken !== undefined && pushToken)
    ? (<Button title={"Trigger Note"} onPress={() => triggerNotificationHandler()}/>) : [];

  return (isDevice && isExpo && isDebug)
    ? (<View>{button}<SyncStatus/></View>) : (<SyncStatus/>);

}
