import 'react-native-gesture-handler';
import * as React from 'react';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { NativeBaseProvider } from 'native-base';
import { Platform, View } from "react-native";
// import AppLoading from 'expo-app-loading';
import { useFonts } from 'expo-font';

// Redux
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import loginReducer from './App/Login/store/reducer';
import productsReducer from './App/Products/store/reducer';
import notificationReducer from './App/Notifications/store/reducer';

import mainReducer from './store/reducers/main';
import decisionTreeReducer from './store/reducers/decisionTree';

import DrawerNav from './App/Nav/DrawerNav';
import { navigationRef } from './App/Nav/RootNavigation';
import Notification from "./App/Notifications/Notification";

import theme from "./theme/theme"
import AnimScreen from "./App/Screens/AnimScreen";

const coloplastTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: '#00b0ca'
  },
};

const rootReducer = combineReducers({
  main: mainReducer,
  login: loginReducer,
  decisionTree: decisionTreeReducer,
  products: productsReducer,
  notifications: notificationReducer
})

const store = createStore(
  rootReducer,
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default function App() {
  let [fontsLoaded] = useFonts({
    'Coloplast': require('./assets/fonts/Coloplast.ttf'),
    'Coloplast-Italic': require('./assets/fonts/Coloplast-Italic.ttf'),
    'Coloplast-Medium': require('./assets/fonts/Coloplast-Medium.ttf'),
    'Coloplast-MediumItalic': require('./assets/fonts/Coloplast-MediumItalic.ttf'),
    'Coloplast-Bold': require('./assets/fonts/Coloplast-Bold.ttf'),
    'Coloplast-BoldItalic': require('./assets/fonts/Coloplast-BoldItalic.ttf'),
    'Roboto': require('./assets/fonts/Roboto-Regular.ttf'),
    'Roboto-Italic': require('./assets/fonts/Roboto-Italic.ttf'),
    'Roboto-Medium': require('./assets/fonts/Roboto-Medium.ttf'),
    'Roboto-MediumItalic': require('./assets/fonts/Roboto-MediumItalic.ttf'),
    'Roboto-Bold': require('./assets/fonts/Roboto-Bold.ttf'),
    'Roboto-BoldItalic': require('./assets/fonts/Roboto-BoldItalic.ttf')
  });

  const notification = (Platform.OS !== 'web') ? (<Notification/>) : [];
  const anim = (Platform.OS !== 'web') ? (<AnimScreen />) : [];

  if (!fontsLoaded) {
    return <View style={{flex: 1, backgroundColor: '#78BFD3'}} />;
  } else {
    return (
      <Provider store={store}>
        {anim}
        <NavigationContainer theme={coloplastTheme} ref={navigationRef}>
          <NativeBaseProvider theme={theme}>
            <DrawerNav />
            {notification}
          </NativeBaseProvider>
        </NavigationContainer>
      </Provider>
    );
  }
}
