
export const SAVE_USER = 'SAVE_USER';
export const saveUser = (user, async) => {
  async = (typeof async !== 'undefined') ? async : true;
  return { type: SAVE_USER, user: user, async: async }
};

export const SAVE_USER_DATA = 'SAVE_USER_DATA';
export const saveUserData = (data, async) => {
  async = (typeof async !== 'undefined') ? async : true;
  return { type: SAVE_USER_DATA, data: data, async: async }
};

export const LOGOUT = 'LOGOUT';
export const logout = () => {
  return { type: LOGOUT }
};

