import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";
import t from "../../translation/translation"

import AccountScreen from "./Screens/AccountScreen";
import AccountUpdate from "./Screens/AccountUpdate";
import DisclaimerScreen from "./Screens/DisclaimerScreen";
import ProcessUpdateScreen from "./Screens/ProcessUpdateScreen";

const StackNavi = createStackNavigator()
function AccountNavigator() {

  const screenOptions = {
    headerShown: false,
    cardStyleInterpolator: ({ current }) => ({
      cardStyle: {
        opacity: current.progress
      },
    })
  }

  return (
    <StackNavi.Navigator screenOptions={screenOptions}>
      <StackNavi.Screen options={{title: t("Your Account")}} name="Account" component={AccountScreen} />
      <StackNavi.Screen options={{title: t("Update Account")}} name="Update" component={AccountUpdate} />
      <StackNavi.Screen options={{title: t("Data Privacy")}} name="Disclaimer" component={DisclaimerScreen} />
      <StackNavi.Screen options={{title: t("Process Update")}} name="Process" component={ProcessUpdateScreen} />
    </StackNavi.Navigator>
  )
}

export default AccountNavigator;
