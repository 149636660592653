import { SET_DECISION, RESET_DECISIONS } from '../actions/decisionTree';


const initialState = {
    woundInfected: null,
    tissueType: null,
    exudation: null,
    woundDepth: null,
    surroundingSkin: null,
}

const defaultReducer = (state = initialState, action) => {

    // console.log('RDX DECISION TREE: ', action);

    switch (action.type) {
        case SET_DECISION:
            return { ...state, ...action.values }

        case RESET_DECISIONS:
            return { ...initialState }

        default:
    }

    return state;
}

export default defaultReducer;
