import React, {Component} from 'react';
import { KeyboardAvoidingView, Platform } from "react-native"
import { Heading, Text, VStack, FormControl, Input, HStack } from 'native-base';
import Screen from "../../Component/Screen";
import Alert from "../Components/Alert";
import t from "../../../translation/translation";
import { connect } from "react-redux";
import { validateLength } from "../service/validation";
import { saveUserData } from "../store/actions";
import PrevNext from "../../Component/PrevNext";

class RegisterScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      job: props.job,
      company: props.company,
      phone: props.phone,
      street: props.street,
      nr: props.nr,
      additional: props.additional,
      zip: props.zip,
      city: props.city,
      country: (props.country) ? props.country : t('Germany'),
      formError: {},
      alert: { showAlert: false }
    };
  }

  validateForm = () => {
    let errors = {}

    if (!validateLength(this.state.job, 2)) {
      errors.job = t('The %name field cannot be empty').replace('%name', t('Job Title'));
    }

    if (!validateLength(this.state.company, 2)) {
      errors.company = t('The %name field cannot be empty').replace('%name', t('Company'));
    }

    if (!validateLength(this.state.phone)) {
      errors.phone = t('The %name field cannot be empty').replace('%name', t('Phone Number'));
    }

    if (!validateLength(this.state.street)) {
      errors.street = t('The %name field cannot be empty').replace('%name', t('Street'));
    }

    if (!validateLength(this.state.nr)) {
      errors.nr = t('The %name field cannot be empty').replace('%name', t('Nr'));
    }

    if (!validateLength(this.state.zip)) {
      errors.zip = t('The %name field cannot be empty').replace('%name', t('ZIP'));
    }

    if (!validateLength(this.state.city)) {
      errors.city = t('The %name field cannot be empty').replace('%name', t('City'));
    }

    if (!validateLength(this.state.country)) {
      errors.country = t('The %name field cannot be empty').replace('%name', t('Country'));
    }

    this.setState({formError: {...errors}});

    return (Object.keys(errors).length === 0);
  }

  submitForm = () => {
    if (this.validateForm()) {

      const userData = {
        job: this.state.job,
        company: this.state.company,
        phone: this.state.phone,
        street: this.state.street,
        nr: this.state.nr,
        additional: this.state.additional,
        zip: this.state.zip,
        city: this.state.city,
        country: this.state.country,
      };
      // Write user data to local store.
      this.props.setUserData(userData);

      // Move forward to Disclaimer.
      this.props.navigation.navigate('Disclaimer');

    }
  }


  render() {

    const footer = (
      <PrevNext
        prev={() => this.props.navigation.goBack()}
        next={() => { this.submitForm() }}
        nextDisabled={false}
      />);

    return (
      <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{flex: 1}}>
        <Screen scrollable={true} footer={footer}>
          {/* Feedback from from submission. */}
          <Alert {...this.state.alert} />

          <VStack space={2} m={6} mb={8} justifyContent="center">
            <HStack justifyContent={'space-between'} alignItems={'center'}>
              <Heading size="xl">{t('Update your Address Data')}</Heading>
              <Text fontSize="12px">* {t('required information')}</Text>
            </HStack>

            <FormControl isDisabled={true}>
              <FormControl.Label>{t('Name')}</FormControl.Label>
              <Input size="lg" value={this.props.name} />
            </FormControl>

            <FormControl isDisabled={true}>
              <FormControl.Label>{t('Email')}</FormControl.Label>
              <Input size="lg" value={this.props.email} />
            </FormControl>

            <FormControl isRequired isInvalid={'company' in this.state.formError}>
              <FormControl.Label>{t('Company')}</FormControl.Label>
              <Input size="lg" value={this.state.company}
                     onChangeText={val => {this.setState({company: val});}} />
              <FormControl.ErrorMessage>{this.state.formError.company}</FormControl.ErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={'job' in this.state.formError}>
              <FormControl.Label>{t('Job Title')}</FormControl.Label>
              <Input size="lg" value={this.state.job}
                     onChangeText={val => {this.setState({job: val});}} />
              <FormControl.ErrorMessage>{this.state.formError.job}</FormControl.ErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={'phone' in this.state.formError}>
              <FormControl.Label>{t('Phone Number')}</FormControl.Label>
              <Input size="lg" value={this.state.phone}
                     onChangeText={val => {this.setState({phone: val});}} />
              <FormControl.ErrorMessage>{this.state.formError.phone}</FormControl.ErrorMessage>
              <FormControl.HelperText mt={4}>{t('For any queries we need your telephone number')}</FormControl.HelperText>
            </FormControl>

            <HStack>
              <FormControl flexBasis="75%" isRequired isInvalid={'street' in this.state.formError}>
                <FormControl.Label>{t('Street')}</FormControl.Label>
                <Input size="lg" value={this.state.street}
                       onChangeText={val => {this.setState({street: val});}} />
                <FormControl.ErrorMessage>{this.state.formError.street}</FormControl.ErrorMessage>
              </FormControl>

              <FormControl flexBasis="25%" pl={2} isRequired isInvalid={'nr' in this.state.formError}>
                <FormControl.Label>{t('Nr')}</FormControl.Label>
                <Input size="lg" value={this.state.nr}
                       onChangeText={val => {this.setState({nr: val});}} />
                <FormControl.ErrorMessage>{this.state.formError.nr}</FormControl.ErrorMessage>
              </FormControl>
            </HStack>

            <FormControl isInvalid={'additional' in this.state.formError}>
              <FormControl.Label>{t('Address Supplement')}</FormControl.Label>
              <Input size="lg" value={this.state.additional}
                     onChangeText={val => {this.setState({additional: val});}} />
              <FormControl.ErrorMessage>{this.state.formError.additional}</FormControl.ErrorMessage>
            </FormControl>

            <HStack>
              <FormControl flexBasis="25%" isRequired isInvalid={'zip' in this.state.formError}>
                <FormControl.Label>{t('ZIP')}</FormControl.Label>
                <Input size="lg" value={this.state.zip}
                       onChangeText={val => {this.setState({zip: val});}} />
                <FormControl.ErrorMessage>{this.state.formError.zip}</FormControl.ErrorMessage>
              </FormControl>

              <FormControl flexBasis="75%" pl={2} isRequired isInvalid={'city' in this.state.formError}>
                <FormControl.Label>{t('City')}</FormControl.Label>
                <Input size="lg" value={this.state.city}
                       onChangeText={val => {this.setState({city: val});}} />
                <FormControl.ErrorMessage>{this.state.formError.city}</FormControl.ErrorMessage>
              </FormControl>
            </HStack>

            <FormControl isDisabled={true} isInvalid={'country' in this.state.formError}>
              <FormControl.Label>{t('Country')}</FormControl.Label>
              <Input size="lg" value={this.state.country}
                     onChangeText={val => {this.setState({country: val});}} />
              <FormControl.ErrorMessage>{this.state.formError.country}</FormControl.ErrorMessage>
              <FormControl.HelperText mt={4}>{t('Product samples can only be sent to addresses in Germany')}</FormControl.HelperText>
            </FormControl>

            <FormControl mb={8}>
              <FormControl.HelperText>{t('This information is required to process your sample order')}</FormControl.HelperText>
            </FormControl>
          </VStack>
        </Screen>
      </KeyboardAvoidingView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    name: state.login.user.name,
    email: state.login.user.email,
    password: state.login.user.password,

    job: state.login.user.data.job,
    company: state.login.user.data.company,
    phone: state.login.user.data.phone,
    street: state.login.user.data.street,
    nr: state.login.user.data.nr,
    additional: state.login.user.data.additional,
    zip: state.login.user.data.zip,
    city: state.login.user.data.city,
    country: state.login.user.data.country,

    accountUI: state.login.accountUI,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUserData: (data) => {
      return dispatch(saveUserData(data))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterScreen);
