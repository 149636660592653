import React, {Component} from 'react';
import {IconButton} from 'native-base';
import {connect} from 'react-redux';
import {MaterialCommunityIcons, Feather} from "@expo/vector-icons";
import {setBookmark, unsetBookmark} from "../store/actions";

class Bookmark extends Component {

  render() {
    if (this.props.id !== 'undefined') {
      const isBookmarked = (this.props.bookmarks.indexOf(this.props.id) >= 0);
      return (
        <IconButton
          onPress={() => {
            if (isBookmarked)
              this.props.rmBookmark(this.props.id)
            else
              this.props.addBookmark(this.props.id)
          }}
          borderRadius="full"
          variant="transparent"
          colorScheme={isBookmarked ? "white" : "gray"}
          _icon={{
            as: MaterialCommunityIcons,
            name: isBookmarked ? "star" : "star-outline",
            size: 6,
          }}
          size={'sm'}
          ml={1}
        />)
    } else
      return []
  }
}

const mapStateToProps = (state) => {
  return {
    bookmarks: state.products.bookmarks
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    addBookmark: id => dispatch(setBookmark(id)),
    rmBookmark: id => dispatch(unsetBookmark(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookmark)
