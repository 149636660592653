import { ApolloClient, InMemoryCache } from '@apollo/client';

// import AsyncStorage from '@react-native-async-storage/async-storage';
// import { persistCache } from 'apollo3-cache-persist';

let SingletonApolloClientInstance = null;

export default class SingletonApolloClient {
  constructor() {
    if (!SingletonApolloClientInstance) {
      SingletonApolloClientInstance = this;
      const cache = new InMemoryCache();
      this.client = new ApolloClient({
        cache: cache,
        uri: 'https://colo-backend.zukunftsmarke.com/graphql',
      })
      this.time = new Date().getTime();
    }

    return SingletonApolloClientInstance;
  }

  getClient() {
    return this.client;
  }

}


