import React, { Component } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Box, VStack, Center, Icon, Text } from "native-base";
import { LinearGradient } from 'expo-linear-gradient';
import { Feather } from "@expo/vector-icons";


class Answer extends Component {

  render() {
    let gradient = ['#99DFEA', '#65D0DF'];
    let icon = [];
    if (typeof this.props.selected !== 'undefined' && this.props.selected) {
      icon = (<Icon as={Feather} name="check" size="6" m="auto" color="#2E3233" />)
      gradient = ['#56CFDC', '#2FB7D1']
    }
    const labelIcon = (this.props.icon) ? this.props.icon : [];
    return (
      <TouchableOpacity style={styles.container} onPress={() => { this.props.onPressed(this.props.value) }}>
        <Box h={158} shadow={3} borderRadius={8}>
          <LinearGradient colors={gradient} style={styles.background} />
          <Box style={styles.iconBox}>{icon}</Box>
          <VStack flex={1} px={2} mt={85.001}>
            <Text textAlign="center" color="#fff" noOfLines={2} lineHeight={28.001} fontSize={[16, 20.001]}>{this.props.label}</Text>
            <Center>{labelIcon}</Center>
          </VStack>
        </Box>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexBasis: '50%',
    maxWidth: 220,
    position: 'relative',
    padding: 4,
    marginTop: 12,
    borderRadius: 8,
  },
  background: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 8,
  },
  iconBox: {
    width: 47.9999,
    height: 47.9999,
    borderRadius: 32,
    backgroundColor: '#fff',
    position: "absolute",
    overflow: 'hidden',
    top: -12,
    left: "50%",
    transform: [{ translateX: -24 }],
  }
});


export default Answer;

