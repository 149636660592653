/**
 * Das Interface dient dazu, verschiedene account APIs anbinden zu können und das Feedback für die Login-Komponente
  zu vereinheitlichen. So kann sich die Login-Komponente potentiell mit verschiedenen Systemen (Drupal, WordPress)
  und verschiedenen technologien verbinden.
 *
 */
class accountInterface {

  accountApi = null;

  constructor(accountApi) {
    this.accountApi = accountApi;
  }

  /**
   * Send a register new user request.
   *
   * @param user {Object}
   *   Expects an object with at least these properties: name, email, password.
   *
   * @returns {string}
   *   Returns result code (success, error, offline, mistyped: for internal error)
   *   if request was successful.
   */
  registerAccount = async (user) => {
    const response = await this.accountApi.registerAccount(user);
    return ((typeof response === 'string') && (['success', 'error', 'offline'].indexOf(response) >= 0))
      ?  response : 'mistyped';
  }

  /**
   * Update existing user account.
   *
   * @param data {Object}
   *   Expects an object with at least these properties: name, email, password.
   *
   * @returns {string}
   *   Returns result code (success, error, offline, mistyped: for internal error)
   *   if request was successful.
   */
  updateAccount = async (data) => {
    const response = await this.accountApi.updateAccount(data);
    return ((typeof response === 'string') && (['success', 'error', 'offline'].indexOf(response) >= 0))
      ?  response : 'mistyped';
  }

  /**
   * Update existing user account.
   *
   * @param user {Object}
   *   Expects an object with at least these properties: name, email, password.
   *
   * @param data {Object}
   *   Expects an object with various properties: job, company, address, ...
   *
   * @param order {Object}
   *   Expects an object with various properties: job, company, address, ...
   *
   * @returns {string}
   *   Returns result code (success, error, offline, mistyped: for internal error)
   *   if request was successful.
   */
  updateAccountData = async (user, data, order = {}) => {
    const response = await this.accountApi.updateAccountData(user, data, order);
    return ((typeof response === 'string') && (['success', 'error', 'offline'].indexOf(response) >= 0))
      ?  response : 'mistyped';
  }

  /**
   * Returns if App can connect with external server.
   *
   * @param user {Object}
   *   Expects an object with at least these properties: name, password.
   *
   * @returns {String|Object}
   *   Return result code (error|offline) or (when login was successful) an object
   *   with user data (properties are at least: name, email).
   */
  connectWithAccount = async (user) => {
    const response = await this.accountApi.connectWithAccount(user);
    if (typeof response === 'object') {
      // On success API returns object with complete user data. At least this contains 'name' and 'email'.
      return (response.hasOwnProperty('name') && response.hasOwnProperty('email'))
        ? response : 'mistyped';
    } else {
      // return 'error' if response is false or 'offline' if request failed.
      return ((typeof response === 'string') && (['error', 'offline'].indexOf(response) >= 0))
        ?  response : 'mistyped';
    }
  }

  /**
   * Send request to send a reset password email to user.
   *
   * @param email {string}
   *   Expects an email address.
   *
   * @returns {string}
   *   Returns result code (success, error, offline, mistyped: for internal error)
   *   if request was successful.
   */
  resetPassword = async (email) => {
    const response = await this.accountApi.resetPassword(email);
    return ((typeof response === 'string') && (['success', 'error', 'offline'].indexOf(response) >= 0))
      ?  response : 'mistyped';
  }

  submitOrder = async (cart) => {
    return await this.accountApi.submitOrder(cart);
  };

  /**
   * Add a single bookmark entity to backend user account.
   *
   * @param ids {array}
   *   Ids to remove.
   *
   * @returns {Promise<void>}
   */
  addBookmarks = async (ids) => {
    this.accountApi.setBookmarks(ids)
      .then(response => response);
  }

  /**
   * Remove a single bookmark entity from backend user account.
   *
   * @param ids {array}
   *   Ids to remove.
   *
   * @returns {Promise<void>}
   */
  removeBookmarks = async (ids) => {
    this.accountApi.setBookmarks([], ids)
      .then(response => response);
  }

  /**
   * Add/Remove multiple bookmark entities from backend user account.
   *
   * @param addIds {Array<integer>}
   *   Array of ids to add.
   * @param removeIds {Array<integer>}
   *   Array of ids to remove.
   *
   * @returns {Promise<string|string>}
   */
  setBookmarks = async (addIds= [], removeIds = []) => {
    this.accountApi.setBookmarks(addIds, removeIds)
      .then(response => response);
  }

  /**
   * Add a single bookmark entity to backend user account.
   *
   * @param obj {object}
   *   Id to add to
   * @returns {Promise<void>}
   */
  putCart = async (obj) => {
    this.accountApi.putCartMultiple([obj])
      .then(response => response);
  }

  /**
   * Remove a single bookmark entity from backend user account.
   *
   * @param  id {number}
   *   Id to add to.
   *
   * @returns {Promise<void>}
   */
  rmCart = async (id) => {
    this.accountApi.putCartMultiple([], [id])
      .then(response => response);
  }

  /**
   * Remove all items from cart.
   *
   * @returns {Promise<void>}
   */
  emptyCart = async () => {
    this.accountApi.putCartMultiple([], [], 1)
      .then(response => response);
  }

  /**
   * Add/Remove multiple bookmark entities from backend user account.
   *
   * @param addIds {Array<object>}
   *   Array of ids to add.
   * @param removeIds {Array<number>}
   *   Array of ids to remove.
   * @param rmAll {number}
   *   Array of ids to remove.
   *
   * @returns {Promise<string|string>}
   */
  putCartMultiple = async (addIds= [], removeIds = [], rmAll = 0) => {
    this.accountApi.putCartMultiple(addIds, removeIds, rmAll)
      .then(response => response);
  }

}

export default accountInterface
