import React, { Component } from 'react';
import { HStack, Icon, VStack } from "native-base";
import { connect } from 'react-redux';
import t from '../../../translation/translation'
import { setDecision } from "../../../store/actions/decisionTree";
import Screen from '../../Component/Screen';
import StepId from "../../Component/StepId";
import PageText from "../../Component/PageText";
import AnswerSelect from "../../Component/AnswerSelect";
import PrevNext from "../../Component/PrevNext";
import { Fontisto } from "@expo/vector-icons"

class ExudationScreen extends Component {


  render() {

    const continueWith = (result) => {
      this.props.setResult(result);
    }

    const footer = (
      <PrevNext
        prev={() => {
          this.props.resetTissue();
          this.props.navigation.navigate('Tissue')
        }
        }
        next={() => this.props.navigation.navigate('Surrounding skin')}
        nextDisabled={!this.props.exudation}
      />);

    const options = [
      { '26': t('little exudation') },
      { '27': t('moderate exudation') },
      { '28': t('profusely exudation') }
    ];

    const drop = (<Icon as={Fontisto} name="blood-drop" color="#fff" textAlign='center' size={18.001} />);
    const icons = {
      '26': (<HStack>{drop}</HStack>),
      '27': (<HStack>{drop}{drop}</HStack>),
      '28': (<HStack>{drop}{drop}{drop}</HStack>)
    }

    return (
      <Screen scrollable={true} footer={footer}>
        <StepId stepCount={6} current={2} nav={this.props.navigation} />
        <VStack mx={6} flex={1} space={6} alignItems="center" >

          <PageText
            fontSize={37}
            heading={t('Exsudation')}
            text={t('How strong is the exudate discharge?')} />

          <AnswerSelect options={options}
            icons={icons}
            defaultValue={this.props.exudation + ''}
            onAnswered={(answer) => { continueWith(answer); }} />

        </VStack>
      </Screen>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    exudation: state.decisionTree.exudation
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setResult: (valObj) => {
      return dispatch(setDecision({ exudation: valObj }))
    },
    resetTissue: () => {
      return dispatch(setDecision({ tissueType: null }))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ExudationScreen)
