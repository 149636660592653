import {
  SET_PRODUCTS,
  EMPTY_CART,
  PUT_CART,
  RM_CART,
  SET_BOOKMARK,
  UNSET_ALL_BOOKMARKS,
  UNSET_BOOKMARK,
  SUBMIT_ORDER
} from "./actions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import t from "../../../translation/translation";

const initialState = {
  products: [],
  bookmarks: [],
  cart: {},
  lastUpdate: 0,
  categories: [
    { "key": "14", "val": t('Foam dressings') },
    { "key": "45", "val": t('Wound Contact Dressing') },
    { "key": "44", "val": t('Wound fillers') },
    { "key": "12", "val": t('Hydrocolloid dressings') },
    { "key": "17", "val": t('Super absorber') },
    { "key": "43", "val": t('Skin Protection') },
    { "key": "46", "val": t('Wound Irrigation Solutions') },

  ]
}

const productsReducer = (state = initialState, action) => {
  let index = null;
  let bookmarks = [...state.bookmarks];
  let cart = { ...state.cart };

  switch (action.type) {
    case SET_PRODUCTS:
      const productsData = { ...state, ...action.data };
      if (action.syncLocal) {
        AsyncStorage.setItem('productsData', JSON.stringify(productsData));
      }
      return productsData

    case SET_BOOKMARK:
      if (bookmarks.indexOf(action.id) < 0) {
        bookmarks.push(action.id);
        AsyncStorage.setItem('productsData', JSON.stringify({ ...state, bookmarks: bookmarks }));
        // if (action.syncBackend) state.accountUI.addBookmarks([action.id]);
      }
      return { ...state, bookmarks: bookmarks }

    case UNSET_BOOKMARK:
      index = (typeof action.id !== 'undefined') ? bookmarks.indexOf(action.id) : -1;
      if (index >= 0) {
        bookmarks.splice(index, 1);
        AsyncStorage.setItem('productsData', JSON.stringify({ ...state, bookmarks: bookmarks }));
        // if (action.syncBackend) state.accountUI.removeBookmarks([action.id]);
      }
      return {
        ...state,
        bookmarks: bookmarks
      }

    case UNSET_ALL_BOOKMARKS:
      const idsToRemove = [...state.bookmarks];
      AsyncStorage.setItem('productsData', JSON.stringify({
        ...state,
        bookmarks: []
      }));
      // if (action.syncBackend) state.accountUI.removeBookmarks(idsToRemove);
      return {
        ...state,
        bookmarks: []
      }

    case PUT_CART:
      const idStr = action.id.toString();
      cart[idStr] = {
        id: action.id,
        variant: action.variant,
      };
      AsyncStorage.setItem('productsData', JSON.stringify({ ...state, cart: cart }));
      return { ...state, cart: cart }

    case RM_CART:
      const rid = action.id.toString();
      if (typeof cart[rid] !== 'undefined') {
        delete cart[rid];
        AsyncStorage.setItem('productsData', JSON.stringify({ ...state, cart: cart }));
      }
      return { ...state, cart: cart }

    case EMPTY_CART:
      const nextState = { ...state, cart: {} };
      AsyncStorage.setItem('productsData', JSON.stringify(nextState));
      return nextState;

    default:

  }

  return state;
}

export default productsReducer;
