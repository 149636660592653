import React, { Component } from 'react';
import { Platform } from 'react-native';
import { Box, ScrollView } from "native-base";


class Screen extends Component {

  render() {
    const bgColor = (typeof this.props.bgColor !== 'undefined') ? this.props.bgColor : '#F3E9E0';
    let pb = 0;
    switch (Platform.OS) {
      case 'web':
        pb = 8;
        break;
      case 'android':
        pb = 3;
        break;
    }
    const scrollable = this.props.scrollable ? this.props.scrollable : true
    const shadow = scrollable ? {shadow: 8} : {};
    
    const footer = (typeof this.props.footer !== 'undefined') ? (
      <Box backgroundColor={bgColor}
           w={'100%'}
           pt={3}
           pb={pb}
           {...shadow}
           safeAreaBottom>
        <Box w={'100%'} maxW={700} mx={'auto'}>
          {this.props.footer}
        </Box>
      </Box>
      ) : [];

    const children = (scrollable) ?
      (<ScrollView flex={1} scrollIndicatorInsets={{ right: 1 }} _contentContainerStyle={{
          justifyContent: "center",
        }}>{this.props.children}</ScrollView>)
      : this.props.children;

    return (
      <Box flex={1} bg={bgColor} borderTopRadius={16} overflow="hidden">
        <Box flex={1} w={'100%'} maxW={700} mx={'auto'}>
          {children}
        </Box>
        {footer}
      </Box>
    );
  }
}

export default Screen;

