import React, { Component } from 'react';
import Screen from "../../Component/Screen";
import t from "../../../translation/translation";
import {Button, Box, Heading, HStack, Text, Switch, VStack, Link} from 'native-base';

import { connect } from 'react-redux';
import { saveUser } from "../store/actions";


class DisclaimerScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      consentAdv: false,
      consentSample: false,
    }
  }

  submitForm = async () => {
  }

  render() {
    const footer = (
      <HStack space={4} mx={6}>
        <Button flexBasis={'50%'} flexGrow={1} flexShrink={1} size={'lg'} variant="subtl" colorScheme="secondary"
                onPress={() => this.props.navigation.navigate('Update')}>{t('previous')}</Button>
        <Button flexBasis={'50%'} flexGrow={1} flexShrink={1} size={'lg'} variant="solid"
                isDisabled={!this.state.consentSample || !this.state.consentAdv}
                onPress={() => this.props.navigation.navigate('Process')}>{t('Submit')}</Button>
      </HStack>)

    return (
      <Screen footer={footer}>
        <VStack flex={1} mx={6} mt={5} space={4}>
          <HStack justifyContent={'space-between'} alignItems={'center'}>
            <Heading size="lg">{t('Data Privacy')}</Heading>
            <Text fontSize="12px">* {t('required information')}</Text>
          </HStack>

          <Box>
            <Text fontSize="16px" fontWeight="bold">{t('Consent under data protection law')}</Text>
            <Text fontSize="16px">{t('The dispatch of the product sample order is free of charge for you')}</Text>
          </Box>

          <HStack justifyContent={'space-between'} alignItems={'flex-start'}>
            <Box flexBasis="70%">
              <Text fontSize="16px" fontWeight="bold">{t('Declaration of consent under data protection law for the dispatch of product samples')}*</Text>
            </Box>
            <Switch onTrackColor="primary.600" isChecked={this.state.consentSample} onToggle={
              () => this.setState({consentSample: !this.state.consentSample})
            } />
          </HStack>

          <HStack justifyContent={'space-between'} alignItems={'flex-start'}>
            <Box flexBasis="70%">
              <Text fontSize="16px" fontWeight="bold">{t('Consent under data protection law to contact you for advertising')}*</Text>
            </Box>
            <Switch onTrackColor="primary.600" isChecked={this.state.consentAdv} onToggle={
              () => this.setState({consentAdv: !this.state.consentAdv})
            } />
          </HStack>

          <Box>
            <Text fontSize="16px">{t('Further information about the processing and protection of your data at')}</Text>
            <Link _text={{color: 'primary.600', fontSize: '16px'}} href={'https://www.coloplast.de/datenschutz'}>www.coloplast.de/datenschutz</Link>
          </Box>

        </VStack>
      </Screen>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    accountUI: state.login.accountUI
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUserData: (user, async) => {
      async = (typeof async !== 'undefined') ? async : true;
      return dispatch(saveUser(user, async))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisclaimerScreen);
