import React, { Component } from 'react';
import { connect } from 'react-redux';
import {AlertDialog, VStack, Heading, Button, HStack, Text} from "native-base";
import Screen from "../../Component/Screen";
import t from "../../../translation/translation";
import ProductList from "../Component/ProductList";
import { emptyCart } from "../store/actions";
import {Alert, Linking} from "react-native";

class ProductsCart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false
    }
    this.alertRef = React.createRef();
  }

  toggleDialog() {
    this.setState({
      dialogOpen: !this.state.dialogOpen
    })
  }

  render() {
    const ids = Object.keys(this.props.cart).map(n => parseInt(n, 10));
    const emptyMessage = (<Text key={'empty'} fontSize={16} ml={6}>{t('There is no product in your cart yet')}</Text>)
    const cartView =  (<ProductList emptyMessage={emptyMessage}
                                    debug={false}
                                    mode="cart"
                                    ids={ids}
                                    navigation={this.props.navigation} />)



    // Create remove Button if list is not empty.
    const removeBtn = (ids.length) ? (
      <Button variant="ghost" onPress={() => {this.toggleDialog()}}
      ><Text color={'primary.700'} fontWeight={'bold'} fontSize={16}>{t('Remove all')}</Text></Button>) : [];

    const footer = (
      <VStack space={4} mx={6}>
        <Button size={'lg'} variant="solid" isDisabled={(ids.length === 0)} onPress={() => {
          this.props.navigation.navigate('Update');
        }}>{t('Go to checkout')}</Button>
      </VStack>);

    return (
      <Screen footer={footer} scrollable={true}>
        <VStack mx={6} my={6}>
          <HStack justifyContent="space-between">
            <Heading size="xl" my={2}>{ids.length.toString(10) + ' ' + t('Products')}</Heading>
            {removeBtn}
          </HStack>
          {cartView}

          <HStack justifyContent="flex-end" mt={3}>
            <Button variant="ghost" onPress={() => {this.props.navigation.getParent().navigate('ProductsOv')}}
              ><Text color={'primary.700'} fontWeight={'bold'} fontSize={16}>{t('Add more')}</Text></Button>
          </HStack>
        </VStack>

        <AlertDialog onClose={() => {this.toggleDialog()}} isOpen={this.state.dialogOpen} leastDestructiveRef={this.alertRef}>
          <AlertDialog.Content>
            <AlertDialog.CloseButton />
            <AlertDialog.Header>{t('Empty cart')}</AlertDialog.Header>
            <AlertDialog.Body>{t('Are you sure you want to delete all items in the cart')}</AlertDialog.Body>
            <AlertDialog.Footer>
              <Button.Group space={2}>
                <Button variant="unstyled" colorScheme="coolGray"
                        onPress={() => {this.toggleDialog()}}>{t('Cancel')}</Button>
                <Button colorScheme="danger" onPress={() => {
                  this.props.emptyCart();
                  this.toggleDialog()
                }}>{t('Delete')}</Button>
              </Button.Group>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>

      </Screen>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products.products,
    cart: state.products.cart
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    emptyCart: () => {dispatch(emptyCart())}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsCart);
