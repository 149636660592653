import React, { Component } from 'react';
import { Platform, Linking, View } from "react-native";
import { connect } from 'react-redux'
import { Link, Box, Heading, Center, VStack, HStack, Button, CheckIcon } from "native-base";
import Paragraphs from "../../Paragraphs/Paragraphs";
import MediaImage from "../../Component/MediaImage"
import Bookmark from "../Component/Bookmark";
import { HeaderButtons, Item } from "react-navigation-header-buttons";
import HeaderButton from "../../Component/HeaderButton";
import t from "../../../translation/translation";
import Screen from "../../Component/Screen";
import VariantTag from "../../Component/VariantTag";
import { putCart, setBookmark } from "../store/actions";
import { HeaderBtnsLeft } from "../Component/HeaderBtns";
import { Shadow } from 'react-native-shadow-2';
import HtmlRenderer from "../../Component/HtmlRenderer";
import htmlRendererStyles from "../../../theme/htmlRendererStyles";


class ProductScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      willAddToCart: !!props.route.params.willAddToCart,
      addedToCart: false,
      isBookmarked: false,
      selectedVariant: 0,
    }
  }

  componentDidMount() {
    this.props.navigation.getParent().setOptions({
      headerLeft: () => (<HeaderButtons HeaderButtonComponent={HeaderButton}>
        <Item title={t('Reset Selection')}
          name="arrowleft"
          icomp="AntDesign"
          onPress={() => {
            this.props.navigation.navigate('Products overview');
            // Reset Header left to default when closing.
            this.props.navigation.getParent().setOptions({
              headerLeft: () => (<HeaderBtnsLeft />)
            });
          }} />
      </HeaderButtons>)
    });
  }


  render() {
    const product = this.props.route.params.product;
    const hasVariants = !!(product.variants && Array.isArray(product.variants) && product.variants.length);
    const content = (product.content) ? [...product.content] : [];

    let teaserImg = [];
    if (content.length && content[0]['__typename'] === 'ParagraphImage') {
      const imgProps = { ...content.shift().image };
      const tistyle = {
        position: "absolute",
        zIndex: 8,
        top: '50%',
        left: '50%',
      }
      // console.log(imgProps)
      teaserImg = (
        <Center bgColor={"#D8D8D8"} p={5} position="relative" width="100%" overflow="hidden">
          <Shadow startColor="#ffffffff" finalColor="#ffffff00" paintInside="#ffffff"
            distance={150} getChildRadius={50} getViewStyleRadius={50}
            size={[50, 50]} radius={50} offset={[0, 0]} containerViewStyle={tistyle}>
            <Box />
          </Shadow>
          <Box h="33%" position="absolute" bottom={0} left={0} right={0} bg="#fff" zIndex={9} />
          <Box w="55%" position="relative" zIndex={10}>
            <MediaImage {...imgProps} />
          </Box>
        </Center>
      )
    }

    // Build Footer
    const btnBookmarkContent = (!this.state.isBookmarked) ?
      t('Bookmark') : (<CheckIcon size="5" m="auto" color="#2E3233" />)
    let bookmark = (!this.state.willAddToCart) ? (
      <Button size={'lg'}
        variant="subtl"
        flexBasis={'35%'}
        flexGrow={1}
        onPress={() => {
          this.props.addBookmark(product.id);
          this.setState({ isBookmarked: true })
        }}>{btnBookmarkContent}</Button>) : [];

    const btnAddToCartContent = (!this.state.addedToCart) ?
      t('Add to cart') : (<CheckIcon size="5" m="auto" color="#FFF" />)

    let footer = (<HStack space={4} mx={6} justifyContent={'center'}>
      {bookmark}
      { /* "ADD TO CART" BUTTON */}
      <Button size={{ 'base': 'md', 'sm': 'lg' }} variant="solid"
        flexBasis={(!this.state.willAddToCart) ? '65%' : '100%'}
        flexGrow={1}

        //isDisabled={this.state.willAddToCart && hasVariants && this.state.selectedVariant === 0}
        onPress={() => {
          const url = 'https://produkte.coloplast.de/produkte/wundversorgung?utm_source=verbandcheck&utm_medium=app&utm_campaign=musterbestellung'
          if (Platform.OS == 'web') {
            window.open(url, '_blank');
          } else {
            Linking.openURL(url).catch((err) => console.error('An error occurred', err));
          }

          //   if (hasVariants && !this.state.willAddToCart) {
          //     this.setState({ willAddToCart: true })
          //   } else {
          //     const vid = (typeof this.state.selectedVariant === 'object') ? this.state.selectedVariant.id : 0;
          //     this.props.putCart(product.id, vid);
          //     this.setState({
          //       willAddToCart: false,
          //       addedToCart: true
          //     });
          //     if (!!this.props.route.params.goBackAfterAddToCart) {
          //       this.props.navigation.goBack();
          //     }
        }}
        /*}}*/>
        {btnAddToCartContent}</Button>
    </HStack>);

    // Build footer variant tag cloud.
    if (this.state.willAddToCart && hasVariants) {

      // Variant Tag Cloud
      const variants = product.variants.map((variant) => (
        <VariantTag key={variant.id} status={(this.state.selectedVariant === variant) ? 'active' : 'default'}
          onPress={() => {
            this.setState({ selectedVariant: variant })
          }}
        >{variant.name}</VariantTag>));

      // Extended Footer
      footer = (
        <VStack>
          <HStack flexWrap={'wrap'} justifyContent={'center'} space={1} mx={3} mb={4}>{variants}</HStack>
          <Box width={'100%'}>{footer}</Box>
        </VStack>
      )
    }
    const subtitle = (product.subtitle) ?
      (<Heading fontSize={20.001} mt={-15.001} mb={28.001} fontFamily={'Coloplast-MediumItalic'} color={'#6C6F70'}>{product.subtitle}</Heading>) : [];

    return (
      <Screen footer={footer} scrollable={true} bgColor={'#fff'}>
        <Box width={'100%'} maxWidth={768} mx={'auto'}>
          {teaserImg}
          <VStack space={2} p={2}>
            <Box rounded="lg" p={2} backgroundColor={'white'}>
              <HStack space={7} mb={5} alignItems={"flex-end"} justifyContent={'space-between'}>
                <Heading size="xl">{product.name}</Heading>
              </HStack>
              {subtitle}
              <HtmlRenderer main={true} html={'<p>' + product.teaser.text + '</p>'} styles={htmlRendererStyles} />
            </Box>
            <VStack space={2}>
              <Paragraphs content={content} />
            </VStack>
          </VStack>
        </Box>
      </Screen>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    bookmarks: state.products.bookmarks,
    cart: state.products.cart,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addBookmark: (id) => dispatch(setBookmark(id)),
    putCart: (id, variant) => dispatch(putCart(id, variant)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductScreen);
