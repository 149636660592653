import React, { Component } from 'react';
import { Center, VStack, Icon, HStack, Box, Text } from "native-base";
import { Foundation } from "@expo/vector-icons"
import { connect } from 'react-redux';
import t from '../../../translation/translation'
import Screen from '../../Component/Screen';

import { setDecision } from "../../../store/actions/decisionTree";
import StepId from "../../Component/StepId";
import PageText from "../../Component/PageText";
import AnswerSelect from "../../Component/AnswerSelect";
import PrevNext from "../../Component/PrevNext";
import CpInfoBox from "../../Component/CpInfoBox";

class WoundDepthScreen extends Component {




  render() {

    const continueWith = (result) => {
      this.props.setResult(result);
    }

    const footer = (
      <PrevNext
        prev={() => {
          this.props.resetSurround()
          this.props.navigation.navigate('Surrounding skin')
        }
        }
        next={() => this.props.navigation.navigate('Products overview')}
        nextDisabled={!this.props.woundDepth} />);

    const options = [
      { '24': t('lt2cm') },
      { '25': t('gt2cm') }
    ];


    return (
      <Screen scrollable={true} footer={footer}>
        <StepId stepCount={6} current={4} nav={this.props.navigation} />
        <VStack mx={6} flex={1} space={6} alignItems="center" >

          <PageText
            fontSize={37}
            heading={t('Wound depth')}
            text={t('How deep is the Wound?')} />

          <AnswerSelect options={options}
            defaultValue={this.props.woundDepth + ''}
            onAnswered={(answer) => { continueWith(answer); }} />

          <Box mt="10%" width="100%">
            <CpInfoBox label={t('If undermined please use a suitable wound filler')} />
          </Box>

        </VStack>
      </Screen>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    woundDepth: state.decisionTree.woundDepth,
    tissueType: state.decisionTree.tissueType
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setResult: (valObj) => {
      return dispatch(setDecision({ woundDepth: valObj }))
    },
    resetSurround: () => {
      return dispatch(setDecision({ surroundingSkin: null }))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(WoundDepthScreen)
