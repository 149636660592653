import React, {Component} from 'react';
import LoginSwitch from "./LoginSwitch";
import AccountNavigator from "./AccountNavigator";

class NavigatorSwitch extends Component {

  render() {

    return (
      <LoginSwitch>
        <AccountNavigator />
      </LoginSwitch>);
  }
}

export default NavigatorSwitch;
