import {APP_INIT, SPLASH_FIN} from "../actions/main";
import {Platform} from "react-native";

const initialState = {
    api: {
        domain: 'https://colo-backend.zukunftsmarke.com',
        basePath: '/'
    },
    isAppInitialized: false,
    splashFinished: (Platform.OS === 'web'),
    isDebug: false
}

const mainReducer = (state = initialState, action) => {
    // console.log('REDUX MAIN: ', action);
    switch (action.type) {
        case APP_INIT:
            return {
                ...state,
                isAppInitialized: true,
            }
        case SPLASH_FIN:
            return {
                ...state,
                splashFinished: true,
            }
        default:

    }

    return state;
}

export default mainReducer;
