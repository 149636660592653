import React, { Component } from "react";
import { Text, HStack, VStack } from "native-base";


class Ulist extends Component {

  getItem(item, index) {
    let textStyle = {}
    if (typeof this.props.textStyle !== 'undefined') {
      textStyle = this.props.textStyle;
    }
    let iconStyle = { ...textStyle, color: 'primary.600' }
    return (<HStack space={1} key={'item_' + index}>
      <Text flex={0} {...iconStyle}>{'›'}  </Text>
      <Text {...textStyle} flex={1}>{item}</Text>
    </HStack>)
  }

  render() {
    const items = this.props.data.map((item, index) => this.getItem(item, index));
    let style = {
      my: 15,
      ...this.props.style,
    }
    return (
      <VStack {...style}>
        {items}
      </VStack>)
  }
}

export default Ulist;
