import React, { Component } from "react";
import { ActivityIndicator, View } from "react-native";
import { Image, VStack } from "native-base";


class ImageExt extends Component {

  constructor(props) {
    super(props);
    this.state = {
      width: 320,
      height: 180,
      ratio: 1.7777777,
    }
  }

  onLayout(layout) {
    this.setState({
      height: Math.round(layout.width / this.state.ratio)
    })
  }

  componentDidMount() {
    if (this.props.width && this.props.height) {
      this.setState({
        width: this.props.width,
        height: this.props.height,
        ratio: this.props.width / this.props.height,
      })
    }
  }

  render() {
    let style = { width: '100%', height: parseInt(this.state.height, 10) };
    if (typeof this.props.style === 'object') {
      style = { ...this.props.style, ...style }
    }
    const alt = (this.props.alt) ? this.props.alt : '';
    return (
      <VStack>
        <View onLayout={(e) => { this.onLayout(e.nativeEvent.layout) }} {...style}>
          <Image source={{ uri: this.props.src }}
            key={this.props.src}
            PlaceholderContent={<ActivityIndicator />}
            width="100%"
            height={parseInt(this.state.height, 10)}
            alt={alt}
          />
        </View>
      </VStack>
    );
  }
}
export default ImageExt;
