import React, {Component} from 'react';
import {AlertDialog, Button} from "native-base";
import t from "../../../translation/translation";

class Alert extends Component {

  constructor(props) {
    super(props);
    this.alertRef = React.createRef();
  }

  render() {
    return (
      <AlertDialog isOpen={this.props.open}
                   onClose={this.props.onClosed}
                   motionPreset={"fade"}
                   leastDestructiveRef={this.alertRef}>
        <AlertDialog.Content>
          <AlertDialog.Header>{this.props.header}</AlertDialog.Header>
          <AlertDialog.Body>{this.props.body}</AlertDialog.Body>
          <AlertDialog.CloseButton/>
          <AlertDialog.Footer>
            <Button size={'sm'} onPress={this.props.onClosed}>{t('Close')}</Button>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>)
  }
}

export default Alert;
