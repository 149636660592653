import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HeaderButtons, Item } from "react-navigation-header-buttons";
import HeaderButton from "../../Component/HeaderButton";
import t from "../../../translation/translation";
import * as RootNavigation from '../../Nav/RootNavigation.js';

class HeaderBtns extends Component {

  render() {

    const cartCount = Object.keys(this.props.cart).length;
    const cartIcon = (cartCount) ?
      (<Item title={t('View cart')}
        name="cart-outline"
        count={cartCount}
        icomp="Ionicons"
        iconSize={24.001}
        onPress={() => {
          RootNavigation.navigate('SampleOrder', { screen: 'Products overview' });
        }} />) : [];

    const bookmarkCount = this.props.bookmarks.length;
    const bookmarkIcon = (bookmarkCount) ?
      (<Item title={t('View bookmarks')}
        name="star"
        iconSize={23.001}
        count={0}
        icomp="Ionicons"
        onPress={() => {
          RootNavigation.navigate('Bookmarked');
        }} />) : [];

    return (<HeaderButtons HeaderButtonComponent={HeaderButton}>
      {bookmarkIcon}
      {cartIcon}
    </HeaderButtons>)
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.products.cart,
    bookmarks: state.products.bookmarks
  }
}
const HeaderBtnsRight = connect(mapStateToProps)(HeaderBtns);



// Drawer toggle.
function HeaderBtnsLeft() {
  const drawerIcon = (<Item title={t('Menu')}
    name="menu"
    icomp="Feather"
    iconSize={28.001}
    onPress={() => {
      RootNavigation.toggleDrawer();
    }} />);

  return (<HeaderButtons HeaderButtonComponent={HeaderButton}>
    {drawerIcon}
  </HeaderButtons>)
}

export { HeaderBtnsRight, HeaderBtnsLeft };
