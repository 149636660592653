import React from "react";
import { Button, HStack } from "native-base";
import t from "../../translation/translation";


export default function (props) {

  const prevDisabled = (typeof props.prevDisabled !== 'undefined' && props.prevDisabled);
  const nextDisabled = (typeof props.nextDisabled !== 'undefined' && props.nextDisabled);
  const size = !props.size ? "lg" : props.size;
  const btnNext = (!props.nextHidden) ?
    (<Button flexBasis={'50%'} flexGrow={1} flexShrink={1} size={size} variant="solid"
      isDisabled={nextDisabled}
      onPress={props.next}>{!props.nextText ? t('next') : props.nextText}</Button>) : []
  return (
    <HStack space={4} mx={6}>
      <Button flexBasis={'50%'} flexGrow={0} flexShrink={0} size={'lg'} variant="subtl" colorScheme="secondary"
        isDisabled={prevDisabled}
        onPress={props.prev}>{t('previous')}</Button>
      {btnNext}
    </HStack>);
}
