// Styles for rendered HTML by component HtmlRenderer.

const defaults = {
  marginBottom: 0,
  marginTop: 0
}

const col = {
  px: 1,
  flexGrow: 0,
  flexShrink: 0
}

export default {
  H2: {
    ...defaults,
    _text: {
      color: '#2E3233',
      fontSize: '24px',
      lineHeight: '36px',
    },
  },
  H3: {
    mb: 0, mt: 19.999,
    _text: {
      textTransform: 'uppercase',
      color: '#2E3233',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '21px'
    }
  },
  H4: {
    mb: 0, mt: 19.999,
    _text: {
      color: '#00b0ca',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '21px'
    }
  },
  H5: {
    mb: 0, mt: 0,
    _text: {
      color: '#2E3233',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px'
    }
  },
  H6: {
    mb: 0, mt: 0,
    _text: {
      color: '#2E3233',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '21px'
    }
  },
  P: {
    ...defaults,
    _text: {
      color: '#2E3233',
      fontSize: '14px',
      lineHeight: '21px',
    },
    marginTop: '7px',
  },
  IMG: {
    marginTop: 25,
    marginBottom: 10,
  },
  DIV: {
    _text: {
      color: '#2E3233',
    }
  },
  STRONG: {
    _text: {
      color: '#2E3233',
      fontWeight: '700',
      fontStyle: 'italic'
    }
  },
  grid: {
    my: 1,
    overflow: 'hidden',
  },
  row: {
    mx: -1,
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  "col-1": {
    ...col,
    flexBasis: '8.333333%'
  },
  "col-2": {
    ...col,
    flexBasis: '16.666666%'
  },
  "col-3": {
    ...col,
    flexBasis: '25%'
  },
  "col-4": {
    ...col,
    flexBasis: '33.333333%'
  },
  "col-5": {
    ...col,
    flexBasis: '41.666666%'
  },
  "col-6": {
    ...col,
    flexBasis: '50%'
  },
  "col-7": {
    ...col,
    flexBasis: '58.333333%'
  },
  "col-8": {
    ...col,
    flexBasis: '66.666666%'
  },
  "col-9": {
    ...col,
    flexBasis: '75%'
  },
  "col-10": {
    ...col,
    flexBasis: '83.333333%'
  },
  "col-11": {
    ...col,
    flexBasis: '91.666666%'
  },
  "col-12": {
    ...col,
    flexBasis: '100%'
  },
  blue: {
    _text: {
      color: '#00b0ca'
    }
  },
  bold: {
    _text: {
      fontWeight: 600
    }
  }
}
