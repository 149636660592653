
import { gql } from "@apollo/client";

export const GET_PRODUCTS = gql`
query ($path: String!) {
  page(path: $path) {
    name
    ... on Channel {
      articles {
        total
        items {
          id
          language
          name
          url
          uuid
          ... on Article {
            teaser {
              image {
                id
                src
                title
                alt
                width
                height
              }
              text
            }
            subtitle
            seoTitle
            recommend
            priority
            tags {
              id
              name
              url
            }
            variants {
              id
              name
              url
            }
            channel {
              id
              name
              url
            }
            content {
              ... on ParagraphText {
                __typename
                text
              }
              ... on ParagraphGallery {
                __typename
                images {
                  alt
                  id
                  language
                  name
                  src
                  width
                  height
                }
                name
                summary
              }
              ... on ParagraphImage {
                __typename
                image {
                  alt
                  id
                  language
                  name
                  src
                  width
                  height
                }
                summary
              }
            }
          }
        }
      }
    }
  }
}
`;
