import React, { Component } from 'react';
import {Box, HStack, Pressable, Text } from "native-base";
import ActionIcon from "./ActionIcon";
import {connect} from "react-redux";
import {setBookmark, unsetBookmark, putCart, rmCart} from "../store/actions";

class ProductTeaser extends Component {

  getVariant() {
    const cartItem = this.props.cart[this.props.product.id];
    if (typeof cartItem === 'object' && cartItem.variant) {
      const cartVariant = cartItem.variant;
      for (let prodVariant of this.props.product.variants) {
        if (prodVariant.id === cartVariant) {
          return (<Text>{prodVariant.name}</Text>)
        }
      }
    }
    return []
  }

  cartFwd() {
    const variants = this.props.product.variants;
    if (variants && variants.length) {
      this.props.navigation.navigate('Product Details', {
        product: this.props.product,
        willAddToCart: true,
        goBackAfterAddToCart: true
      })
    } else {
      this.props.addCart(this.props.product.id);
    }
  }

  render() {
    const product = this.props.product;
    const actionIcons = [];
    let variant = [];

    switch (this.props.mode) {
      case "favlist":
        actionIcons.push(<ActionIcon key="cart1" icon="cart" onPressed={() => {this.cartFwd()}} />);
        actionIcons.push(<ActionIcon key="fav1" icon="trash" onPressed={() => {this.props.rmBookmark(product.id)}} />);
        break;
      case "cart":
        actionIcons.push(<ActionIcon key="cart1" icon="trash" onPressed={() => {this.props.rmCart(product.id)}} />);
        variant = this.getVariant();
        break;
      default:
        actionIcons.push(<ActionIcon key="default1" icon="info" onPressed={this.props.clicked} />);
        //actionIcons.push(<ActionIcon key="default2" icon="cart" onPressed={() => {this.cartFwd()}} />);

    }

    const border = (this.props.border) ? { borderTopWidth: 1, borderTopColor: '#D6D6D6' } : {}
    return (
      <HStack alignItems="center" justifyContent="space-between" ml={6} mr={2} minH={60} {...border}>
        <Box flexGrow={1} flexShrink={1}>
          <Pressable style={{cursor: 'pointer'}} py={3} onPress={this.props.clicked} key={product.id}>
            <Text fontSize={15.999}
                  fontWeight={'medium'}
                  mb={0} color={'#2e3233'}
                  numberOfLines={1}>{product.name}</Text>
            {variant}
          </Pressable>
        </Box>
        <HStack flexGrow={1} flexShrink={0} justifyContent={'flex-end'}>
          {actionIcons}
        </HStack>
      </HStack>
    );

  }
}


const mapStateToProps = (state) => {
  return {
    bookmarks: state.products.bookmarks,
    cart: state.products.cart,
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    addBookmark: id => dispatch(setBookmark(id)),
    rmBookmark: id => dispatch(unsetBookmark(id)),
    addCart: id => dispatch(putCart(id)),
    rmCart: id => dispatch(rmCart(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTeaser);
