const images = {
  "no_image": {
    path: './no_picture.png',
    src: require('./no_picture.png'),
    width: 744,
    height: 744,
  },
  "cp_professional": {
    path: './cp_professional.jpg',
    src: require('./cp_professional.jpg'),
    width: 1440,
    height: 720,
  },
  "cp_professional_logo": {
    path: './cp_professional_logo.png',
    src: require('./cp_professional_logo.png'),
    width: 1445,
    height: 315,
  },
  "wound_infected": {
    path: './wound_infected.jpg',
    src: require('./wound_infected.jpg'),
    width: 1440,
    height: 1010,
  },
  "wound_not_infected": {
    path: './wound_not_infected.jpg',
    src: require('./wound_not_infected.jpg'),
    width: 1440,
    height: 1010,
  },
}

class allFiles {
  constructor(id) {
    this.img = (typeof images[id] === 'object')
      ? images[id] : images["no_image"];
  }

  getSrc() {
    return this.img.src;
  }

  getSize() {
    return {
      width: this.img.width,
      height: this.img.height,
    };
  }

  getRatio() {
    return this.img.width / this.img.height;
  }
}

export default allFiles;
