import React, { Component } from 'react';
import { Box } from "native-base";
import ParagraphText from './ParagraphTypes/ParagraphText';
import ParagraphImage from './ParagraphTypes/ParagraphImage';

class Paragraphs extends Component {

  render() {

    let key = 0;
    const content = this.props.content
      .filter((item) => (typeof item["__typename"] !== 'undefined'))
      .map((item) => {
        switch (item["__typename"]) {
          case 'ParagraphText':
            return (<ParagraphText key={key++} text={item.text} />)
          case 'ParagraphImage':
            return (<ParagraphImage key={key++} image={item.image} />)
          default:
        }
    })

    return (<Box>{content}</Box>);
  }
}

export default Paragraphs;
