import React, {Component} from 'react';
import {IconButton} from 'native-base';
import {MaterialCommunityIcons, Feather, Ionicons } from "@expo/vector-icons";
import PropTypes from "prop-types";

class ActionIcon extends Component {
  render() {
    if (this.props.id !== 'undefined') {
      let icon;
      switch (this.props.icon) {
        case "cart":
          icon = {
            as: Ionicons,
            name: "cart-outline",
            size: 5,
            color: '#2E3233'
          }
          break;
        case "close":
          icon = {
            as: Ionicons,
            name: "close",
            size: 5,
            color: '#2E3233'
          }
          break;
        case "filter":
          icon = {
            as: Ionicons,
            name: "ios-filter",
            size: 5,
            color: '#2E3233'
          }
          break;
        case "bookmark":
          icon = {
            as: MaterialCommunityIcons,
            name: "star",
            size: 5,
            color: '#2E3233'
          }
          break;
        case "info":
          icon = {
            as: MaterialCommunityIcons,
            name: "information",
            size: 6,
            color: '#2E3233'
          }
          break;
        case "trash":
          icon = {
            as: Feather,
            name: "trash-2",
            size: 5,
            color: '#2E3233'
          }
          break;
        default:
          icon = {
            as: Feather,
            name: "circle",
            size: 5,
            color: '#2E3233'
          }
      }

      return (
        <IconButton
          onPress={() => {this.props.onPressed()}}
          borderRadius="full"
          variant="transparent"
          _icon={{...icon, ...this.props._icon}}
          size={'sm'}
          ml={1}
          {...this.props.style}
        />)
    } else
      return []
  }
}

export default ActionIcon;

ActionIcon.propTypes = {
  icon: PropTypes.string,
  _icon: PropTypes.object,
  onPressed: PropTypes.func,
  style: PropTypes.object,
};

ActionIcon.defaultProps = {
  onPressed: () => {},
  style: {},
  _icon: {}
}
