import React, {Component} from 'react';
import {connect} from "react-redux";
import {View, StyleSheet, SafeAreaView, Modal } from 'react-native';
import {LinearGradient} from "expo-linear-gradient";
import LottieView from 'lottie-react-native';
import Logo from "../Component/Logo";
import {splashFinished} from "../../store/actions/main"

class AnimScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasAnimPlayedOnce: false
    }
  }

  render() {
    const isModalVisible = !(this.props.isAppInitialized && this.state.hasAnimPlayedOnce);
    return (
      <Modal visible={isModalVisible} animationType="fade">
        <SafeAreaView style={styles.splash}>
          <LinearGradient colors={['#5CCFDD', '#50B8D2']} locations={[0,1]} start={[0,0.5]} end={[1,0.5]} style={styles.background} />
          <LottieView source={require('../../assets/anim/splash-screen.json')}
                      style={styles.lottie}
                      autoPlay={true}
                      loop={false}
                      onAnimationFinish={() => {
                        this.setState({hasAnimPlayedOnce: true});
                        this.props.splashFin();
                      }}
          />
          <View style={styles.logo}>
            <Logo width={185} fill='white' />
          </View>
        </SafeAreaView>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  splash: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    backgroundColor: '#000',
    position: 'relative',
  },
  background: {
    position: "absolute",
    top: 0,
    left:0,
    right: 0,
    bottom: 0,
  },
  lottie: {
    margin: 0,
    width: '80%',
    transform: [{translateY: -30}]
  },
  logo: {
    alignItems: 'center',
    position: "absolute",
    bottom: '9%',
    left: 0,
    right: 0,
  }
});


const mapStateToProps = (state) => ({
  isAppInitialized: state.main.isAppInitialized,
})

const mapDispatchToProps = dispatch => {
  return {
    splashFin: () => dispatch(splashFinished())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AnimScreen)
