import React, { Component } from "react";
import { Text, Box } from "native-base";
import _ from "lodash";
import { Link } from 'native-base';
import ImageExt from "./ImageExt";
import { parse } from 'node-html-parser';
import Ulist from "./Ulist";
import Accordion from "./Accordion";



class HtmlRenderer extends Component {

  render() {
    const nodes = parse(this.props.html);
    return this.explorer(nodes);
  }

  explorer(nodes) {
    const components = [];
    if (nodes.hasOwnProperty('childNodes')) {
      nodes.childNodes.forEach((child, index) => {
        switch (child.tagName) {
          case 'UL':
            components.push(this.ulTag(child, index))
            break;
          case 'IMG':
            components.push(this.imgTag(child, index))
            break;
          case 'A':
            components.push(this.anchorTag(child, index))
            break;
          case 'P':
            components.push(this.paraTag(child, index))
            break;

          case 'ARTICLE':
          case 'ASIDE':
          case 'DIV':
            components.push(this.containerTag(child, index))
            break;
          default:
            if (child.childNodes.length) {
              components.push(this.defaultTag(child, index))
            }
        }
      });
    }
    return components
  }


  defaultTag(node, index, content) {
    const style = this.getStyles(node);
    content = (content && content.length) ? content : node.textContent;

    return (<Box key={index} {...style}>{content}</Box>)
  }

  containerTag(node, index) {
    let items = [];
    node.childNodes.forEach(child => {
      const item = this.explorer({ childNodes: [child] });
      if (item.length) items.push(item);
    });
    return this.defaultTag(node, index, items);
  }

  ulTag(node, index) {
    const classes = (node.attributes.class) ? node.attributes.class : '';
    if (classes.indexOf('ext-') >= 0) {
      const size = (classes.indexOf('ext-parent') >= 0) ? 'sm' : 'xs';

      const items = []
      node.childNodes.forEach(child => {
        if (child.tagName === 'LI') {
          items.push(this.explorer(child));
        }
      });

      return (<Accordion key={index} size={size} items={items} />)

    } else {
      const items = []
      node.childNodes.forEach(child => {
        if (child.tagName === 'LI') items.push(child.textContent);
      });
      return (<Ulist key={index}
        style={{ mb: 3, mt: 2 }}
        textStyle={{ fontSize: 14, color: '#2E3233' }}
        data={items} />)
    }
  }

  imgTag(node) {
    const style = this.getStyles(node);
    return (<ImageExt {...node.attributes} style={style} />);
  }

  anchorTag(node, index) {
    const style = this.getStyles(node);
    return (<Link key={index}{...style} href={node.attributes.href}
      isExternal>{node.textContent}</Link>);
  }

  paraTag(node, index) {
    const style = this.getStyles(node);
    let items = []
    node.childNodes.forEach(child => {
      if (child.tagName === 'A') {
        items.push(this.anchorTag(child, index));
      }
      else if (child.tagName === 'SUP') {
        items.push(<Text key={index} style={{ fontSize: 8, lineHeight: 18, textAlignVertical: 'top' }}>{child.textContent}</Text>)
      }
      else items.push(child.textContent);
    });
    if (this.props.main) {
      return <Text key={index} fontSize={'md'} color={'#2E3233'}>{items}</Text>

    } else {
      return <Text key={index} {...style}>{items}</Text>

    }

  }

  getStyles(node) {
    const styles = JSON.parse(JSON.stringify(this.props.styles));
    let style = (styles[node.tagName]) ? { ...styles[node.tagName] } : {};
    let classes = (node.attributes && node.attributes.class) ? node.attributes.class : '';
    classes = classes.split(' ');
    classes.forEach(className => {
      if (styles[className]) {
        const classStyle = { ...styles[className] };
        style = _.merge(style, classStyle);
      }
    });
    return style;
  }
}

export default HtmlRenderer;
