import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AlertDialog, VStack, Heading, Button, HStack, Text } from "native-base";
import Screen from "../../Component/Screen";
import t from "../../../translation/translation";
import ProductList from "../Component/ProductList";
import { unsetAllBookmarks } from "../store/actions";

class ProductsFavList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false
    }
    this.alertRef = React.createRef();
  }

  toggleDialog() {
    this.setState({
      dialogOpen: !this.state.dialogOpen
    })
  }

  render() {
    const catOverview = [];
    for (const i in this.props.categories) {
      console.log(parseInt(this.props.categories[i].key, 10));
      catOverview.push(<ProductList key={this.props.categories[i].key}
        mode="favlist"
        tags={[parseInt(this.props.categories[i].key, 10)]}
        ids={this.props.bookmarks}
        asExtendable={true}
        hideOnEmpty={true}
        isExtended={true}
        title={this.props.categories[i].val + ' (%num)'}
        navigation={this.props.navigation} />)
    }

    if (!this.props.bookmarks.length) {
      catOverview.push(<Text key={'empty'} fontSize={16} ml={6}>{t('There is no product on your bookmark list yet')}</Text>)
    }

    // Create remove Button if list is not empty.
    const removeBtn = (this.props.bookmarks.length) ? (
      <Button variant="ghost" onPress={() => { this.toggleDialog() }}
      ><Text color={'primary.700'} fontWeight={'bold'} fontSize={16}>{t('Remove all')}</Text></Button>) : [];

    return (
      <Screen scrollable={true}>
        <VStack mx={6} my={6}>
          <HStack justifyContent="space-between">
            <Heading size="xl" my={2}>{this.props.bookmarks.length.toString(10) + ' ' + t('Products')}</Heading>
            {removeBtn}
          </HStack>
          {catOverview}
        </VStack>

        <AlertDialog onClose={() => { this.toggleDialog() }} isOpen={this.state.dialogOpen} leastDestructiveRef={this.alertRef}>
          <AlertDialog.Content>
            <AlertDialog.CloseButton />
            <AlertDialog.Header>{t('Delete all bookmarks')}</AlertDialog.Header>
            <AlertDialog.Body>{t('Are you sure you want to delete all bookmarks')}</AlertDialog.Body>
            <AlertDialog.Footer>
              <Button.Group space={2}>
                <Button variant="unstyled" colorScheme="coolGray"
                  onPress={() => { this.toggleDialog() }}>{t('Cancel')}</Button>
                <Button colorScheme="danger" onPress={() => {
                  this.props.unsetAllBookmarks();
                  this.toggleDialog()
                }}>{t('Delete')}</Button>
              </Button.Group>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>

      </Screen>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products.products,
    categories: state.products.categories,
    bookmarks: state.products.bookmarks
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    unsetAllBookmarks: () => { dispatch(unsetAllBookmarks()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsFavList);
