export const SET_EXPO_TOKEN = 'SET_EXPO_TOKEN';
export const setExpoToken = (token) => {
  return { type: SET_EXPO_TOKEN, token: token }
};

export const SET_SYNC_STATUS = 'SET_SYNC_STATUS';
export const setSyncStatus = (status) => {
  return { type: SET_SYNC_STATUS, syncStatus: status }
};

const initialState = {
  token: null,
  syncStatus: false
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXPO_TOKEN:
      return {
        ...state,
        token: action.token,
      }

    case SET_SYNC_STATUS:
      return {
        ...state,
        syncStatus: action.syncStatus,
      }
    default:
  }

  return state;
}

export default notificationReducer;
