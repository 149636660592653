import React, { Component } from 'react';
import { } from 'react-native';
import { HStack } from "native-base";
import Answer from "./Answer";


class AnswerSelect extends Component {

  render() {

    let defaultValue = (typeof this.props.defaultValue === 'string') ? this.props.defaultValue : null;
    const options = (typeof this.props.options === 'object') ? this.props.options : {}
    const onAnswered = this.props.onAnswered;
    const icons = (this.props.icons) ? this.props.icons : {};

    const Answers = options.map(function (option) {
      const wrapped = Object.keys(option).map(function (value) {
        return (<Answer
          key={value}
          value={value}
          label={option[value]}
          icon={(icons[value]) ? icons[value] : null}
          selected={defaultValue === value}
          onPressed={() => {
            onAnswered(value);
          }}
        />)
      });
      return (wrapped.length) ? wrapped[0] : (<></>);
    });
    return (
      <HStack flexWrap={'wrap'} justifyContent="center" margin={-1} w="100%">
        {Answers}
      </HStack>
    );
  }
}

export default AnswerSelect;

