import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import { Center, Image } from "native-base";
import MediaImage from "../../Component/MediaImage";

class ParagraphImage extends Component {

  render() {
    const styles = StyleSheet.create({
      responsiveImage: {
      }
    });

    return (
      <Center rounded="lg" p={3} mb={2} backgroundColor={'white'}>
        <MediaImage {...this.props.image} />
      </Center>)
  }
}

export default ParagraphImage;
