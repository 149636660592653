import React, { Component } from 'react';
import { Dimensions, Platform } from 'react-native';
import { ScrollView, Box, Heading, Divider, Modal, Button } from 'native-base';
import t from '../../translation/translation'

// docs: https://www.npmjs.com/package/react-native-gesture-bottom-sheet#user-content-props
import BottomSheet from 'react-native-gesture-bottom-sheet';

class CustomBottomSheet extends Component {
  constructor(props) {
    super(props);
    this.bottomSheetRef = React.createRef();
    this.state = {
      open: false
    }
  }

  showBottomSheet() {
    if (Platform.OS === 'web') {
      this.setState({ open: true })
    } else {
      this.bottomSheetRef.current.show()
    }
  }

  closeBottomSheet() {
    if (Platform.OS === 'web') {
      this.setState({ open: false })
    } else {
      this.bottomSheetRef.current.close()
    }
  }

  getHeight() {
    const windowHeight = Dimensions.get('window').height;
    let height = (typeof this.props.height === 'number')
      ? this.props.height : Math.round(windowHeight * 0.5);
    const maxHeight = Math.round(windowHeight * 0.9);
    return (height > maxHeight) ? maxHeight : height;
  }

  render() {
    // Returns Mative-Base modal if web browser.
    if (Platform.OS === 'web') {
      return (<Modal isOpen={this.state.open} onClose={() => this.setState({ open: false })}>
        <Modal.Content maxWidth="500px">
          <Modal.CloseButton />
          <Modal.Header>{this.props.heading}</Modal.Header>
          <Modal.Body>
            {this.props.children}
          </Modal.Body>
          <Modal.Footer>
            <Button.Group variant="ghost" space={2}>
              <Button onPress={() => { this.setState({ open: false }) }}>
                {t('Close')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>)
    } else {
      // Returns BottomSheet component if Android or MacOs.
      const heading = (typeof this.props.heading === 'string')
        ? (
          <><Heading textAlign="center"
            lineHeight={30}
            px={6}
            letterSpacing={0.9}
            fontSize={17.999}
            textTransform={"uppercase"}>{this.props.heading}</Heading>
            <Divider my={3} h={0.59} bgColor={'#B2B2B2'} />
          </>) : this.props.heading;
      return (
        <BottomSheet draggable={true}
          hasDraggableIcon={false}
          ref={this.bottomSheetRef}
          height={this.getHeight()}
          sheetBackgroundColor="#FFF"
          dragIconColor="#D4D5D5"
          style={{ backgroundColor: '#FFF' }}
        >
          <Box flex={1} mt={2} mb={6} w={'100%'}>
            {heading}
            <ScrollView _contentContainerStyle={{ minW: "72", px: "0" }}>
              {this.props.children}
            </ScrollView>

          </Box>
        </BottomSheet>
      );
    }
  }
}

export default CustomBottomSheet;
