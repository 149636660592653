import React, {Component} from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { Buffer } from "buffer";
import { setSyncStatus } from "../store/reducer";

class SyncStatus extends Component {

  syncToken() {
    if (typeof this.props.token === 'string'
      && !!this.props.token.length
      && this.props.user.name
      && this.props.user.password
    ) {
      const tokenFrags = this.props.token.match(/^ExponentPushToken\[([a-zA-Z0-9]*)\]$/);
      const token = (typeof tokenFrags === 'object' && tokenFrags.length >= 2) ? tokenFrags[1] : false;

      if (!token) {
        return false
      }

      try {
        const auth = Buffer.from(this.props.user.name + ':' + this.props.user.password).toString('base64');
        const requestOptions = {
          method: 'GET',
          headers: {
            'Cache-Control': 'no-cache',
            'Authorization': 'Basic ' + auth
          }
        };
        let responseStatus = null;
        const syncUrl = this.props.api.domain
          + this.props.api.basePath
          + "pushy/sync-token/"
          + token
          + "?_format=hal_json";

        fetch(syncUrl, requestOptions)
          .then(response => {
            responseStatus = response.status;
            return response.json()
          })
          .then(data => {
            // Response data are not relevant just check success.
            if (data.synced) {
              this.props.updateSyncStatus(true);
            }
          }).catch((e) => {
            console.log('CONNECT ACCOUNT FAILED (I): ', e);
          });

      } catch(e) {
        console.log('CONNECT ACCOUNT FAILED (II): ', e);
      }
    }
  }

  componentDidMount() {
    this.syncToken();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.syncStatus) {
      this.syncToken();
    }
  }

  render() {
    return (this.props.isDebug)
      ? (<View>
          <Text>Synced: {(this.props.syncStatus) ? 'SYNCED' : 'UN-SYNCED'}</Text>
        </View>)
      : [];
  }
}

const mapStateToPros = (state) => {
  return {
    isDebug: state.main.isDebug,
    api: state.main.api,
    user: state.login.user,
    token: state.notifications.token,
    syncStatus: state.notifications.syncStatus
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSyncStatus: (status) => dispatch(setSyncStatus(status))
  }
}

export default connect(mapStateToPros, mapDispatchToProps)(SyncStatus)
