import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Heading, Text, VStack, Link } from "native-base";
import t from '../../../translation/translation'
import Screen from '../../Component/Screen';
import { Alert, Linking } from 'react-native';
import { resetDecisions, setDecision } from "../../../store/actions/decisionTree";

import StepId from "../../Component/StepId";
import PageText from "../../Component/PageText";
import PrevNext from "../../Component/PrevNext";
import ProductsList from "../../Products/Component/ProductList";

class FProductsOverview extends Component {


  render() {
    const recommendSArr = ["woundInfected", "tissueType", "exudation", "surroundingSkin", "woundDepth"]
      .map(item => {
        if (this.props.decisionTree[item]) {
          return this.props.decisionTree[item]
        } else return 'nn'
      });
    const recommendSelection = recommendSArr.join('_');

    const footer = (
      <PrevNext
        prev={() => {
          if (recommendSelection == '30_21_nn_34_nn' || recommendSelection == '30_21_nn_35_nn' || recommendSelection == '31_21_nn_34_nn' || recommendSelection == '31_21_nn_35_nn' || recommendSelection == '31_18_26_34_nn' || recommendSelection == '31_18_26_35_nn' || recommendSelection == '31_18_27_34_nn' || recommendSelection == '31_18_27_35_nn') {
            this.props.resetSurround();
            this.props.navigation.navigate('Surrounding skin')
          } else if (recommendSelection == '30_18_nn_nn_nn') {
            this.props.resetTissue();
            this.props.navigation.navigate('Tissue')
          }
          else {
            this.props.resetWound();
            this.props.navigation.navigate('Wound depth')
          }
        }

        }
        next={() => {
          this.props.resetResult();
          this.props.navigation.navigate('Wizard', { screen: 'Infection' })
        }
        }
        nextText={t('New Selection')}
        size={'md'}
      />);

    const recommendArr = ["woundInfected", "tissueType", "exudation", "surroundingSkin", "woundDepth"]
      .map(item => {
        if (this.props.decisionTree[item]) {
          return '(' + this.props.decisionTree[item] + '|nn)'
        } else return '(nn)'
      });
    const recommendStr = recommendArr.join('_');

    const styles = {
      link: {
        fontSize: '16px',
        color: 'primary.700'
      }
    }
    // Empty Message.
    let emptyMessage = (<Box>
      <Text fontSize={16} color={'#2E3233'}>{t("We do not have any combination")}</Text>
      <Text fontSize={16} mt={3} color={'primary.600'}>{t("Feel Free to contact us")}</Text>
      <Link mt={2}
        _text={styles.link} onPress={() => {
          Linking.canOpenURL(t('mailto-address'))
            .then(supported => {
              if (!supported) {
                Alert.alert('Can´t open mail client with: '
                  + t('mailto-address').replace('mailto:', ''));
              } else {
                return Linking.openURL(t('mailto-address'));
              }
            })
        }}>{t('Write an e-mail')}</Link>
      <Link mt={1}
        _text={styles.link} onPress={() => {
          Linking.canOpenURL(t('tel-num'))
            .then(supported => {
              if (!supported) {
                Alert.alert('Phone service not available with: '
                  + t('tel-num').replace('tel:', ''));
              } else {
                return Linking.openURL(t('tel-num'));
              }
            })
        }}>{t('Call us by phone')}</Link>

    </Box>)

    return (
      <Screen footer={footer} scrollable={true}>
        <StepId stepCount={6} current={5} nav={this.props.navigation} />
        <VStack mx={6} px={6} flex={1} space={6} alignItems={"flex-start"} justifyContent={"flex-start"} >

          <PageText heading={t('Recommendation')} />
          <ProductsList recommend={recommendStr + '_P1'}
            emptyMessage={emptyMessage}
            debug={false}
            hideOnEmpty={false}
            showTitleOnEmpty={false}
            title={t('Primary bandage')}
            navigation={this.props.navigation} />
          <ProductsList recommend={recommendStr + '_S1'}
            debug={false}
            hideOnEmpty={true}
            title={t('Secondary bandage')}
            navigation={this.props.navigation} />

          <ProductsList recommend={recommendStr + '_P2'}
            debug={false}
            hideOnEmpty={true}
            title={t('Alternate primary dressing')}
            navigation={this.props.navigation} />
          <ProductsList recommend={recommendStr + '_S2'}
            debug={false}
            hideOnEmpty={true}
            title={t('Alternate secondary dressing')}
            navigation={this.props.navigation} />
          <ProductsList recommend={recommendStr + '_CL'}
            debug={false}
            hideOnEmpty={true}
            title={t('Wound cleansing')}
            navigation={this.props.navigation} />

        </VStack>
      </Screen>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    decisionTree: state.decisionTree,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resetResult: () => {
      return dispatch(resetDecisions())
    },
    resetWound: () => {
      return dispatch(setDecision({ woundDepth: null }))
    },
    resetTissue: () => {
      return dispatch(setDecision({ tissueType: null }))
    },
    resetSurround: () => {
      return dispatch(setDecision({ surroundingSkin: null }))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FProductsOverview)
