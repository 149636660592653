import React, {Component} from 'react';
import { createStackNavigator } from "@react-navigation/stack";
import t from "../../translation/translation"
import InfectedScreen from "./Screens/A-InfectedScreen";
import TissueTypeScreen from "./Screens/B-TissueTypeScreen";
import ExudationScreen from "./Screens/C-ExudationScreen";
import SurroundingSkinScreen from "./Screens/D-SurroundingSkinScreen";
import WoundDepthScreen from "./Screens/E-WoundDepthScreen";
import ProductsOverview from "./Screens/F-ProductsOverview";
import ProductScreen from "../Products/Screens/ProductScreen";
import { resetDecisions } from "../../store/actions/decisionTree";
import {connect} from "react-redux";
import { prodScreenOpts } from "../Products/Component/navopts"

class DecisionTreeNavigator extends Component {

  componentDidMount() {
    this.props.resetResult();
  }

  render() {
    const StackNavi = createStackNavigator()
    const screenOptions = {
      headerShown: false,
      cardStyleInterpolator: ({ current }) => ({
        cardStyle: {
          opacity: current.progress
        },
      })
    }

    return (
      <StackNavi.Navigator screenOptions={screenOptions}>
        <StackNavi.Screen name="Infection"          options={{title: t("Infection")}} component={InfectedScreen} />
        <StackNavi.Screen name="Tissue"             options={{title: t("Tissue")}} component={TissueTypeScreen} />
        <StackNavi.Screen name="Exudation"          options={{title: t("Exsudation")}} component={ExudationScreen} />
        <StackNavi.Screen name="Surrounding skin"   options={{title: t("Surrounding skin")}} component={SurroundingSkinScreen} />
        <StackNavi.Screen name="Wound depth"        options={{title: t("Wound depth")}} component={WoundDepthScreen} />
        <StackNavi.Screen name="Products overview"  options={{title: t("Recommended Products")}} component={ProductsOverview} />
        <StackNavi.Screen name="Product Details"    options={prodScreenOpts} component={ProductScreen} />
      </StackNavi.Navigator>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    resetResult: () => {
      return dispatch(resetDecisions())
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DecisionTreeNavigator);
