import React, {Component} from 'react';
import {connect} from 'react-redux';
import AuthNavigator from "./AuthNavigator";

class LoginSwitch extends Component {

  render() {
    return (this.props.user.valid)
      ? (<>{this.props.children}</>)
      : (<AuthNavigator />);
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.user
  }
}

export default connect(mapStateToProps)(LoginSwitch)

