import * as React from 'react';
import { KeyboardAvoidingView, Platform } from "react-native"
import {Component} from "react";
import {connect} from 'react-redux';
import { VStack, FormControl, Input, Button, Icon } from 'native-base';
import Screen from "../../Component/Screen";
import Alert from "../Components/Alert";
import t from "../../../translation/translation";
import { saveUser } from "../store/actions";
import { validateLength } from "../service/validation";
import { FontAwesome } from "@expo/vector-icons";
import PageText from "../../Component/PageText";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      password: props.password,
      formError: {},
      alert: { showAlert: false },
      passRead: false
    };
  }

  validateForm = () => {
    let errors = {}
    if (!validateLength(this.state.name)) {
      errors.name = t('The name field cannot be empty');
    }
    if (!validateLength(this.state.password)) {
      errors.password = t('The password field cannot be empty');
    }
    this.setState({formError: {...errors}});

    return (Object.keys(errors).length === 0);
  }

  processLogin = async () => {
    if (this.validateForm()) {

      const loginData = {
        name: this.state.name,
        password: this.state.password
      };

      const user = await this.props.accountUI.connectWithAccount(loginData);
      if (typeof user === 'object') {
        // Request was successful.
        const accountStatus = {
          offline: false,
          valid: true,
          lastValidation: new Date().getTime()
        }
        const userData = {...loginData, ...user, ...accountStatus}
        this.props.setUserData(userData);
      } else if (typeof user === 'string') {
        switch (user) {
          case 'error':
            this.setState({ alert: {
                open: true,
                header: t('Login failed'),
                body: t('Username or password wrong'),
                onClosed: () => { this.setState({alert: { showAlert: false }}) }
              }
            });
            break;
          case 'offline':
            this.setState({ alert: {
                open: true,
                header: t('Offline error'),
                body: t('App can not connect with server'),
                onClosed: () => { this.setState({alert: { showAlert: false }}) }
              }
            });
            break;
          case 'mistyped':
            this.setState({ alert: {
                open: true,
                header: 'Program error',
                body: 'Unexpected response. Check coding of the account API for user login.',
                onClosed: () => { this.setState({alert: { showAlert: false }}) }
              }
            });
            break;
          default:
        }
      }

    }
  }

  render = () => {
    const footer = (
      <VStack space={2} mx={6}>
        <Button size={'lg'} onPress={() => {this.processLogin()}}>{t('Login')}</Button>
      </VStack>);

    return (
      <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{flex: 1}}>
        <Screen footer={footer}>
          <Alert {...this.state.alert} />
          <VStack flex={1} mx={6} mt={8}>

            <PageText heading={t('Login')}
                      size="xl"
                      text={t("Please enter your credentials")}/>

            <VStack space={2} mt={5}>

              <FormControl isRequired isInvalid={'name' in this.state.formError}>
                <FormControl.Label>{t('Username')}</FormControl.Label>
                <Input size="xl" type="text" value={this.state.name} onChangeText={name => {
                  this.setState({name: name});
                }} />
                <FormControl.ErrorMessage>{this.state.formError.name}</FormControl.ErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={'password' in this.state.formError}>
                <FormControl.Label>{t('Password')}</FormControl.Label>
                <Input size="xl"
                       type={this.state.passRead ? 'text' : 'password'}
                       value={this.state.password}
                       onChangeText={password => { this.setState({password: password});}}
                       InputRightElement={<Icon color={this.state.passRead ? 'primary.600' : 'gray.300'}
                                                mr={3}
                                                textAlign="center"
                                                as={<FontAwesome name="eye" size="sm" />}
                                                onPress={() => {this.setState({passRead: !this.state.passRead}) }}
                                                size="sm" />}
                />
                <FormControl.ErrorMessage>{this.state.formError.password}</FormControl.ErrorMessage>
              </FormControl>

              <VStack mt={5} space={2} alignItems="flex-end">
                <Button variant="ghost" size="lg"
                        onPress={() => {this.props.navigation.navigate('Password');}}>{t('Forgot password')}</Button>
                <Button variant="ghost" size="lg"
                        onPress={() => {this.props.navigation.navigate('Register');}}>{t('Register new Account')}</Button>
              </VStack>

            </VStack>
          </VStack>
        </Screen>
      </KeyboardAvoidingView>);
  }
}


const mapStateToProps = (state) => {
  return {
    name: state.login.user.name,
    password: state.login.user.password,
    accountUI: state.login.accountUI
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUserData: (userData) => {
      return dispatch(saveUser(userData))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
