import React, {useState} from "react";
import {HStack, Text} from "native-base";
import PropTypes from "prop-types";
import ActionIcon from "./ActionIcon";

export default function InScreenFilterItem(props) {
  return (
    <HStack alignItems="center" borderRadius="full" m={2} pl={4} pr={1} py={2.75} bg="primary.600">
      <Text color="#FFF">{props.title}</Text>
      <ActionIcon icon="close"
                  onPressed={() => props.onDisable()}
                  _icon={{color: '#FFF', size: 5}}/>
    </HStack>
  )
}

InScreenFilterItem.propTypes = {
  onDisable: PropTypes.func,
  title: PropTypes.string,
};

InScreenFilterItem.defaultProps = {
  onDisable: () => {},
  title: "no title given"
}
