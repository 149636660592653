import React, { Component } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { Image } from 'native-base';
import ImageHandler from '../../assets/img/allFiles';

class CustomImage extends Component {
  constructor(props) {
    super(props);
    this.handler = new ImageHandler(props.id);
    this.state = {
      height: 60
    }
  }

  onLayout(layout) {
    this.setState({
      height: Math.round(layout.width/this.handler.getRatio())
    })
  }

  render() {
    let style = {width: '100%', height: this.state.height};
    if (typeof this.props.style === 'object') {
      style = {...this.props.style, ...style}
    }
    const alt = (typeof this.props.alt === 'string') ? this.props.alt : this.props.id;
    return (
      <View style={style} onLayout={(e) => {this.onLayout(e.nativeEvent.layout)}}>
        <Image source={this.handler.getSrc()}
               PlaceholderContent={<ActivityIndicator />}
               key={this.props.id}
               alt={alt}
               size={'100%'}
        />
      </View>
    );
  }
}

export default CustomImage;

