import React, {Component} from 'react';
import { KeyboardAvoidingView, Platform } from "react-native"
import { VStack, FormControl, Input, Button, Icon } from 'native-base';
import Screen from "../../Component/Screen";
import Alert from "../Components/Alert";
import t from "../../../translation/translation";
import { connect } from "react-redux";
import { validateEmail, validateLength, validateName, validatePassword } from "../service/validation";
import { FontAwesome } from "@expo/vector-icons";
import { saveUser } from "../store/actions";
import PageText from "../../Component/PageText";

class RegisterScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      email: props.email,
      password: '',
      confirm: '',
      formError: {},
      alert: { showAlert: false },
      passRead: false
    };
  }

  validateForm = () => {
    let errors = {}
    if (!validateEmail(this.state.email)) {
      errors.email = t('E-mail address not valid');
    }
    if (!validateLength(this.state.email)) {
      errors.email = t('The email field cannot be empty');
    }
    if (!validateName(this.state.name, 3)) {
      errors.name = t('Name must contain three char');
    }
    if (!validatePassword(this.state.password)) {
      errors.password = t('Password mistyped');
    }
    if (this.state.password !== this.state.confirm) {
      errors.confirm = t('Password does not match');
    }
    this.setState({formError: {...errors}});

    return (Object.keys(errors).length === 0);
  }

  submitRegister = async () => {
    if (this.validateForm()) {
      const status = await this.props.accountUI.registerAccount({
        email: this.state.email,
        name: this.state.name,
        password: this.state.password,
        expoToken: this.props.expoToken
      });

      switch (status) {
        case 'success':
          const userData = {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            valid: true,
            offline: false,
            lastValidation: new Date().getTime()
          };

          // Write registration data to redux.
          this.props.setUserData(userData);

          break;
        case 'error':
          this.setState({ alert: {
              open: true,
              header: t('Register error'),
              body: t('Seems e-mail is already registered'),
              onClosed: () => { this.setState({alert: { showAlert: false }}) }
            }
          });
          break;
        case 'offline':
          this.setState({ alert: {
              open: true,
              header:  t('Offline error'),
              body: t('Server error'),
              onClosed: () => { this.setState({alert: { showAlert: false }}) }
            }
          });
          break;
        case 'mistyped':
          this.setState({ alert: {
              open: true,
              header: 'Program error',
              body: 'Unexpected response. Check coding of the account API for register new user.',
              onClosed: () => {
                this.setState({alert: { showAlert: false }});
              }
            }
          });
          break;
        default:

      }
    }
  }


  render() {

    const footer = (
      <VStack space={2} mx={6}>
        <Button size={'lg'} onPress={() => { this.submitRegister() }}>{t('Register')}</Button>
      </VStack>);

    return (
      <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{flex: 1}}>
        <Screen footer={footer} scrollable={true}>
          {/* Feedback from from submission. */}
          <Alert {...this.state.alert} />

          <VStack flex={1} mx={6} mt={8}>
            <PageText heading={t('Register new Account')}
                      size="xl"
                      text={t('Sign up to continue')}/>

            <VStack space={2} mt={5}>

              <FormControl isRequired isInvalid={'name' in this.state.formError}>
                <FormControl.Label>{t('Full name')}</FormControl.Label>
                <Input size="xl" value={this.state.name}
                       onChangeText={val => {this.setState({name: val});}} />
                <FormControl.ErrorMessage>{this.state.formError.name}</FormControl.ErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={'email' in this.state.formError}>
                <FormControl.Label>{t('Email')}</FormControl.Label>
                <Input size="xl" value={this.state.email}
                       onChangeText={val => {this.setState({email: val});}} />
                <FormControl.ErrorMessage>{this.state.formError.email}</FormControl.ErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={'password' in this.state.formError}>
                <FormControl.Label>{t('Password')}</FormControl.Label>
                <Input size="xl" type={this.state.passRead ? 'text' : "password"}
                       value={this.state.password}
                       onChangeText={val => { this.setState({password: val});}}
                       InputRightElement={<Icon color={this.state.passRead ? 'primary.600' : "gray.300"}
                                                mr={3}
                                                textAlign="center"
                                                as={<FontAwesome name="eye" size="sm" />}
                                                onPress={() => {this.setState({passRead: !this.state.passRead}) }}
                                                size="sm" />}
                />
                <FormControl.ErrorMessage>{this.state.formError.password}</FormControl.ErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={'confirm' in this.state.formError}>
                <FormControl.Label>{t('Confirm Password')}</FormControl.Label>
                <Input size="xl" type={this.state.passRead ? 'text' : "password"}
                       value={this.state.confirm}
                       onChangeText={val => {this.setState({confirm: val});}}
                       InputRightElement={<Icon color={this.state.passRead ? 'primary.600' : "gray.300"}
                                                mr={3}
                                                textAlign="center"
                                                as={<FontAwesome name="eye" size="sm" />}
                                                onPress={() => {this.setState({passRead: !this.state.passRead}) }}
                                                size="sm" />}
                />
                <FormControl.ErrorMessage>{this.state.formError.confirm}</FormControl.ErrorMessage>
              </FormControl>
            </VStack>

            <VStack mt={5} space={2} alignItems="flex-end">
              <Button variant="ghost" size="lg"
                      onPress={() => {this.props.navigation.navigate('Login');}}>{t('Return to Login')}</Button>
            </VStack>

          </VStack>
        </Screen>
      </KeyboardAvoidingView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    name: state.login.user.name,
    email: state.login.user.email,
    accountUI: state.login.accountUI,
    expoToken: state.notifications.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUserData: (user) => {
      return dispatch(saveUser(user))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterScreen);
